@media (max-width: 1024px) {
  section.statistics-container {
    padding-left: 72px !important;
  }
}
section.statistics-container {
  padding-left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statistics {
  z-index: 100;
  position: static;
  padding: 0 32px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
.statistics.promotion-preloader {
  position: absolute;
}
.statistics.promotion-preloader:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent url('../images/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  content: '';
  z-index: 1000;
  transition: opacity 0.2s linear 0.5s;
  opacity: 1;
  max-width: none;
}
.statistics .no-subscriptions {
  position: fixed;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 540px;
  text-align: center;
}
.statistics .no-subscriptions > div:nth-child(1) {
  font-weight: 400;
  font-size: 40px;
  line-height: 1.4;
  color: var(--white);
  margin: 0 0 24px;
}
.statistics .no-subscriptions > div:nth-child(2) {
  font-size: 16px;
  line-height: 1.5;
  color: #807e7e;
  margin: 0 0 48px;
}
.statistics .back {
  background: url('../images/pic_analytics_placeholder.svg') no-repeat top center;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  min-width: 100%;
}
.statistics .back::after {
  content: '';
  position: fixed;
  background-color: var(--overlay);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.statistics .statistics-main {
  min-width: 1024px;
  padding-bottom: 32px;
  padding-top: 32px;
}
.statistics .statistics-main .statistics-date-picker-container {
  position: sticky;
  top: 32px;
  z-index: calc(var(--zindex-popover-next) - 1000);
}
.statistics .statistics-main .tooltip.statistics-tooltip {
  width: 360px;
  padding: 0 10px;
}
.statistics .statistics-main .tooltip.statistics-tooltip .tooltip-inner {
  background-color: #053748;
  height: auto;
  text-align: left;
  border-radius: 3px;
  padding: 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
}
.statistics .statistics-main .tooltip.statistics-tooltip .tooltip-inner ul {
  margin-bottom: 0;
  padding: 0 0 0 20px;
}
.statistics .statistics-main .tooltip.statistics-tooltip .tooltip-arrow {
  right: 2px;
  margin-top: -9px;
  border-width: 9px 0 9px 8px;
  border-left-color: #053748;
}
.statistics .statistics-main .tooltip.statistics-tooltip.top {
  width: auto;
}
.statistics .statistics-main .tooltip.statistics-tooltip.top .tooltip-inner {
  padding: 2px 8px;
  margin-bottom: 5px;
}
.statistics .statistics-main .tooltip.statistics-tooltip.top .tooltip-arrow {
  border-color: transparent;
  border-top-color: #053748;
  border-width: 5px 5px 0;
}
.statistics .statistics-main .panel {
  background: var(--white);
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  margin: 32px 0 0 0;
  padding: 24px;
}
.statistics .statistics-main .panel canvas {
  margin: 0 10px;
}
.statistics .statistics-main .panel .loading {
  margin: 44px auto 44px;
  width: 32px;
}
.statistics .statistics-main .panel .section-header {
  padding: 0 0 16px 0;
  display: inline-block;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: var(--black);
  margin-top: -4px;
}
.statistics .statistics-main .panel .section-header .subtitle {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  margin: 0;
  padding: 2px 0 0 0;
}
.statistics .statistics-main .panel .section-header-table {
  padding: 0 0 16px 0;
}
.statistics .statistics-main .panel .section-date {
  display: inline-block;
  margin-left: 24px;
  font-size: 16px;
  color: var(--grey);
}
.statistics .statistics-main .panel__table {
  padding-right: 0;
  height: 410px;
}
.statistics .statistics-main .panel .noover {
  overflow-y: scroll;
}
.statistics .statistics-main .panel .has-error {
  margin-top: 50px;
  text-align: center;
  font-size: 14px;
}
.statistics .statistics-main .panel .has-error > button {
  display: inline-block;
}
.statistics .statistics-main .column-graph {
  width: 1024px;
  font-size: 0;
}
.statistics .statistics-main .column-graph .section-header .section-header-title {
  text-transform: capitalize;
}
.statistics .statistics-main .column-graph__block {
  height: 410px;
  margin: 0 32px 32px 0;
  width: 496px;
  display: inline-block;
  vertical-align: top;
}
.statistics .statistics-main .column-graph__block .has-error {
  margin-top: 50px;
  text-align: center;
}
.statistics .statistics-main .column-graph__block .has-error > button {
  display: inline-block;
}
.statistics .statistics-main .column-graph__block-bottom {
  height: 410px;
  margin: 0 0 32px 0;
  width: 496px;
  display: inline-block;
}
.statistics .statistics-main .column-graph__block-bottom .has-error {
  margin-top: 50px;
  text-align: center;
}
.statistics .statistics-main .column-graph__block-bottom .has-error > button {
  display: inline-block;
}
.statistics .statistics-main .column-graph .referrals .table-header {
  padding: 0 40px 0 24px;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item.first {
  width: 267px;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item.third {
  float: right;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item.last {
  float: right;
  width: 168px;
  text-align: right;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item .asc {
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
  background-image: url('../images/ic_info_small.svg');
  background-size: auto;
}
.statistics .statistics-main .column-graph .referrals .table-header.header-item .tooltip {
  text-transform: none;
}
.statistics .statistics-main .column-graph .referrals .ref-link {
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  white-space: nowrap;
}
.statistics .statistics-main .column-graph .referrals .ref-link:hover {
  text-decoration: underline;
  color: #448af1;
}
.statistics .statistics-main .footer-message {
  margin-left: 32px;
  width: 495px;
  vertical-align: top;
  line-height: normal;
}
.statistics .statistics-main .footer-message::after {
  content: '';
  display: table;
  clear: both;
}
.statistics .statistics-main .footer-message__logined .foot {
  padding-top: 24px;
  clear: both;
  text-align: left;
}
.statistics .statistics-main .footer-message__logined .button {
  display: inline-block;
}
.statistics .statistics-main .footer-message__logined .db-border {
  width: 113px;
}
.statistics .statistics-main .footer-message__logined .db-orange {
  display: inline-block;
  margin-right: 16px;
}
.statistics .statistics-main .footer-message__logined .promotion__text {
  margin: -4px 0 0 !important;
  line-height: normal;
}
.statistics .statistics-main .footer-message .promotion__text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  color: var(--black);
  margin: -4px 0 16px;
}
.statistics .table {
  width: 100%;
  max-width: 100%;
  font-size: 12px;
}
.statistics .table td {
  padding: 16px 0 16px 24px;
  line-height: 1;
  vertical-align: top;
  border-bottom: 1px solid #e0e0e0;
}
.statistics .table tr > td:last-child {
  text-align: right;
  padding-right: 26px;
}
.statistics .table tr:last-child td {
  border-bottom: none;
}
.statistics .table.referral-table td {
  padding: 16px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statistics .table.referral-table td.right-aligned {
  text-align: right;
}
.statistics .table.referral-table td.width110 {
  width: 110px;
  max-width: 110px;
}
.statistics .table.referral-table td.width208 {
  width: 208px;
  max-width: 208px;
}
.statistics .table.referral-table td.width294 {
  width: 294px;
  max-width: 294px;
}
.statistics .table.referral-table td.width410 {
  max-width: 410px;
}
.statistics .table.referral-table td.text-grey {
  color: #767676;
}
.statistics .table.referral-table td.bold-text {
  font-weight: 600;
}
.statistics .table.referral-table td:first-child {
  padding-left: 24px;
  padding-right: 5px;
}
.statistics .table.referral-table td:last-child {
  padding-right: 40px;
}
.statistics .table--popular-urls td:first-child {
  width: 335px;
  word-break: break-all;
}
.statistics .table--popular-urls td:first-child a {
  color: var(--black);
  text-decoration: none;
}
.statistics .table--popular-urls td:first-child a:hover {
  text-decoration: none;
}
.statistics .table--popular-urls td:last-child {
  width: 161px;
  word-break: break-all;
}
.statistics .table-container {
  height: 298px;
  margin-left: -24px;
  overflow-y: auto;
}
.statistics .table-container.sources {
  height: 279px;
}
.statistics .table-header {
  height: 40px;
  background-color: var(--greyLight20);
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  margin: 0 0 0 -24px;
  color: #767676;
  text-transform: uppercase;
}
.statistics .table-header__left {
  display: inline-block;
  margin-left: 18px;
}
.statistics .table-header__left:first-child {
  margin: 0 5px 0 24px;
}
.statistics .table-header__right {
  float: right;
  margin-right: 26px;
}
.statistics .table-header::after {
  content: '';
  display: table;
  clear: both;
}
.statistics::before {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100px;
  right: 0;
  content: '';
  display: block;
  background: var(--greyLight20);
}
.statistics .asc {
  position: absolute;
  top: 30px;
  right: 75px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../images/ic_info.svg') center center no-repeat;
  background-size: cover;
  opacity: 0.25;
}
.statistics .asc:hover {
  opacity: 1;
}
.statistics .asc:hover::after {
  position: absolute;
  content: attr(tooltip-message);
  min-width: 240px;
  border-radius: 3px;
  background-color: #053748;
  padding: 12px;
  right: 34px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: var(--white);
  word-wrap: break-word;
}
.statistics .asc:hover::before {
  position: absolute;
  content: ' ';
  top: 50%;
  right: 28px;
  width: 13px;
  height: 13px;
  z-index: 99;
  background-color: #053748;
  transform: translateY(-50%) rotate(45deg);
}
.statistics .asc.asc-ref:hover:after,
.statistics .asc.asc-ref:hover:before {
  display: none;
}
