placeholder-block {
  display: inline-block;
  height: 8px;
  border-radius: 2px;
  background-color: var(--greyLight20);
  margin: 0 4px 0 0;
  align-self: center;
  vertical-align: 1px;
}
placeholder-block[size='short'] {
  width: 48px;
}
placeholder-block[size='long'] {
  width: 80px;
}
placeholder-block.placeholder-block_position {
  margin-left: 48px !important;
}
