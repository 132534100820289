/**
 * store standard css properties but in mixins to prefix different browser compatibility
 */
users-table {
  display: block;
}
users-table table {
  table-layout: fixed;
  width: intrinsic;
  width: -webkit-max-content;
  width: fit-content;
  min-width: 100%;
}
users-table table .cursor-pointer-on-hover {
  cursor: pointer;
  min-width: 60px;
  border-radius: 4px;
  padding-top: 2px;
}
users-table table avatar {
  width: 36px;
  height: 36px;
  top: -4px;
  left: 0;
  position: absolute;
  transition: width 200ms cubic-bezier(0.22, 0.61, 0.36, 1), height 200ms cubic-bezier(0.22, 0.61, 0.36, 1), left 200ms cubic-bezier(0.22, 0.61, 0.36, 1), top 200ms cubic-bezier(0.22, 0.61, 0.36, 1), transform 200ms cubic-bezier(0.22, 0.61, 0.36, 1), box-shadow 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
  cursor: pointer;
}
users-table table avatar[disabled] {
  cursor: default;
  pointer-events: none;
}
users-table table avatar.scale-width-height {
  z-index: var(--zindex-popover);
  width: 250px;
  height: 250px;
  left: -107px;
  top: -107px;
}
users-table table .avatar-name_positioning {
  position: relative;
  height: 28px;
}
users-table table .avatar-name_positioning > chip {
  max-width: calc(100% -  36px + 16px);
}
users-table table .session-cell-elements_position {
  display: inline-flex;
  align-items: center;
}
users-table table tr:hover td {
  background-color: var(--white);
}
users-table table tr:hover .mes-icon {
  fill: var(--blue);
}
users-table table tr .mes-icon {
  fill: #d9d9d9;
}
users-table table tr.void-tr {
  pointer-events: none;
}
users-table table tr.void-tr td {
  text-align: center;
  vertical-align: middle;
}
users-table table tr.void-tr td .loading {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('../images/spinner_b.svg') no-repeat center center;
  background-size: 32px 32px;
}
users-table table td,
users-table table th {
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #40403c;
  height: 64px;
  vertical-align: middle;
  text-align: left;
}
users-table table td.checkbox,
users-table table th.checkbox {
  text-align: center;
}
users-table table td.checkbox .checkbox-control,
users-table table th.checkbox .checkbox-control {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
users-table table td.checkbox .checkbox-control.disabled,
users-table table th.checkbox .checkbox-control.disabled {
  opacity: 0.4;
  pointer-events: none;
}
users-table table td.checkbox .checkbox-control input,
users-table table th.checkbox .checkbox-control input {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 10;
  opacity: 0;
}
users-table table td.checkbox .checkbox-control span,
users-table table th.checkbox .checkbox-control span {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 1;
  background: url('../images/ic_checkbox.svg') center center no-repeat;
  opacity: 0.6;
}
users-table table td.checkbox .checkbox-control input:checked + span,
users-table table th.checkbox .checkbox-control input:checked + span {
  background-image: url('../images/ic_checkbox_checked.svg');
  opacity: 1;
}
users-table table th {
  position: relative;
  background-color: var(--greyLight30);
  min-width: 12px;
  font-weight: normal;
  cursor: default;
}
users-table table th.drag-target::after {
  content: '';
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #f0f0f0;
}
users-table table th.drag-target .th-box:not(.drag) .cont {
  transform: translateX(12px);
}
users-table table th.drag-target .th-box:not(.drag) .cont.sort {
  transform: translateX(15px);
}
users-table table th:first-child {
  border-top-left-radius: 12px;
}
users-table table th:last-child {
  border-top-right-radius: 12px;
}
users-table table th .th-box {
  height: 64px;
  line-height: 64px;
  width: 100%;
  display: inline-block;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 150ms, opacity 150ms;
}
users-table table th .th-box:active,
users-table table th .th-box:active * {
  cursor: grab !important;
}
users-table table th .th-box.drag {
  position: absolute;
  pointer-events: auto !important;
  top: 0;
  z-index: 1;
  padding: 0 0 0 10px;
  opacity: 1;
}
users-table table th .th-box.drag,
users-table table th .th-box.drag * {
  cursor: grabbing !important;
}
users-table table th .th-box.drag .cont {
  color: var(--black);
}
users-table table th .th-box .cont {
  display: inline-block;
  color: #767676;
  white-space: nowrap;
  line-height: 16px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  transition: transform 100ms;
}
users-table table th .th-box .cont.sortable {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
users-table table th .th-box .cont.sort {
  background-color: var(--greyLight20);
  color: var(--black);
  padding: 0 30px 0 11px;
  margin: 0 0 0 -11px;
  height: 26px;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
users-table table th .th-box .cont.sort::after {
  content: '';
  display: inline-block;
  font-size: 12px;
  width: 10px;
  height: 6px;
  background: url('../images/selector.svg') no-repeat center center;
  opacity: 0.4;
  vertical-align: middle;
  position: absolute;
  right: 12px;
  top: 10px;
}
users-table table th .th-box .cont.sort.down::after {
  transform: rotate(180deg);
}
users-table table th .resize-drag-hover {
  position: absolute;
  top: 0;
  right: 6px;
  width: 12px;
  height: 100%;
  z-index: 10;
  cursor: col-resize;
}
users-table table th .resize-drag-hover::before {
  content: '';
  position: absolute;
  height: 30px;
  width: 2px;
  background-color: transparent;
  top: 17px;
  left: 5px;
  transition: background-color 60ms;
}
users-table table th .resize-drag-hover:hover::before {
  background-color: #999999;
}
users-table table th.resize-drag .resize-drag-hover::before {
  background-color: #999999;
}
users-table table th:first-child .resize-drag-hover,
users-table table th:last-child .resize-drag-hover,
users-table table th:nth-last-child(2) .resize-drag-hover {
  display: none;
}
users-table table td {
  background-color: var(--greyLight30);
  border-bottom: solid 1px var(--greyLight20);
  white-space: nowrap;
  -webkit-user-select: text;
  user-select: text;
}
users-table table td .td-inner.drag {
  position: relative;
  z-index: 105;
  width: 100%;
}
users-table table td .overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1px;
}
users-table table td:not(.checkbox):not(.locale) span span {
  display: inline-block;
  vertical-align: middle;
  height: 26px;
  line-height: 26px;
  padding: 0 11px;
  margin: 0 0 0 -11px;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
users-table table td.last {
  text-align: center;
}
users-table table td.last .button-ver2 {
  display: inline-block;
  opacity: 1 !important;
}
users-table table td.vars_count .var {
  cursor: pointer;
}
users-table table td.vars_count .var.disabled {
  pointer-events: none;
}
users-table table td.vars_count .var:hover {
  color: var(--white);
  background-color: #f4ae63;
}
users-table table td.status > span {
  position: relative;
  display: block;
}
users-table table td.status > span > span {
  color: var(--black) !important;
}
users-table table td.status > span > span info {
  display: inline-block;
  vertical-align: middle;
}
users-table table tr.blocked td {
  color: var(--grey);
}
users-table table tr.blocked td a {
  color: var(--grey);
}
users-table table tr.blocked td a .avatar {
  opacity: 0.4;
}
users-table table tr.blocked td span {
  color: var(--grey) !important;
}
users-table table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
users-table table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
users-table table .user-table-user-row_disabled-child {
  opacity: 0.6 !important;
}
users-table table .user-table-user-row_disabled-child > * > * {
  pointer-events: none !important;
}
users-table .user-table-tooltip {
  width: 360px;
  padding: 0 10px;
  pointer-events: none;
}
users-table .user-table-tooltip .tooltip-inner {
  background-color: #053748;
  height: auto;
  text-align: left;
  border-radius: 3px;
  padding: 12px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.4;
  white-space: normal;
}
users-table .user-table-tooltip .tooltip-inner ul {
  margin-bottom: 0;
  padding: 0 0 0 20px;
}
users-table .user-table-tooltip .tooltip-arrow {
  right: 2px;
  margin-top: -9px;
  border-width: 9px 0 9px 8px;
  border-left-color: #053748 !important;
}
body.cf-drag-col-resize *,
body.cf-drag-col * {
  pointer-events: none !important;
  -webkit-user-select: none;
  user-select: none;
}
body .users-table-margin {
  width: 0;
  height: 60px;
}
