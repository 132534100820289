@import '../../../modern-ui/_common/colors/colors.css';
.google-sheets-connector_layout {
  width: calc(100% +  25px * 2);
  height: 66px;
  margin: -10px -25px 14px;
}
.google-sheets-connector-content_layout {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  align-items: center;
}
.google-sheets-connector_green {
  background-color: #33a96e;
}
.google-sheets-creation-text_makeup {
  line-height: 18px;
  font-size: 14px;
  color: var(--white);
  padding: 0 12px;
}
.google-sheets-link_makeup {
  display: block;
  height: 38px;
  line-height: 38px;
  background-color: var(--white);
  color: var(--black) !important;
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 12px;
  font-size: 14px;
  margin: 0 18px 0 0;
  padding: 0 16px;
  text-decoration: none !important;
}
.google-sheets-info_layout {
  line-height: 18px;
  font-size: 14px;
  color: var(--white);
}
.google-sheets-info_light-text {
  opacity: 0.8;
}
.google-sheets-control-title {
  color: var(--black) !important;
  font-weight: 500 !important;
}
.google-sheets-control-title,
.google-sheets-send-all-title {
  font-size: 15px !important;
  display: inline-block;
  text-transform: none !important;
}
.google-sheets-attributes-container {
  display: flex;
}
.google-sheets-checkbox {
  margin-left: -4px;
}
