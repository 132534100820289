.menu-local {
  min-height: 36px;
  margin: 0;
  padding: 0 0 0 225px;
  text-align: right;
}
.menu-local .menu-local-box {
  white-space: normal;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}
.menu-local .menu-local-box .menu-local-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: 26px;
  line-height: 24px;
  border-radius: 4px;
  background-color: var(--white);
  font-size: 14px;
  color: var(--black);
  padding: 0 16px;
  margin: 0 0 16px 16px;
  cursor: pointer;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  transition: box-shadow 80ms ease, border-color 80ms ease, background-color 80ms ease;
}
.menu-local .menu-local-box .menu-local-item .trash-popup {
  left: auto;
  right: -10px;
}
.menu-local .menu-local-box .menu-local-item:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-local .menu-local-box .menu-local-item:hover,
.menu-local .menu-local-box .menu-local-item:focus {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border-top-color: rgba(0, 0, 0, 0.17);
  border-left-color: rgba(0, 0, 0, 0.17);
  border-right-color: rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  outline: none;
}
.menu-local .menu-local-box .menu-local-item:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.22);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
.menu-local .menu-local-box .menu-local-item.act {
  background-color: #053748;
  border: solid 1px #053748;
  color: var(--white);
  box-shadow: none;
}
.menu-local .menu-local-box .menu-local-item.hasDeprecated {
  border: solid 1px red;
  box-shadow: none;
}
.menu-local .menu-local-box .menu-local-item.add {
  position: relative;
  cursor: default;
  padding: 0;
  border: none;
}
.menu-local .menu-local-box .menu-local-item.add .but {
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 16px 0 36px;
}
.menu-local .menu-local-box .menu-local-item.add .but::before {
  position: absolute;
  content: '';
  background: url('../images/ic-add-small.svg') no-repeat left top;
  width: 16px;
  height: 16px;
  left: 14px;
  top: 4px;
  opacity: 0.3;
  transition: opacity 100ms;
}
.menu-local .menu-local-box .menu-local-item.add .but:hover::before {
  opacity: 1;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup {
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 100;
  width: 181px;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 0 0 4px;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: max-height 150ms cubic-bezier(0.22, 0.61, 0.36, 1), opacity 50ms 100ms;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup.open {
  max-height: 310px;
  pointer-events: auto;
  opacity: 1;
  transition: max-height 150ms cubic-bezier(0.22, 0.61, 0.36, 1), opacity 50ms 0ms;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup > div {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  color: var(--black);
  padding: 0 16px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup > div:hover {
  background-color: #fafafa;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup > div.title {
  border-bottom: solid 1px #e1e1e1;
  line-height: 30px;
  height: 31px;
  font-weight: bold;
  margin: 4px 0 4px;
  pointer-events: none;
}
.menu-local .menu-local-box .menu-local-item.add .menu-local-popup > div.title:not(:first-child) {
  margin: 4px 0 4px;
}
