/*! angularjs-slider - v5.8.1 -
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> -
 https://github.com/angular-slider/angularjs-slider -
 2016-10-27 */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 0 0 3px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
}
.rzslider.with-legend {
  margin-bottom: 40px;
}
.rzslider[disabled] {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}
.rzslider[disabled] .rz-bar-wrapper.rz-draggable {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-bar.rz-selection {
  background: #8b91a2;
}
.rzslider[disabled] .rz-ticks .rz-tick {
  cursor: not-allowed;
}
.rzslider[disabled] .rz-ticks .rz-tick.rz-selected {
  background: #8b91a2;
}
.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}
.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
}
.rzslider .rz-bar-wrapper.rz-draggable {
  cursor: move;
}
.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
}
.rzslider .rz-bar.rz-selection {
  z-index: 2;
  background: var(--green);
  border-radius: 2px;
}
.rzslider .rz-pointer {
  top: -6px;
  z-index: 3;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.12);
  outline: none;
}
.rzslider .rz-pointer:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.16);
}
.rzslider .rz-pointer:active {
  background-color: #fafafa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.rzslider .rz-pointer:after {
  /*position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: var(--white);
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  content: '';*/
}
.rzslider .rz-pointer:hover:after {
  /*background-color: var(--white);*/
}
.rzslider .rz-pointer.rz-active {
  z-index: 4;
}
.rzslider .rz-pointer.rz-active:after {
  /*background-color: #451aff;*/
}
.rzslider .rz-bubble {
  bottom: -23px;
  padding: 1px 3px;
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  text-align: center;
  color: var(--green);
}
.rzslider .rz-bubble.rz-selection {
  top: 16px;
}
.rzslider .rz-bubble.rz-limit {
  color: var(--grey);
}
.rzslider .rz-bubble.rz-floor {
  left: 0;
}
.rzslider .rz-bubble.rz-ceil {
  right: 0;
}
.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
.rzslider .rz-ticks .rz-tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-left: 11px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%;
}
.rzslider .rz-ticks .rz-tick.rz-selected {
  background: #0db9f0;
}
.rzslider .rz-ticks .rz-tick .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}
.rzslider .rz-ticks .rz-tick .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
}
.rzslider .rz-ticks.rz-ticks-values-under .rz-tick-value {
  top: initial;
  bottom: -32px;
}
.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}
.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}
.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}
.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}
.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}
.rzslider.rz-vertical .rz-bubble.rz-selection {
  top: auto;
  left: 16px !important;
}
.rzslider.rz-vertical .rz-bubble.rz-floor {
  bottom: 0;
  left: auto;
}
.rzslider.rz-vertical .rz-bubble.rz-ceil {
  top: 0;
  right: auto;
}
.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
}
.rzslider.rz-vertical .rz-ticks .rz-tick {
  margin-top: 11px;
  margin-left: auto;
  vertical-align: middle;
}
.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-value {
  top: initial;
  left: 24px;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-legend {
  top: initial;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}
.rzslider.rz-vertical .rz-ticks.rz-ticks-values-under .rz-tick-value {
  right: 24px;
  bottom: initial;
  left: initial;
}
