div.message-is-sent {
  left: 35%;
  padding: 152px 0 0;
  position: absolute !important;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  width: 400px;
  z-index: 10;
  background: url('../images/pic_bubble_done.svg') no-repeat center top transparent;
}
div.message-is-sent div:nth-child(1) {
  font-weight: 300;
  font-size: 40px;
  line-height: 1.4;
  color: var(--black);
  margin: -20px 0 24px;
}
div.message-is-sent div:nth-child(2) {
  font-size: 16px;
  line-height: 1.5;
  color: #807e7e;
}
