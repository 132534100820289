.history {
  width: 745px;
  margin-left: -8px;
}
.history .tr {
  display: flex;
  /* Safari */
  flex-direction: row;
}
.history .tr > * {
  line-height: 16px;
  white-space: nowrap;
  flex: none;
}
.history .tr > * > div {
  height: 16px;
}
.history .tr > *:nth-child(1) {
  width: 34px;
}
.history .tr > *:nth-child(2) {
  width: 146px;
}
.history .tr > *:nth-child(3) {
  width: 176px;
}
.history .tr > *:nth-child(4) {
  width: 47px;
  text-align: center;
}
.history .tr > *:nth-child(5) {
  width: 86px;
  text-align: right;
}
.history .tr > *:nth-child(6) {
  width: 80px;
  text-align: right;
}
.history .tr > *:nth-child(7) {
  width: 73px;
  text-align: right;
}
.history .tr > *:nth-child(8) {
  width: 71px;
  text-align: right;
}
.history .thead {
  position: sticky;
  padding: 8px 0;
  top: -32px;
  /** padding-top of `.rcol` */
  z-index: 9;
  background-color: var(--greyLight30);
  width: 745px;
}
.history .thead .tr > * {
  position: relative;
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  color: #666666;
}
.history .thead .help-mini {
  top: 0;
  right: -20px;
  position: absolute;
  z-index: 1;
}
.history .tbody .bc-item {
  position: relative;
  width: 736px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 7px;
  background-color: var(--white);
  margin: 0 0 8px;
}
.history .tbody .bc-item .tr {
  height: 100%;
  display: flex;
  align-items: center;
}
.history .tbody .bc-item .tr > div:nth-child(1)::before {
  content: '';
  display: block;
  width: 34px;
  height: 20px;
  background: no-repeat center center;
  background-size: 23px auto;
  position: absolute;
  top: 12px;
  left: -1px;
}
.history .tbody .bc-item .bc-history-date {
  font-size: 11px;
  font-weight: bold;
  color: #3c3f40;
}
.history .tbody .bc-item .bc-history-time {
  font-size: 11px;
  font-weight: bold;
  color: #3c3f40;
}
.history .tbody .bc-item .bc-history-timezone {
  font-size: 12px;
  color: var(--grey);
}
.history .tbody .bc-item .bc-history-type {
  font-size: 12px;
  color: #3c3f40;
  font-weight: bold;
}
.history .tbody .bc-item .bc-history-preview {
  font-size: 10px;
  font-weight: 600;
  color: var(--grey);
  line-height: 20px;
  text-transform: uppercase;
}
.history .tbody .bc-item .bc-history-total {
  font-size: 11px;
  font-weight: bold;
  color: #3c3f40;
}
.history .tbody .bc-item .bc-history-percents {
  font-size: 13px;
  font-weight: bold;
  color: #3c3f40;
}
.history .tbody .bc-item .bc-history-numbers {
  font-size: 11px;
  color: var(--grey);
  line-height: 19px;
}
.history .tbody .bc-item .bc-history-progress {
  position: absolute;
  margin: -9px 0 0 -18px;
}
.history .tbody .bc-item .bc-history-progress-text {
  font-size: 11px;
  color: #aeadad;
}
.history .tbody .bc-item .bc-history-progress-text_blue {
  color: #448af1;
}
.history .tbody .bc-item .bc-history-progress-text_orange {
  color: #ca9b19;
}
.history .tbody .bc-item .bc-history-error {
  color: #e9604a;
}
.history .tbody .bc-item .bc-history-error-icon {
  vertical-align: -1px;
  width: 12px;
  height: 12px;
}
.history .tbody .bc-item .ellipsis {
  display: block;
  max-width: calc(100% - 22px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.history .tbody .bc-item .duplicate {
  position: absolute;
  z-index: 5;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 100ms;
}
.history .tbody .bc-item:hover .duplicate {
  opacity: 0.3;
}
.history .tbody .bc-item::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 100%;
  top: 0;
  right: -30px;
  background-color: transparent;
}
.history .tbody .bc-item.send_now .tr > div:nth-child(1)::before {
  background-image: url('../images/ic_newpost.svg');
}
.history .tbody .bc-item.autoposting .tr > div:nth-child(1)::before {
  background-image: url('../images/ic_autoposting.svg');
}
.history .tbody .bc-item.scheduled .tr > div:nth-child(1)::before {
  background-image: url('../images/ic_scheduled_posts.svg');
}
.history .tbody .bc-item.recurrent .tr > div:nth-child(1)::before,
.history .tbody .bc-item.monthly .tr > div:nth-child(1)::before {
  background-image: url('../images/ic_recurring.svg');
}
.history .tbody .bc-item.sequence .tr > div:nth-child(1)::before {
  background-image: url('../images/ic_sequence.svg');
  background-size: contain;
}
.history .tooltip-bk-copied .tooltip-inner {
  background-color: #053748;
  width: 144px;
  transform: translateX(-60px);
}
.history .tooltip-bk-copied .tooltip-arrow {
  border-top-color: #053748;
}
.history .big-tooltip .tooltip-inner {
  background-color: #053748;
  width: 350px;
  transform: translateX(-150px);
  height: auto;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  padding: 15px;
}
.history .big-tooltip .tooltip-arrow {
  border-bottom-color: #053748;
}
.tooltip.bc-status-tooltip {
  min-width: 100px;
  max-width: 300px;
  z-index: 99999999;
}
.tooltip.bc-status-tooltip .tooltip-inner {
  background-color: #053748;
  width: 244px;
  height: auto;
  text-align: left;
  font-size: 14px;
  color: var(--white);
  line-height: 16px;
  white-space: normal;
  padding: 8px;
  transform: translateX(2px);
}
.tooltip.bc-status-tooltip .tooltip-arrow {
  border-top-color: #053748;
  border-bottom-color: #053748;
  min-width: 0;
  transform: translateX(1px);
}
.bc__cell-button {
  background-color: transparent;
  border: none;
}
.bc__cell--last {
  padding-right: 0;
}
.focus-not-visible .bc__cell-button:focus {
  outline: none;
}
.bc__row-details {
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
}
.bc__row--divider {
  height: 1px;
  background-color: var(--greyLight20);
  margin-bottom: 12px;
}
.bc__dropdown-arrow {
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  margin-right: -12px;
}
.bc__dropdown-arrow--open {
  transform: rotate(180deg);
}
