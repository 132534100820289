.menu-subitem {
  white-space: nowrap;
  position: relative;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: var(--black);
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
  box-sizing: content-box;
  z-index: 1;
}
.menu-subitem.menu-subitem-error {
  z-index: 20;
  color: #e9604a !important;
}
.menu-subitem.menu-subitem-error::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid #e9604a;
  z-index: 1000;
  pointer-events: none;
}
.menu-subitem.menu-subitem-error .preview {
  color: #e9604a !important;
}
.menu-subitem::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  height: 1px;
  width: 410px;
  z-index: 10;
  background-color: var(--greyLight20);
  margin-top: -1px;
}
.menu-subitem.ui-sortable-helper {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0);
  transition: transform 80ms ease, box-shadow 200ms ease, border-color 80ms ease;
  padding: 0 16px !important;
  background-color: #fbfafa;
}
.menu-subitem.ui-sortable-helper::after {
  display: none;
}
.menu-subitem.ui-sortable-helper:active {
  border-color: rgba(0, 0, 0, 0.07) !important;
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0.13);
  transform: scale(1.02, 1.02);
}
.menu-subitem.ui-sortable-helper .trash-popup {
  display: none !important;
}
.menu-subitem.ui-sortable-helper .v-drag {
  opacity: 1 !important;
  left: -25px !important;
}
.menu-subitem.ui-sortable-helper .edit-submenu {
  right: -4px;
}
.menu-subitem.act {
  background-color: var(--greyLight20);
}
.menu-subitem.act::after {
  background-color: var(--greyLight20);
  top: 0;
  width: 100%;
}
.menu-subitem.disabled {
  cursor: default;
  z-index: 22;
}
.menu-subitem.disabled .title {
  opacity: 0.3;
}
.menu-subitem.disabled::after {
  display: none;
}
.menu-subitem.add {
  cursor: pointer;
  font-size: 14px;
  color: var(--black);
  padding: 0 0 0 36px;
}
.menu-subitem.add:last-child::after {
  display: none;
}
.menu-subitem.add::before {
  content: '';
  position: absolute;
  left: 16px;
  right: auto;
  bottom: 25px;
  width: 16px;
  height: 12px;
  background: url('../images/ic-add-small.svg') left center no-repeat;
  opacity: 0.3;
  transition: opacity 100ms;
}
.menu-subitem.add:hover::before {
  opacity: 1;
}
.menu-subitem .title {
  display: flex;
}
.menu-subitem .preview {
  display: flex;
  overflow: hidden;
  padding: 3px 0 0 16px;
  font-size: 12px;
  color: #767676;
  line-height: 16px;
}
.menu-subitem .preview .url {
  display: block;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px;
}
.menu-subitem .preview .block {
  display: block;
}
.menu-subitem .preview .block::after {
  content: '';
  position: absolute;
  top: 1px;
  right: 0;
  height: 46px;
  width: 60px;
  background: linear-gradient(90deg, rgba(251, 250, 250, 0) 0%, #fbfafa 100%);
}
.menu-subitem .preview .block span {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 0 4px;
  text-overflow: ellipsis;
  height: 16px;
  border-radius: 3px;
  background-color: var(--greyLight20);
  margin: 0 4px 0 0;
  max-width: 100%;
}
.menu-subitem .preview .block span::after {
  content: ',';
  position: absolute;
  top: 0;
  height: 16px;
  width: 12px;
  right: -13px;
}
.menu-subitem .preview .block span:last-child::after {
  content: '';
}
.menu-subitem .help-mini {
  position: absolute;
  right: 0;
  top: 22px;
}
.menu-subitem .edit-submenu {
  width: 124px;
  height: 60px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  color: var(--grey);
  background-position: 110px center;
  background-repeat: no-repeat;
  background-image: url('../images/ic-arrow-right.svg');
  padding: 0 0 0 18px;
  overflow: hidden;
}
.menu-subitem .edit-submenu:hover {
  text-decoration: none;
  color: #448af1;
  background-image: url('../images/ic-arrow-right-hover.svg');
}
.menu-subitem .trash-popup {
  bottom: 16px;
  right: -15px;
  left: auto;
  top: auto;
}
.menu-subitem .v-drag {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 24px;
  left: -25px;
  top: 0;
  display: none;
}
.menu-subitem:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-subitem:hover .v-drag {
  opacity: 0.3;
}
.menu-subitem.draged .v-drag {
  display: block;
}
