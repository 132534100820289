/*112px - left/right padding (112*2 = 224px)*/
.sequences-subscribe-editor-popup .line-vertical-centred {
  color: var(--greyDark);
  margin: 0 0 24px 0;
}
.sequences-subscribe-editor-popup .line-vertical-centred > * {
  margin: 0 16px 0 0;
}
.sequences-subscribe-editor-popup .margin-in-popup {
  margin: 0 0 10px;
}
