fb-error-popup {
  position: absolute;
  top: 0;
  left: 0;
}
fb-error-popup modal-container {
  background: no-repeat center center url('../images/bg.gif');
  background-size: cover;
}
fb-error-popup .fb-error-popup_layout {
  width: 600px;
  height: 416px;
}
fb-error-popup .fb-error-popup_spacing {
  padding: 32px 40px;
}
fb-error-popup .fb-error-popup_text {
  font-size: 23px;
  line-height: 1.39;
  letter-spacing: -0.4px;
  color: var(--white);
}
fb-error-popup .fb-error-popup-title_spacing {
  margin: 0 0 156px;
}
fb-error-popup .fb-error-popup-text_spacing {
  padding: 0px 30px 0 0;
}
fb-error-popup .fb-error-popup-actions_layout {
  padding: 0 24px 0 0;
  text-align: right;
}
fb-error-popup .fb-error-popup-button_width {
  width: 112px;
}
fb-error-popup .icon_layout {
  display: inline-block;
}
