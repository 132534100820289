/*112px - left/right padding (112*2 = 224px)*/
.card-item.list .remove {
  left: 257px;
}
div.plugin-modules .card.card-plugin-list {
  width: 256px;
}
.card-item .r-buttons.r-hide {
  opacity: 1 !important;
}
.card-item.list .drag-box {
  position: relative;
  white-space: nowrap;
  display: inline-block;
}
.card-item.list .card {
  display: inline-block;
  white-space: normal;
  margin-right: 8px;
  border-radius: 7px;
  vertical-align: top;
  border-color: #d1d0d0;
}
.card-item.list .card .list-wrapper {
  border: 1px solid #eb5757;
  border-radius: 7px;
  display: flex;
  align-items: stretch;
  width: 656px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.card-item.list .card .list-alert {
  padding: 24px;
  flex: 0 0 396px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 7px 7px 0;
  flex-direction: column;
}
.card-item.list .card .list-alert .alert-title {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 600;
}
.card-item.list .card .list-alert .alert-text {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
}
.card-item.list .card .list-container {
  flex: 0 0 254px;
}
.card-item.list .card .list-container .list-item {
  position: relative;
  border: 1px solid transparent;
}
.card-item.list .card .list-container .list-item.no-valid {
  border-color: var(--red) !important;
  z-index: 2;
}
.card-item.list .card .list-container .list-item + .list-item {
  border-top: solid 1px var(--greyLight20);
  margin-top: -1px;
}
.card-item.list .card .list-container .list-item .card-image-placeholder {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: var(--greyLight20);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.card-item.list .card .list-container .list-item .card-image-placeholder.disabled {
  pointer-events: none;
}
.card-item.list .card .list-container .list-item .card-image-placeholder:hover .card-image-placeholder-content {
  cursor: pointer;
  opacity: 1;
}
.card-item.list .card .list-container .list-item .card-image-placeholder .card-image-placeholder-content {
  opacity: 0.35;
  padding: 0 30px;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Helvetica', 'Arial';
  text-shadow: 0px 0px 0px var(--black);
}
.card-item.list .card .list-container .list-item:hover .card-image-placeholder {
  cursor: default;
}
.card-item.list .card .list-container .list-item:hover .trash-popup:not(.mini) {
  transform: scale(1, 1);
  opacity: 1;
}
.card-item.list .card .list-container .list-item.act.no-cover .image {
  background-color: rgba(233, 96, 74, 0.07);
  box-shadow: 0 0px 0px 1px var(--red);
}
.card-item.list .card .list-container .list-item.act.no-cover .image .img.placeholder .button-image .button-image__title {
  color: var(--red);
}
.card-item.list .card .list-container .list-item.act:first-child {
  padding-top: 34px;
}
.card-item.list .card .list-container .list-item.act:first-child .image.found {
  background-color: rgba(0, 0, 0, 0.5);
}
.card-item.list .card .list-container .list-item.act:first-child .image.found:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.card-item.list .card .list-container .list-item.act:first-child .image.found .img {
  opacity: 1;
}
.card-item.list .card .list-container .list-item.act:first-child:hover .image.found {
  top: 0;
  position: absolute;
}
.card-item.list .card .list-container .list-item.act:first-child:hover .image.found:before {
  background-color: rgba(0, 0, 0, 0.6);
}
.card-item.list .card .list-container .list-item.act:first-child:hover .image.found .button-image-wrapper {
  opacity: 1;
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper {
  width: 100%;
  text-align: center;
  max-height: 0;
  line-height: 2.5;
  opacity: 0;
  transition: opacity 0.2s;
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper .button-image {
  opacity: 1;
  line-height: 1;
  height: auto !important;
  margin: 0;
  position: relative !important;
  top: 0 !important;
  padding-left: 25px;
  background-position: left center;
  background-size: 20px;
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper .button-image:hover .button-image__title {
  color: var(--greyLight20);
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper .button-image + .button-image {
  margin-left: 25px;
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper .button-image .button-image__title {
  font-size: 12px;
}
.card-item.list .card .list-container .list-item.act:first-child .button-image-wrapper .button-image--remove {
  display: none !important;
}
.card-item.list .card .list-container .list-item.act:first-child .image .img.loading {
  line-height: 50px;
}
.card-item.list .card .list-container .list-item.act:first-child .image .img.loading img {
  width: 20px;
  height: 20px;
}
.card-item.list .card .list-container .list-item.act:first-child .content {
  display: block;
  position: relative;
}
.card-item.list .card .list-container .list-item.act:first-child .content .trash-popup:not(.mini) {
  top: -35px;
}
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol {
  width: 100%;
}
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .title textarea,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .body textarea,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .url textarea {
  background-color: transparent;
}
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .title textarea.error,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .body textarea.error,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .url textarea.error,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .title textarea:focus,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .body textarea:focus,
.card-item.list .card .list-container .list-item.act:first-child .content .content-lcol .url textarea:focus {
  background-color: var(--white);
}
.card-item.list .card .list-container .list-item.act:first-child.no-valid {
  border: 0;
}
.card-item.list .card .list-container .list-item.act:first-child.no-valid .trash-popup [tooltip]::after,
.card-item.list .card .list-container .list-item.act:first-child.no-valid .trash-popup [tooltip]::before {
  display: none !important;
}
.card-item.list .card .list-container .list-item.act:first-child.no-valid > *:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px var(--red);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  pointer-events: none;
}
.card-item.list .card .list-container .list-item.act:first-child.no-valid .image button {
  opacity: 1 !important;
  background-image: url('../../../assets/images/new/ic_image_red.svg') !important;
}
.card-item.list .card .list-container .list-item.act:first-child.no-valid .image button span {
  color: var(--red) !important;
}
.card-item.list .card .list-container .list-item:first-child {
  padding-top: 147px;
  z-index: 1;
  border: 0px !important;
}
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .title textarea {
  font-size: 12px;
}
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .body textarea,
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .url textarea {
  font-size: 11px;
}
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .item-btn {
  color: var(--greyLight20);
  border-color: var(--greyLight20);
}
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .item-btn.act {
  color: var(--blue);
  border-color: var(--blue);
}
.card-item.list .card .list-container .list-item:first-child .content .content-lcol.content-lcol-list .item-btn.act.button-error {
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.card-item.list .card .list-container .list-item:first-child > .image {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
}
.card-item.list .card .list-container .list-item:first-child > .image:hover .img.placeholder .button-image.button-image--replace {
  opacity: 1 !important;
}
.card-item.list .card .list-container .list-item:first-child > .image:hover .img.placeholder .button-image.button-image--replace .button-image__title:before {
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea:focus,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea:focus,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea:focus {
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px rgba(0, 0, 0, 0.6);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error::-webkit-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error::-webkit-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error::-webkit-input-placeholder {
  color: var(--red);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error::-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error::-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error::-moz-placeholder {
  color: var(--red);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:-ms-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:-ms-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:-ms-input-placeholder {
  color: var(--red);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:-moz-placeholder {
  color: var(--red);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:focus::-webkit-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:focus::-webkit-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:focus::-webkit-input-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:focus::-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:focus::-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:focus::-moz-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:focus:-ms-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:focus:-ms-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:focus:-ms-input-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea.error:focus:-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea.error:focus:-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error:focus:-moz-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea::-webkit-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea::-webkit-input-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea::-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea::-moz-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea:-ms-input-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea:-ms-input-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .title textarea:-moz-placeholder,
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .body textarea:-moz-placeholder {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea {
  color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea::-webkit-input-placeholder {
  color: var(--grey);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea::-moz-placeholder {
  color: var(--grey);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea:-ms-input-placeholder {
  color: var(--grey);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea:-moz-placeholder {
  color: var(--grey);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .url textarea.error {
  color: #e9604a;
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .item-btn.act {
  color: var(--white);
  background-color: var(--blue);
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .item-btn.act.button-error {
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.card-item.list .card .list-container .list-item:first-child > .image.found + .content .content-lcol.content-lcol-list .item-btn:hover {
  color: var(--white);
  border-color: var(--white);
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder {
  border-bottom: none !important;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder:hover .button-image .button-image__title:before {
  text-shadow: 0px 0px 0px transparent;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder .button-image {
  background-position: center 48px;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder .button-image.button-image--replace {
  pointer-events: none;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder .button-image:hover .button-image__title:before {
  text-shadow: 0px 0px 0px transparent;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder .button-image .button-image__title {
  font-size: 0;
  line-height: 2;
}
.card-item.list .card .list-container .list-item:first-child > .image .img.placeholder .button-image .button-image__title:before {
  content: 'Add item with cover image';
  font-size: 16px;
  font-weight: 400;
  font-family: 'Helvetica', 'Arial';
  text-shadow: 0px 0px 0px var(--black);
}
.card-item.list .card .list-container .list-item .content {
  z-index: 2;
  padding: 8px 6px 7px;
  position: initial;
}
.card-item.list .card .list-container .list-item .content .buttons-wrp {
  position: relative;
  width: 240px;
  display: inline-block;
  line-height: 1;
}
.card-item.list .card .list-container .list-item .content .content-lcol {
  width: 171px;
}
.card-item.list .card .list-container .list-item .content .content-lcol .button-popup-editor.open {
  left: 50%;
}
.card-item.list .card .list-container .list-item .content .content-lcol > div.title {
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 1px;
}
.card-item.list .card .list-container .list-item .content .content-lcol > div.title textarea,
.card-item.list .card .list-container .list-item .content .content-lcol > div.title .wrap-mask {
  font-weight: 600;
  top: -1px;
  color: #1d1d1d;
  padding: 2px 6px 0 !important;
  font-size: 12px;
  line-height: 1.5;
  height: 22px !important;
}
.card-item.list .card .list-container .list-item .content .content-lcol > div.body {
  margin-bottom: 0px;
}
.card-item.list .card .list-container .list-item .content .content-lcol > div.body textarea,
.card-item.list .card .list-container .list-item .content .content-lcol > div.body .wrap-mask {
  font-size: 12px;
  color: var(--greyDark);
  top: -1px;
  height: 22px !important;
  line-height: 1.5;
  padding: 2px 6px 0 !important;
}
.card-item.list .card .list-container .list-item .content .content-lcol > div.url textarea,
.card-item.list .card .list-container .list-item .content .content-lcol > div.url .wrap-mask {
  height: 22px;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  margin-bottom: 0px;
  width: 100%;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn {
  border-radius: 2px;
  border: solid 1px var(--greyLight20);
  background: transparent;
  font-size: 11px;
  line-height: 1;
  color: var(--grey);
  padding: 4px 8px;
  font-weight: 500;
  width: auto;
  height: auto;
  margin-left: 6px;
  display: block;
  float: left;
  min-height: 22px;
  position: relative;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn:hover {
  cursor: pointer;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn:hover .trash-popup.xs {
  transform: scale(0.8);
  opacity: 1;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn.cf-btn:before {
  content: attr(data-placeholder);
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn.act {
  border: solid 1px var(--blue);
  color: var(--blue);
  padding: 0.5px 8px;
  line-height: 22px;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn.act:before {
  display: none;
}
.card-item.list .card .list-container .list-item .content .content-lcol .item-btn.act.button-error {
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .title textarea {
  font-size: 12px;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .body textarea,
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .url textarea {
  font-size: 11px;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .url {
  margin-bottom: 4px;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn .content-box .blocks-suggest {
  font-size: 17px;
  text-align: left;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn .content-box .title-box .title-resize-box .title {
  vertical-align: middle;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn .content-box .hide-box {
  text-align: left;
  font-size: 16px;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn .content-box .hide-box .state-selector .submenu {
  display: none;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn .content-box .hide-box .blocks-suggest .bs-content .blocks-list .trash-popup.mini {
  left: auto;
  right: -8px;
  top: -8px;
  width: 20px;
  height: 20px;
  bottom: auto;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn.expand {
  width: 230px;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn.expand .content-box {
  opacity: 1;
}
.card-item.list .card .list-container .list-item .content .content-lcol.content-lcol-list .item-btn:hover {
  color: var(--blue);
  border-color: var(--blue);
}
.card-item.list .card .list-container .list-item .content .image-mini {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 8px;
  right: 8px;
  border-radius: 4px;
}
.card-item.list .card .list-container .list-item .content .image-mini .image {
  border: 1px dashed var(--greyLight20);
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 31px var(--greyLight20);
}
.card-item.list .card .list-container .list-item .content .image-mini .image:hover {
  border-color: var(--black);
}
.card-item.list .card .list-container .list-item .content .image-mini .image.found {
  border: 0px dashed transparent;
  box-shadow: inset 0px 0px 0px 0px transparent;
}
.card-item.list .card .list-container .list-item .content .image-mini .image.found:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
}
.card-item.list .card .list-container .list-item .content .image-mini .image.found .button-image-wrapper .button-image:hover {
  opacity: 1;
}
.card-item.list .card .list-container .list-item .content .image-mini .image.found .img {
  opacity: 1;
  background-color: var(--greyLight20);
}
.card-item.list .card .list-container .list-item .content .image-mini:hover .image.found {
  top: 0;
  position: absolute;
}
.card-item.list .card .list-container .list-item .content .image-mini:hover .image.found:before {
  background-color: rgba(0, 0, 0, 0.3);
}
.card-item.list .card .list-container .list-item .content .image-mini:hover .image.found .button-image-wrapper {
  opacity: 1;
}
.card-item.list .card .list-container .list-item .content .image-mini .image,
.card-item.list .card .list-container .list-item .content .image-mini .img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.card-item.list .card .list-container .list-item .content .image-mini .image.loading,
.card-item.list .card .list-container .list-item .content .image-mini .img.loading {
  line-height: 83px;
}
.card-item.list .card .list-container .list-item .content .image-mini .image.placeholder,
.card-item.list .card .list-container .list-item .content .image-mini .img.placeholder {
  border: none;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image__title,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image__title {
  display: none !important;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image.button-image--replace,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image.button-image--replace {
  background-position: center center;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper {
  width: 100%;
  height: 100%;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image {
  width: 20px;
  height: 20px !important;
  margin: 0;
  top: auto !important;
  bottom: 8px;
  left: 8px;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image.button-image--replace,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image.button-image--replace,
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image.button-image--crop,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image.button-image--crop,
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image.button-image--remove,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image.button-image--remove {
  background-size: contain;
  background-position: center center;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image.button-image--crop,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image.button-image--crop {
  right: 8px;
  left: auto;
}
.card-item.list .card .list-container .list-item .content .image-mini .image .button-image-wrapper .button-image.button-image--remove,
.card-item.list .card .list-container .list-item .content .image-mini .img .button-image-wrapper .button-image.button-image--remove {
  top: 0 !important;
  left: auto;
  right: 0;
}
.card-item.list .card .list-container .list-item .content .image-mini.act {
  border-color: transparent;
}
.card-item.list .card .list-container .list-item .content .trash-popup {
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  left: auto;
  right: -12px;
  margin: auto;
  position: absolute;
  border-color: rgba(0, 0, 0, 0.2);
}
.card-item.list .card .list-container .list-item .content .trash-popup.xs {
  width: 20px;
  height: 20px;
}
.card-item.list .card .list-container .list-item .content .trash-popup.xs.top {
  top: -10px;
  bottom: auto;
}
.card-item.list .card .list-container .list-item .content .trash-popup.xs.right {
  right: -10px;
}
.card-item.list .card .list-container .list-item.act .image {
  background-color: var(--greyLight20);
}
.card-item.list .card .list-container .list-item.act .image .img.placeholder {
  max-height: 43px;
  background-color: transparent;
}
.card-item.list .card .list-container .list-item.act .image .img.placeholder .button-image.button-image--replace {
  background-position: 76px 11px;
  background-size: 20px;
  pointer-events: all;
}
.card-item.list .card .list-container .list-item.act .image .img.placeholder .button-image .button-image__title {
  font-size: 12px;
  position: absolute !important;
  line-height: 1;
  top: 16px;
  right: 75px;
  left: auto;
}
.card-item.list .card .list-container .list-item.act .image .img.placeholder .button-image .button-image__title:before {
  display: none;
}
.card-item.list .card .list-container .list-item .image {
  margin: 0;
  width: 254px;
  height: 148px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: var(--white);
  overflow: hidden;
}
.card-item.list .card .list-container .list-item .image.found {
  background-color: #353e41;
}
.card-item.list .card .list-container .list-item .image:hover .img {
  opacity: 0.1;
}
.card-item.list .card .list-container .list-item .image:hover .button.remove,
.card-item.list .card .list-container .list-item .image:hover .button.crop {
  opacity: 0.7;
}
.card-item.list .card .list-container .list-item .image:hover .button.remove:hover,
.card-item.list .card .list-container .list-item .image:hover .button.crop:hover {
  opacity: 1;
}
.card-item.list .card .list-container .list-item .image .img {
  width: 254px;
  height: 148px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 100ms;
  background-color: var(--white);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.card-item.list .card .list-container .list-item .image .img.loading {
  text-align: center;
  vertical-align: middle;
  line-height: 160px;
  opacity: 1 !important;
}
.card-item.list .card .list-container .list-item .image .img.placeholder {
  opacity: 1 !important;
  background-color: var(--white);
  position: relative;
  border-bottom: solid 1px var(--greyLight20);
}
.card-item.list .card .list-container .list-item .image .img.placeholder .button-image--replace {
  opacity: 0.35;
  background-position: center 51px;
  background-image: url('../../../assets/images/new/ic_image.svg');
  width: 100%;
  height: 100% !important;
  top: 0 !important;
  margin: 0;
  line-height: 65px !important;
}
.card-item.list .card .list-container .list-item .image .img.placeholder .button-image--replace .button-image__title {
  color: var(--black);
  font-size: 16px;
}
.card-item.list .card .list-container .list-item .image .img.placeholder:hover {
  cursor: pointer;
}
.card-item.list .card .list-container .list-item .image .img.placeholder:hover .button-image--replace {
  opacity: 1;
}
.card-item.list .card .list-container .list-item .image .button {
  width: 24px;
  height: 24px;
  top: 8px;
  opacity: 0;
  z-index: 15;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 100ms;
}
.card-item.list .card .list-container .list-item .image .button.remove {
  background-image: url('../../../assets/images/ic-close-white.svg');
  right: 8px;
}
.card-item.list .card .list-container .list-item .image .button.crop {
  background-image: url('../../../assets/images/ic_crop.svg');
  right: 34px;
}
.card-item.list .card .list-container .action-list .action-item-v2 .content-box .title-box {
  pointer-events: all;
}
.card-item.list .card .list-container .action-list .action-item-v2 .content-box .title-box .title-resize-box .title {
  margin: 0;
  font-weight: 400;
}
.card-item.list .card .list-container .add-item-btn {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--black);
  text-transform: uppercase;
  padding: 6px 6px 6px 12px;
  border-top: solid 1px var(--greyLight20);
  cursor: pointer;
}
.card-item.list .card .list-container .add-item-btn:before {
  content: ' ';
  width: 10px;
  height: 10px;
  display: inline-block;
  background: url('../../../assets/images/ic_add_small.svg') no-repeat center center;
  opacity: 0.25;
  margin-right: 8px;
}
.card-item.list .card .list-container .add-item-btn:hover::before {
  opacity: 1;
}
.card-item.list .action-item-v2 {
  z-index: 19;
}
.card-item.list .action-item-v2.expand {
  z-index: 20;
}
.card-item.list .action-item-v2.expand .content-box .title-box {
  pointer-events: all;
}
.card-item.list .action-item-v2.expand .content-box .title-box .title-resize-box .title {
  margin: 0;
  font-weight: 400;
}
.card-item.list .list-action-item .action-item-v2 .content-box .hide-box .state-selector li.buy {
  display: none;
}
.card-item.list .buy-title-text {
  margin-left: 6px;
  float: right;
  text-align: right;
}
.card-item.list .buy-title-text .button-ver2 {
  display: inline-block;
  vertical-align: middle;
}
.act .button-popup-editor:not(.expand) .title-box .title-resize-box {
  border-width: 0 !important;
}
.act .button-popup-editor {
  position: relative;
  width: auto;
  transform: translate(0, 0);
  opacity: 1;
  left: 0;
  top: 0;
  background: transparent;
  border: 0 solid transparent;
  box-shadow: 0 0 0 0 transparent;
}
.act .button-popup-editor .title-box {
  padding: 0;
  height: auto;
  border: 0 solid transparent;
}
.act .button-popup-editor .title-box .title-resize-box {
  width: auto;
  margin: 0;
  line-height: 0;
}
.act .button-popup-editor .title-box .title-resize-box .title {
  margin: 0;
  line-height: initial !important;
  font-size: 11px !important;
  font-weight: 500;
}
.act .button-popup-editor .hide-box {
  display: none;
}
