.buy-button {
  position: relative;
  padding: 2px 0 0;
}
.buy-button .info {
  margin: 0 0 12px;
}
.buy-button .info span.link {
  color: #448af1;
  cursor: pointer;
}
.buy-button .info span.link:hover {
  text-decoration: underline;
}
.buy-button .info p {
  font-size: 14px;
  line-height: 1.71;
  color: #666666;
  margin: 0;
  padding: 0;
}
.buy-button .info p.done {
  text-decoration: line-through;
  color: var(--grey) !important;
  pointer-events: none;
}
.buy-button .info p.done * {
  color: var(--grey) !important;
  text-decoration: line-through !important;
}
.buy-button .info p.disabled {
  text-decoration: none;
  color: var(--grey) !important;
  pointer-events: none;
}
.buy-button .info p.disabled * {
  color: var(--grey) !important;
  text-decoration: none !important;
}
.buy-button .info .no-enabled {
  padding: 0 0 0 30px;
  background: url(../images/ic_warning.svg) left top no-repeat;
}
.buy-button .info .no-enabled p {
  color: #e9604a;
}
.buy-button .info .no-enabled-v2 {
  margin: 0 0 24px;
}
.buy-button .info .no-enabled-v2 .l-border-box {
  border-left: 2px solid #666666;
  padding: 0 0 0 14px;
  margin: 0 0 12px;
  overflow: hidden;
}
.buy-button .info .no-enabled-v2 .l-border-box .title {
  font-size: 14px;
  font-weight: 600;
  color: #3c3f40;
  margin: -4px 0 6px;
}
.buy-button .info .no-enabled-v2 .l-border-box .title:last-child {
  margin: -4px 0 0;
}
.buy-button .info .no-enabled-v2 .l-border-box ol {
  margin: 0;
  padding: 0;
  counter-reset: item;
}
.buy-button .info .no-enabled-v2 .l-border-box ol li {
  margin: 0 0 6px;
  padding: 0;
  display: block;
  font-size: 14px;
  color: #3c3f40;
}
.buy-button .info .no-enabled-v2 .l-border-box ol li.disabled {
  color: var(--grey) !important;
  text-decoration: none !important;
  pointer-events: none;
}
.buy-button .info .no-enabled-v2 .l-border-box ol li::before {
  counter-increment: item;
  content: counter(item) '.';
}
.buy-button .info .no-enabled-v2 .l-border-box ol li:last-child {
  margin: 0;
}
.buy-button .info .no-enabled-v2 .foot {
  font-size: 14px;
  color: var(--grey);
}
.buy-button .cost-items-list {
  margin: 0 0 16px;
}
.buy-button .cost-items-list .cost-item {
  position: relative;
  height: 35px;
  line-height: 34px;
  background-color: var(--greyLight20);
  border-bottom: 1px solid var(--white);
  padding: 0 12px;
  white-space: nowrap;
}
.buy-button .cost-items-list .cost-item:first-child {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.buy-button .cost-items-list .cost-item:first-child .cost-item-name {
  width: 162px;
}
.buy-button .cost-items-list .cost-item:first-child .cost-item-name input {
  width: 162px;
}
.buy-button .cost-items-list .cost-item:last-child {
  border-bottom: none;
  height: 34px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.buy-button .cost-items-list .cost-item input {
  font-size: 14px;
  line-height: 1.29;
  color: var(--black);
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.buy-button .cost-items-list .cost-item .cost-item-name {
  display: inline-block;
  vertical-align: top;
  width: 208px;
}
.buy-button .cost-items-list .cost-item .cost-item-name input {
  width: 208px;
}
.buy-button .cost-items-list .cost-item .cost-item-value {
  display: inline-block;
  vertical-align: top;
  width: 90px;
  text-align: right;
}
.buy-button .cost-items-list .cost-item .cost-item-value input {
  width: 100%;
  text-align: right;
}
.buy-button .cost-items-list .cost-item .cost-item-value select {
  font-size: 14px;
  width: 66px;
  margin: 0 0 0 8px;
}
.buy-button .cost-items-list .cost-item .cost-item-value .currency-symbol {
  font-size: 14px;
  line-height: 1.29;
}
.buy-button .cost-items-list .cost-item .trash-popup {
  left: auto;
  right: -6px;
}
.buy-button .cost-items-list .cost-item:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.buy-button .cost-items-list .cost-item.add {
  font-size: 12px;
  color: var(--black);
  cursor: pointer;
}
.buy-button .cost-items-list .cost-item.add::before {
  display: inline-block;
  vertical-align: -4px;
  content: '';
  background: url('../images/ic_add_small.svg') center center no-repeat;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
  opacity: 0.3;
  transition: opacity 100ms;
}
.buy-button .cost-items-list .cost-item.add:hover::before {
  opacity: 1;
}
.buy-button .advanced-settings {
  overflow: hidden;
  max-height: 40px;
  transition: max-height 200ms, overflow 0ms 0ms;
}
.buy-button .advanced-settings .link {
  width: 160px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  background: url('../images/ic_dropdown_off.svg') right top no-repeat;
  cursor: pointer;
}
.buy-button .advanced-settings.open {
  max-height: 800px;
  overflow: visible;
  transition: max-height 200ms, overflow 0ms 200ms;
}
.buy-button .advanced-settings.open .link {
  background-image: url('../images/ic_dropdown_on.svg');
  margin: 0 0 16px;
}
.buy-button .advanced-settings .title {
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  margin: 0 0 8px;
}
.buy-button .advanced-settings .title i {
  font-style: normal;
  color: var(--grey);
}
.buy-button .advanced-settings .requested-user-info {
  line-height: 24px;
  margin: 0 0 16px;
}
.buy-button .advanced-settings .requested-user-info label {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 24px;
  color: var(--black);
  margin: 0 6px 0 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.buy-button .advanced-settings .requested-user-info label:last-child {
  margin: 0;
}
.buy-button .advanced-settings .requested-user-info label > *:first-child {
  margin: 0 1px 0 0;
}
.buy-button .advanced-settings .requested-user-info label:nth-child(3) {
  pointer-events: none;
  opacity: 0.6;
}
.buy-button .advanced-settings .public-vars-suggest {
  margin: 0 0 16px;
}
.buy-button .advanced-settings .art-textarea-expand {
  margin: 0 0 16px;
  max-height: 438px;
}
.buy-button .advanced-settings .art-textarea-expand br {
  content: '';
}
.buy-button .advanced-settings .art-textarea-expand * {
  display: inline;
}
