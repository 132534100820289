.third-party-analytics {
  margin-bottom: -24px;
}
.third-party-analytics .section-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  color: var(--black);
  margin-bottom: 24px;
}
.third-party-analytics .section-wrapper {
  transition: max-height 200ms linear;
  max-height: 400px;
  overflow: hidden;
  margin: 0 -24px;
  padding: 0 24px;
}
.third-party-analytics .section-wrapper.collapsed {
  max-height: 0;
}
.third-party-analytics .section-content {
  font-size: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}
.third-party-analytics .section-content .analytics-item {
  min-height: 96px;
  padding: 24px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -1px;
}
.third-party-analytics .section-content .analytics-item:hover .analytics-item-description.connected .grey {
  opacity: 1;
}
.third-party-analytics .section-content .analytics-item .analytics-item-img {
  margin-right: 38px;
  display: inline-block;
}
.third-party-analytics .section-content .analytics-item .analytics-item-img img {
  width: 139px;
  height: auto;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  color: var(--black);
  margin-right: 16px;
  display: inline-block;
  flex: 1;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description .grey {
  color: #797979;
  font-weight: 300;
  font-size: 14px;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected {
  font-size: 0;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected .connected-container {
  font-size: 14px;
  display: inline-block;
  vertical-align: bottom;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected .connected-container .connected-img {
  background: url('../images/ic_ok_small.svg') center center no-repeat;
  background-size: contain;
  border: 1px solid var(--green);
  border-radius: 20px;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected .connected-container .connected-text {
  color: var(--green);
  vertical-align: middle;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected .grey {
  cursor: pointer;
  opacity: 0;
}
.third-party-analytics .section-content .analytics-item .analytics-item-description.connected .grey:hover {
  color: #131313;
}
.third-party-analytics .section-content .analytics-item .analytics-item-btn {
  display: inline-block;
  flex: 0 0 auto;
}
.third-party-analytics .analytics-switcher-btn {
  display: inline-block;
  margin-bottom: 24px;
}
