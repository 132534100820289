section.settings .button-with-margin {
  margin-top: 24px;
}
section.settings .modern-button-wrapper {
  float: right;
  margin: 18px 24px 0;
}
section.settings > div .border-box.messenger-extensions {
  width: 100%;
  min-width: unset;
  overflow: visible;
  position: relative;
  margin-bottom: 0;
  min-height: 228px;
}
section.settings > div .border-box.messenger-extensions p {
  margin: 0 0 12px;
}
section.settings > div .border-box.messenger-extensions p a {
  text-decoration: none;
  color: #448af1;
}
section.settings > div .border-box.messenger-extensions p a:hover {
  text-decoration: underline;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list {
  margin: -8px 0;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item {
  position: relative;
  margin: 8px 0;
  display: inline-block;
  width: 100%;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item .messenger-extensions-input {
  width: 100%;
  height: 36px;
  font-size: 16px;
  border-radius: 7px;
  padding: 6px 16px;
  color: #3c3f40;
  background-color: #fafafa;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  vertical-align: bottom;
  box-sizing: border-box;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item .messenger-extensions-input.error:not(:focus) {
  border: solid 1px #e9604a;
  color: #e9604a;
  background-color: #ffefed;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item .trash-popup {
  right: -38px;
  top: 4px;
  transform: scale(0.5, 0.5);
  left: auto;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item .trash-popup::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 8px;
  left: -8px;
  top: 0;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item:hover .trash-popup {
  opacity: 1;
  transform: scale(1);
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item .status {
  content: ' ';
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  background: no-repeat center center;
  background-size: cover;
  background-color: #fafafa;
  display: none;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item.pending .status {
  display: block;
  background-image: url('../images/spinner_b.svg');
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item.success .status {
  display: block;
  background-image: url('../images/ic_ok.svg');
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item.error .status {
  background-color: #ffefed;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .domain-list .domain-item.error .messenger-extensions-input:not(:focus) {
  border: solid 1px #e9604a;
  color: #e9604a;
  background-color: #ffefed;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .button-ver2.add {
  height: 36px;
  line-height: 34px;
  display: inline-block;
  margin-top: 24px;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .button-ver2.add::before {
  content: '';
  background: url('../images/ic_add_small.svg') center center no-repeat;
  width: 16px;
  height: 16px;
  margin: 0 2px 0 -5px;
  opacity: 0.3;
  display: inline-block;
  vertical-align: -3px;
}
section.settings > div .border-box.messenger-extensions .messenger-extensions__wrapper .button-ver2.add:hover::before {
  opacity: 1;
}
section.settings > div .border-box.messenger-extensions::before {
  content: '';
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../images/spinner_b.svg') center center no-repeat var(--white);
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}
section.settings > div .border-box.messenger-extensions.no-spiner::before {
  display: none;
}
