.set-up-variable {
  padding: 0 0 45px;
}
.set-up-variable .content .l-col .line .control {
  margin: 0 25px 18px 0 !important;
}
.set-up-variable .content .l-col .line .control.top {
  margin: 0 25px 0 0 !important;
}
.set-up-variable .content .l-col .line:last-child .control {
  margin: 0 25px 0 0 !important;
}
.set-up-variable .content .l-col .line > .trash-popup {
  left: auto;
  right: -38px;
  top: 4px;
}
.set-up-variable .content .l-col .line:hover > .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.set-up-variable .footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 69px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  padding: 16px 24px;
}
.set-up-variable .footer .button-ver2 {
  display: inline-block;
}
.set-up-variable .footer .button-ver2.add {
  padding: 0 20px 0 18px;
}
.set-up-variable .footer .button-ver2.add::before {
  content: '';
  display: inline-block;
  vertical-align: -3px;
  width: 16px;
  height: 16px;
  margin: 0 6px 0 0;
  background: url('../images/ic_add_small.svg') no-repeat center center;
  opacity: 0.3;
  transition: opacity 100ms;
}
.set-up-variable .footer .button-ver2.add:hover::before {
  opacity: 1;
}
