users-table-col-selector {
  display: block;
}
users-table-col-selector .position-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
users-table-col-selector .position-box .arrow-down {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 12px;
}
users-table-col-selector .position-box .cols-list {
  right: -48px;
  top: 13px;
  padding: 10px 0 0;
  position: absolute;
  z-index: 1;
  width: 285px;
  height: auto;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
users-table-col-selector .position-box .cols-list::before {
  content: '';
  position: absolute;
  top: 34px;
  left: 0;
  background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 269px;
  height: 10px;
  z-index: 10;
  pointer-events: none;
}
users-table-col-selector .position-box .cols-list input {
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: var(--black);
  border: none;
  outline: none;
  margin: 0 0 0 22px;
}
users-table-col-selector .position-box .cols-list .scroll-box {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 18px 0;
  min-height: 10px;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item {
  height: 32px;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label {
  display: inline-flex;
  line-height: 30px;
  align-items: center;
  cursor: pointer;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label .art-checkbox {
  margin: 2px 3px 0 0;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label .col-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 210px;
  line-height: 26px;
  padding: 0 7px;
  border-radius: 8px;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label .col-title i {
  color: #aaaaaa;
  font-style: normal;
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label:hover .col-title.system {
  background-color: var(--greyLight20);
  color: var(--black);
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label:hover .col-title.system i {
  color: var(--black);
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label:hover .col-title.user {
  background-color: #906d60;
  color: var(--white);
}
users-table-col-selector .position-box .cols-list .scroll-box .cols-list-item label:hover .col-title.user i {
  color: var(--white);
}
