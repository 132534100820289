.attribute-values-adv {
  -webkit-user-select: none;
  user-select: none;
}
.attribute-values-adv.cf-input {
  min-height: 36px;
  border-radius: 7px;
  font-size: 16px;
  padding: 2px 5px;
  line-height: 16px;
}
.attribute-values-adv.cf-input .attr-input-box {
  display: inline-block;
  position: relative;
  line-height: 26px;
  max-width: 100%;
  width: 100%;
  padding: 1px 0 0;
}
.attribute-values-adv.cf-input .attr-input-box * .not-set {
  background-color: #c3c3c3 !important;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input {
  outline: none;
  display: block;
  -webkit-user-select: auto;
  user-select: all;
  color: var(--black);
  width: 100%;
  min-width: 100%;
  margin: 1px 0 0;
  white-space: normal;
  word-wrap: break-word;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input * {
  -webkit-user-select: auto;
  user-select: all;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input br {
  content: '';
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span {
  background-color: #f4ae63;
  color: var(--white);
  font-size: 16px;
  padding: 2px 7px 3px;
  border-radius: 13px;
  max-width: 100%;
  margin: 1px 0;
  vertical-align: 0;
  word-wrap: break-word;
  word-break: break-all;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span:not(.glob) {
  margin: 0 1px 1px;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span.var {
  background-color: #906d60;
  color: var(--white);
  font-size: 14px;
  padding: 4px 3px 3px;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span.var ab {
  font-size: 16px;
  color: #e0995d;
  display: inline-block;
  transform: translateY(-1px);
  padding: 0 4px;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span.var av {
  vertical-align: 1px;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span.var.system {
  background-color: #9e80bd;
}
.attribute-values-adv.cf-input .attr-input-box .attr-input span.var.system ab {
  color: #e0e0e0;
}
.attribute-values-adv .attr-popup {
  position: absolute;
  border-radius: 7px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.13);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;
  padding: 0;
  width: auto;
  max-width: 100%;
  min-width: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height cubic-bezier(0.2, 0.7, 0.5, 1) 150ms 0ms, opacity 50ms 100ms;
  pointer-events: none;
  left: 0;
}
.attribute-values-adv .attr-popup.open {
  max-height: 267px;
  opacity: 1;
  transition: max-height cubic-bezier(0.2, 0.7, 0.5, 1) 150ms 0ms, opacity 100ms 50ms;
  pointer-events: all;
}
.attribute-values-adv .attr-popup ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-width: unset;
}
.attribute-values-adv .attr-popup ul li {
  display: flex;
  height: 48px;
  line-height: 47px;
  margin: 0;
  cursor: pointer;
  background-color: var(--white);
  order: 2;
  justify-content: space-between;
}
.attribute-values-adv .attr-popup ul li.not-set > span.name span {
  background-color: #c3c3c3;
}
.attribute-values-adv .attr-popup ul li:last-child {
  border-radius: 0 0 3px 0;
}
.attribute-values-adv .attr-popup ul li.act {
  background-color: #f3f3f3;
}
.attribute-values-adv .attr-popup ul li > span {
  display: table-cell;
  padding: 0 5px;
}
.attribute-values-adv .attr-popup ul li > span.name span {
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #f4ae63;
  padding: 0 10px;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  max-width: 211px;
  overflow: hidden;
  margin-top: 11px;
  text-overflow: ellipsis;
}
.attribute-values-adv .attr-popup ul li > span.name.noresponse span {
  background-color: #c3c3c3;
}
.attribute-values-adv .attr-popup ul li > span.stat {
  color: #b5b5b5;
  font-size: 14px;
  padding: 0 12px 0 0;
}
.attribute-values-adv .attr-popup ul li.firstValue {
  order: 1;
}
.attribute-values-adv .attr-popup.var .name span {
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #906d60;
  padding: 0 18px;
  font-size: 14px;
  line-height: 26px;
  color: var(--white);
  max-width: 211px;
  overflow: hidden;
  margin-top: 11px;
  text-overflow: ellipsis;
  position: relative;
}
.attribute-values-adv .attr-popup.var .name span::before {
  content: '{{';
  font-size: 16px;
  position: absolute;
  left: 4px;
  top: -1px;
  color: #e0995d;
}
.attribute-values-adv .attr-popup.var .name span::after {
  content: '}}';
  font-size: 16px;
  position: absolute;
  right: 4px;
  top: -1px;
  color: #e0995d;
}
.attribute-values-adv .attr-popup.var .name span.system {
  background-color: #9e80bd;
}
.attribute-values-adv .attr-popup.var .name span.system::after,
.attribute-values-adv .attr-popup.var .name span.system::before {
  color: #e0e0e0;
}
