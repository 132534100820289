div.ref-link {
  display: inline-block;
  font-size: 0;
  margin-left: 16px;
  align-self: center;
  -webkit-align-self: center;
}
div.ref-link .ref-link-container {
  display: table;
  width: 100%;
  height: 40px;
}
div.ref-link .ref-link-container .ref-link-icon {
  display: inline-block;
  content: '';
  background: url('../images/ic_link_referral.png') no-repeat center 0;
  background-size: 48px;
  width: 48px;
  height: 18px;
  position: relative;
  top: 0px;
  margin-right: 6px;
  cursor: pointer;
}
div.ref-link .ref-link-container .ref-link-icon:hover {
  background-position: center -18px;
}
div.ref-link .ref-link-container.ref-active .ref-link-icon {
  background-position: center -18px;
}
div.ref-link .ref-link-container .tooltip.top {
  top: -34px !important;
}
div.ref-link .ref-link-container .tooltip.top .tooltip-inner {
  background-color: #053748;
}
div.ref-link .ref-link-container .tooltip.top .tooltip-arrow {
  border-top-color: #053748;
}
div.ref-link .ref-link-container .dtc {
  display: table-cell;
  vertical-align: middle;
  float: none;
  line-height: 1;
}
div.ref-link .page-link {
  display: inline-block;
  position: relative;
  top: 0;
  cursor: pointer;
  white-space: nowrap;
}
div.ref-link .page-link .page-link-full {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  display: inline-block;
  color: #3c3f40;
  padding: 10px 0;
}
div.ref-link .page-link .icon-copy {
  background: url('../images/ic_clipboard_small.svg') no-repeat center;
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
div.ref-link .page-link .icon-copy:hover {
  background: url('../images/ic_clipboard_small_hover.svg') no-repeat center;
}
div.ref-link .page-link:hover .page-link-full {
  color: #448af1;
}
div.ref-link .page-link:hover .icon-copy {
  opacity: 1;
  pointer-events: all;
}
div.ref-link .page-link .copy-tooltip {
  pointer-events: none;
}
div.ref-link .page-link .copy-tooltip .tooltip-inner {
  width: 102px;
  min-width: 51px;
  height: 20px;
  border-radius: 2px;
  background-color: #053748;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: var(--white);
  line-height: 16px;
}
div.ref-link .page-link .copy-tooltip .tooltip-arrow {
  border-right-color: #053748;
}
div.ref-link .page-link.copied-tooltip .copy-tooltip .tooltip-inner {
  width: 51px !important;
  background-color: var(--green);
}
div.ref-link .page-link.copied-tooltip .copy-tooltip .tooltip-arrow {
  border-right-color: var(--green);
}
div.ref-link .page-link .input-to-copy-link {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: -99999px;
  font-size: 12px;
}
