@import '../../modern-ui/_common/colors/colors.css';
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
user-filter-component {
  width: 100%;
  display: block;
  max-width: 800px;
}
user-filter-component .fix-positioning-one-pixel-please {
  position: relative;
  top: -1px;
}
user-filter-component[disabled] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
user-filter-component[disabled] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
user-filter-component[disabled] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
user-filter-component .add-button-fix-margin {
  margin-left: 2px;
  margin-bottom: 2px;
}
user-filter-component .container_size {
  display: flex;
  flex-direction: column;
}
user-filter-component .ui-picker-row {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 2px 0;
}
user-filter-component .ui-picker-row:last-child:not(:first-child) {
  align-items: flex-start;
}
user-filter-component .icon-margin {
  margin-top: 6px;
}
user-filter-component .icon-positioning {
  position: relative;
  top: -1px;
}
user-filter-component .operator_container_size {
  width: 53px;
  display: inline-flex;
  justify-content: flex-start;
  flex: 0 0 auto;
  margin-left: 8px;
}
user-filter-component ui-picker-view {
  flex: 1 1 auto;
}
user-filter-component drop-down-menu {
  margin: 2px;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}
user-filter-component drop-down-menu text-field-box {
  background-color: var(--white);
}
user-filter-component .text-field[contenteditable='true']:focus:empty:before {
  color: transparent;
}
