@import '../../modern-ui/_common/colors/colors.css';
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
user-filter-component-data-loader {
  display: block;
  min-height: 100px;
}
user-filter-component-data-loader .user-filter-loader-box_content-align {
  align-items: center;
  justify-content: center;
}
user-filter-component-data-loader .user-filter-loader-box_position {
  position: absolute;
  left: 0;
  top: 0;
  z-index: var(--zindex-popover);
}
