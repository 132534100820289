unbind-popup .error-alert .button-container_inside_spacing > *:not(:first-child) {
  margin-left: 8px;
}
unbind-popup .error-alert .modal-alert_spacing {
  padding: 24px 60px 24px 40px;
}
unbind-popup .error-alert .modal-actions_spacing {
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;
}
unbind-popup .error-alert .modal-alert-highlight {
  font-weight: 600;
}
