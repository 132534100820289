.statistics .section-header {
  font-size: 32px;
  color: var(--black);
  margin: 0;
  padding: 10px 0;
  font-weight: 300;
}
.statistics .custom-graf-tooltip {
  position: absolute;
  padding: 12px;
  border-radius: 3px;
  background-color: #053748;
  pointer-events: none;
  transform: translate(0, -103%);
  transition: opacity 100ms, left 200ms, right 200ms;
  font-size: 14px;
  line-height: 1.29;
  color: var(--white);
  white-space: nowrap;
  opacity: 0;
}
.statistics .custom-graf-tooltip > div {
  margin: 0 0 7px;
}
.statistics .custom-graf-tooltip > div.date {
  white-space: normal;
  max-width: 220px;
}
.statistics .custom-graf-tooltip > div.date:before {
  display: none;
}
.statistics .custom-graf-tooltip > div.bad-day {
  max-width: 280px;
  min-width: 280px;
}
.statistics .custom-graf-tooltip > div:last-child {
  margin: 0;
}
.statistics .custom-graf-tooltip::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #053748;
}
.statistics .legend {
  margin: 0 0 0 24px;
  padding: 19px 0 0;
}
.statistics .legend > span {
  display: inline-block;
  margin: 0 32px 0 0;
  font-size: 13px;
  line-height: 1.54;
  color: var(--black);
}
.statistics .legend > span .asc-tooltip {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../../../assets2/images/ic_info_small.svg') center center no-repeat;
  vertical-align: -3px;
  cursor: pointer;
  opacity: 0.3;
  margin-left: 4px;
}
.statistics .legend > span .asc-tooltip:hover {
  opacity: 1;
}
.statistics .g-colors > *::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 0 6px 0 0;
  vertical-align: -1px;
}
.statistics .g-colors > *.total::before {
  background-color: transparent;
}
.statistics .g-colors > *.subscribed::before {
  background-color: #4a90e2;
}
.statistics .g-colors > *.unsubscribed::before {
  background-color: #ffc98c;
}
.statistics .g-colors > *.active-users::before {
  background-color: #448af1;
}
.statistics .g-colors > *.user-message-read::before {
  background-color: #ffc98c;
}
.statistics .g-colors > *.user-input-received::before {
  background-color: #5bd8ac;
}
.statistics .statistics-main .tooltip.legend-tooltip {
  width: 260px;
}
.statistics .statistics-main .tooltip.legend-tooltip .tooltip-inner {
  background-color: #053748;
  height: auto;
  text-align: left;
}
.statistics .statistics-main .tooltip.legend-tooltip .tooltip-arrow {
  border-top-color: #053748;
}
.statistics-export-chart-button {
  position: absolute;
  right: 24px;
  top: 21px;
}
