.tooltip {
  top: -9999px;
}
.tooltip.system-tooltip {
  min-width: 100px;
  max-width: 300px;
  width: auto;
  z-index: var(--zindex-popover);
}
.tooltip.system-tooltip .tooltip-inner {
  background-color: #053748;
  white-space: normal;
  height: auto;
  text-align: left;
}
.tooltip.system-tooltip .tooltip-arrow {
  min-width: 0;
}
.tooltip.system-tooltip.right {
  margin-left: 8px;
}
.tooltip.system-tooltip.right .tooltip-arrow {
  border-right-color: #053748;
}
.tooltip.system-tooltip.top {
  margin-top: -3px;
}
.tooltip.system-tooltip.top .tooltip-arrow {
  border-top-color: #053748;
}
