div.plugin-modules .card {
  width: 680px;
  height: auto;
  border-radius: 7px;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.13);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.07);
  padding: 24px;
}
div.plugin-modules .card .title {
  padding: 0;
  color: var(--black);
  margin: 0 0 14px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
div.plugin-modules .card .title.has-error {
  color: #e9604a;
}
div.plugin-modules .card .title.has-error::after {
  content: 'ERROR(S)';
  font-size: 11px;
  font-weight: normal;
  color: #e9604a;
  margin: 0 0 0 6px;
  text-transform: uppercase;
  top: 16px;
  position: absolute;
}
div.plugin-modules .card .title.icon {
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0 0 0 32px;
  background-size: auto 24px;
}
div.plugin-modules .card .title.icon.form {
  background-image: url('../images/icons/ic_r_forms@2x.png');
}
div.plugin-modules .card .title.icon.json {
  background-image: url('../images/icons/ic_r_json.png');
}
div.plugin-modules .card .title.icon.subscriptions-manager {
  background-image: url('../images/icons/ic_r_sub_mngr.svg');
}
div.plugin-modules .card .title.icon.zapier {
  background-image: url('../images/icons/ic_r_zapier@2x.png');
}
div.plugin-modules .card .title.icon.subscriber {
  background-image: url('../images/icons/ic_r_subscriber.svg');
}
div.plugin-modules .card .title.icon.set-up-variable {
  background-image: url('../images/icons/ic_parameter_round.svg');
}
div.plugin-modules .card .title.icon.go-to-block {
  background-image: url('../images/icons/ic_go_to_block_round.svg');
}
div.plugin-modules .card .title.icon.to-sequence {
  background-image: url('../images/icons/ic_to-sequence-round.svg');
}
div.plugin-modules .card .title.icon.rm-fr-sequences {
  background-image: url('../images/icons/rm-fr-sequences.svg');
}
div.plugin-modules .card .title.icon.list-plgn {
  background-image: url('../images/icons/ic_list_round.svg');
}
div.plugin-modules .card .title.icon.audio-plugin {
  background-image: url('../images/icons/ic_audio_round.svg');
}
div.plugin-modules .card .title.icon.video-plugin {
  background-image: url('../images/icons/ic_video_round.svg');
}
div.plugin-modules .card .title.icon.chatroom {
  background-image: url('../images/icons/ic_chatroom_round.svg');
}
div.plugin-modules .card .title.icon.location {
  background-image: url('../images/icons/ic_location_round.svg');
}
div.plugin-modules .card .title.icon.send-email {
  background-image: url('../images/icons/ic_r_email.png');
}
div.plugin-modules .card .title.icon.google-sheet {
  background-image: url('../images/icons/ic_gsheets_round.svg');
}
div.plugin-modules .card .title.icon.handover {
  background-image: url('../images/icons/ic_handover_round.svg');
}
div.plugin-modules .card .title.icon.google-search {
  background-image: url('../../../../modern-components/Plugins/common/icons/google_search.svg');
}
div.plugin-modules .card .title.icon.bing-search {
  background-image: url('../../../../modern-components/Plugins/common/icons/bing_search.svg');
}
div.plugin-modules .card .title.icon.swiftype-search {
  background-image: url('../../../../modern-components/Plugins/common/icons/ic_r_swiftype.svg');
}
div.plugin-modules .card .title.icon.rss-plugin {
  background-image: url('../../../../modern-components/Plugins/common/icons/icon_RSS_Import.svg');
}
div.plugin-modules .card .title.icon.subscriptions-list {
  background-image: url('../../../../modern-components/Plugins/common/icons/icon_Subscription_List.svg');
}
div.plugin-modules .card .title.icon.digest {
  background-image: url('../../../../modern-components/Plugins/common/icons/icon_Digest.svg');
}
div.plugin-modules .card .title.icon.live-chat {
  background-image: url('../../../../modern-components/Plugins/common/icons/icon_Live_Chat.svg');
}
div.plugin-modules .card .info {
  font-size: 14px;
  line-height: 1.71;
  color: #767676;
  margin: 0 0 24px;
}
div.plugin-modules .card .info:last-child {
  margin: 0;
}
div.plugin-modules .card .info.bottom {
  margin: 5px 0 -13px;
}
div.plugin-modules .card .info + .info {
  margin: -16px 0 24px;
}
div.plugin-modules .card .subtitle {
  font-size: 15px;
  line-height: 22px;
  color: var(--black);
  letter-spacing: 0.1px;
}
div.plugin-modules .card .subtitle.handover-subtitle {
  margin-bottom: 1px;
}
div.plugin-modules .card .handover-inputs {
  position: relative;
  display: flex;
}
div.plugin-modules .card .handover-error-message {
  font-size: 15px;
  line-height: 22px;
  color: #e9604a;
}
div.plugin-modules .card .handover-error-message-icon {
  display: inline;
  margin-right: 7px;
}
div.plugin-modules .card .question-link {
  position: absolute;
  top: 24px;
  right: 24px;
}
div.plugin-modules .card .handover-dropdown {
  width: 142px;
}
div.plugin-modules .card .handover-dropdown .handover-dropdown-item {
  width: 142px;
}
div.plugin-modules .card .escaped-text {
  padding: 1px 6px;
  background: var(--greyLight30);
  border: 1px solid var(--grey);
  border-radius: 4px;
  color: #de614d;
}
div.plugin-modules .card .black {
  color: var(--black);
}
div.plugin-modules .card .info.auto-post {
  margin: 0 0 12px;
}
div.plugin-modules .card .info.chatroom-info {
  margin: 0;
}
div.plugin-modules .card .info.big {
  line-height: 28px;
  font-size: 16px;
  color: var(--black);
  margin: 0 0 16px;
}
div.plugin-modules .card .location-list {
  margin: 12px 0 0;
  font-size: 0;
}
div.plugin-modules .card .location-plugin-container {
  position: relative;
}
div.plugin-modules .card .click-veil {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  opacity: 0.9;
  z-index: 10;
}
div.plugin-modules .card .veil-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 110;
  position: absolute;
}
div.plugin-modules .card .veil-text_head {
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
  letter-spacing: 1.3px;
}
div.plugin-modules .card .veil-text_content {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 420px;
}
div.plugin-modules .card .variable-location {
  display: inline-block;
  height: 20px;
  line-height: 18px;
  border-radius: 12px;
  background-color: #9e80bd;
  padding: 0 8px;
  color: var(--white);
  font-size: 16px;
  margin: 0 8px 4px 0;
}
div.plugin-modules .card .variable-location::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: 0;
  color: #e0e0e0;
}
div.plugin-modules .card .variable-location::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: 0;
  color: #e0e0e0;
}
div.plugin-modules .card a {
  text-decoration: none;
  color: var(--blue);
  cursor: pointer;
}
div.plugin-modules .card a:hover {
  text-decoration: underline;
}
div.plugin-modules .card .content.autopost ol {
  width: auto;
}
div.plugin-modules .card .content.autopost input {
  width: 100%;
}
div.plugin-modules .card .content.autopost div.status {
  top: 6px;
}
div.plugin-modules .card .content {
  position: relative;
  padding: 0;
}
div.plugin-modules .card .content.zap {
  min-height: 250px;
}
div.plugin-modules .card .content .sub-title {
  margin: 0 0 4px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: var(--greyDark);
  text-transform: uppercase;
}
div.plugin-modules .card .content .copy-link {
  font-weight: bold;
  word-wrap: break-word;
}
div.plugin-modules .card .content ol {
  width: 356px;
  margin: 0;
  padding: 0;
  color: var(--greyDark);
}
div.plugin-modules .card .content ol li {
  list-style-position: inside;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 16px;
}
div.plugin-modules .card .content ol li:last-child {
  margin-bottom: 0;
}
div.plugin-modules .card .content .control {
  position: relative;
}
div.plugin-modules .card .content .control .control-title {
  font-weight: normal;
  margin: 0 0 6px 1px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 24px;
  color: #767676;
}
div.plugin-modules .card .content .control.required .control-title::after,
div.plugin-modules .card .content .control.pr-star .control-title::after {
  content: ' *';
  color: #e9604a;
}
div.plugin-modules .card .content .control .variable-input {
  text-indent: 11px;
  padding-bottom: 2px;
}
div.plugin-modules .card .content .control .variable-input-focused {
  padding-right: 20px;
}
div.plugin-modules .card .content .control .variable-mask-wrapper {
  height: 24px;
  position: absolute;
  width: 184px;
  top: 36px;
  left: 5px;
  overflow-x: hidden;
  pointer-events: none;
}
div.plugin-modules .card .content .control .variable-mask {
  direction: rtl;
  display: inline-block;
  text-align: left;
  pointer-events: none;
  color: transparent;
  float: left;
  padding: 0 5px;
  border-radius: 13px;
  max-width: 185px;
  white-space: pre;
}
div.plugin-modules .card .content .control .variable-mask:before {
  content: '{{';
  color: var(--black);
  margin-left: 18px;
}
div.plugin-modules .card .content .control .variable-mask:after {
  content: '}}';
  color: var(--black);
  margin-right: 4px;
  position: absolute;
  left: 6px;
  top: 0;
}
div.plugin-modules .card .content .control .variable-mask.show-mask {
  background-color: #906d60;
  color: var(--white);
  padding: 1px 8px 2px;
  direction: ltr;
  max-width: none;
  line-height: 21px;
}
div.plugin-modules .card .content .control .variable-mask.show-mask::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
  margin-right: 0;
  margin-left: 0;
}
div.plugin-modules .card .content .control .variable-mask.show-mask::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
  margin-left: 0px;
}
div.plugin-modules .card .content .control .variable-mask.show-mask.system {
  background-color: #9e80bd;
}
div.plugin-modules .card .content .control .variable-mask.show-mask.system:before,
div.plugin-modules .card .content .control .variable-mask.show-mask.system:after {
  color: #e0e0e0;
}
div.plugin-modules .card .content .control .input-mask {
  max-width: 162px;
}
div.plugin-modules .card .content .control > label {
  display: block;
  -webkit-user-select: none;
  user-select: none;
}
div.plugin-modules .card .content .control > label input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
div.plugin-modules .card .content .control > label input[type='checkbox'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 2px #dfdddd;
  margin: 0 8px 0 0;
  vertical-align: -2px;
}
div.plugin-modules .card .content .control > label input[type='checkbox']:checked + span {
  background: url('../images/ic_done_w.svg') center center no-repeat #448af1;
  border: none;
  background-size: cover;
}
div.plugin-modules .card .content .control > label input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
div.plugin-modules .card .content .control > label input[type='radio'] + span {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 8px 0 0;
  vertical-align: -1px;
}
div.plugin-modules .card .content .control > label input[type='radio']:checked + span::after {
  content: ' ';
  z-index: 5;
  top: 3px;
  left: 3px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #448af1;
  border-radius: 50%;
}
div.plugin-modules .card .content .control .selector {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
  margin: 0;
  vertical-align: inherit;
}
div.plugin-modules .card .content .control .selector ul {
  width: 102%;
  max-height: 274px;
}
div.plugin-modules .card .content .control .selector.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
div.plugin-modules .card .content .control input,
div.plugin-modules .card .content .control .input {
  display: inline-block;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  color: var(--black);
  padding: 0 0 0 11px;
}
div.plugin-modules .card .content .control input.input-audio,
div.plugin-modules .card .content .control .input.input-audio,
div.plugin-modules .card .content .control input.input-video,
div.plugin-modules .card .content .control .input.input-video,
div.plugin-modules .card .content .control input.input-rounded,
div.plugin-modules .card .content .control .input.input-rounded {
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
}
div.plugin-modules .card .content .control .status {
  content: ' ';
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 36px;
  cursor: pointer;
  background: no-repeat center center;
  background-size: cover;
  display: none;
}
div.plugin-modules .card .content .control .status.status--no-tooltip {
  cursor: default;
}
div.plugin-modules .card .content .control.error input {
  border: solid 2px #e9604a;
  line-height: 32px;
  color: #e9604a;
  background-color: #ffefed;
  padding-right: 27px;
}
div.plugin-modules .card .content .control.error input::-webkit-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input::-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input:-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input:-ms-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input:-o-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input:input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.error input.input-audio,
div.plugin-modules .card .content .control.error input.input-video,
div.plugin-modules .card .content .control.error input.input-rounded {
  border: solid 1px #e9604a;
}
div.plugin-modules .card .content .control.error .status {
  display: block;
  background-image: url('../images/ic_error.svg');
}
div.plugin-modules .card .content .control.error .status:not(.status--no-tooltip):hover::after {
  position: absolute;
  content: attr(error);
  min-width: 100px;
  border-radius: 3px;
  background-color: #053748;
  padding: 12px;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: var(--white);
  word-wrap: break-word;
  white-space: nowrap;
}
div.plugin-modules .card .content .control.error .status:not(.status--no-tooltip):hover::before {
  position: absolute;
  content: ' ';
  left: 50%;
  bottom: 28px;
  width: 10px;
  height: 13px;
  z-index: 99;
  background-color: #053748;
  transform: translateX(-50%) rotate(45deg);
}
div.plugin-modules .card .content .control.process input {
  padding-right: 27px;
}
div.plugin-modules .card .content .control.process .status {
  display: block;
  background-image: url('../images/spinner_b.svg');
  background-size: 20px auto;
}
div.plugin-modules .card .content .control.ok input {
  padding-right: 27px;
}
div.plugin-modules .card .content .control.ok .status {
  display: block;
  background-image: url('../images/ic_done_green.svg');
}
div.plugin-modules .card .content .control.size-308.ok .wrap-mask,
div.plugin-modules .card .content .control.size-308.process .wrap-mask,
div.plugin-modules .card .content .control.size-308.error .wrap-mask {
  width: 280px;
}
div.plugin-modules .card .content .control.size-365.ok .wrap-mask,
div.plugin-modules .card .content .control.size-365.process .wrap-mask,
div.plugin-modules .card .content .control.size-365.error .wrap-mask {
  width: 337px;
}
div.plugin-modules .card .content .control.size-515.ok .wrap-mask,
div.plugin-modules .card .content .control.size-515.process .wrap-mask,
div.plugin-modules .card .content .control.size-515.error .wrap-mask {
  width: 487px;
}
div.plugin-modules .card .content .control.new-line {
  display: block !important;
  margin: 0 !important;
}
div.plugin-modules .card .content .control .comment {
  font-size: 14px;
  line-height: 1.71;
  color: #767676;
  margin: -10px 0 16px;
  white-space: normal;
}
div.plugin-modules .card .content .control.control-suggest {
  margin-top: 30px !important;
}
div.plugin-modules .card .content .control.control-suggest .control-title {
  position: absolute;
  top: -30px;
}
div.plugin-modules .card .content .control.control-suggest .status {
  display: none;
}
div.plugin-modules .card .content .control.control-suggest.error textarea {
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.07);
  border: solid 1px #e9604a;
  background-color: #fff5f4;
  padding: 4px 6px 0 5px;
}
div.plugin-modules .card .content .control.control-suggest.error textarea::-webkit-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.control-suggest.error textarea::-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.control-suggest.error textarea:-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.control-suggest.error textarea:-ms-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.control-suggest.error textarea:-o-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control.control-suggest.error textarea:input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .popup-box input {
  height: 26px;
  line-height: 26px;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
div.plugin-modules .card .content .control.attr-container .variable-mask-wrapper {
  top: 6px;
}
div.plugin-modules .card .content .control.attr-container .status {
  top: 6px;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input::-webkit-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input::-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input:-moz-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input:-ms-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input:-o-input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.error input:input-placeholder {
  color: #e9604a;
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input {
  color: var(--black);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input::-webkit-input-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input::-moz-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input:-moz-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input:-ms-input-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input:-o-input-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .public-vars-suggest .cf-input.focus input:input-placeholder {
  color: var(--grey);
}
div.plugin-modules .card .content .control .suggest-template-multiline-box {
  width: 100%;
  line-height: 24px;
}
div.plugin-modules .card .content .l-col {
  width: 100%;
}
div.plugin-modules .card .content .l-col .h-block {
  margin: 0 0 32px;
}
div.plugin-modules .card .content .l-col .h-block:last-child {
  margin: 0;
}
div.plugin-modules .card .content .l-col .line {
  display: block;
  white-space: nowrap;
  position: relative;
}
div.plugin-modules .card .content .l-col .line.white-block {
  background-color: var(--white);
  border-radius: 7px;
  padding: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
}
div.plugin-modules .card .content .l-col .line.border-block {
  padding: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  border-radius: 7px;
  border: solid 2px #dfdddd;
}
div.plugin-modules .card .content .l-col .line:last-child .control {
  margin: 0 11px 0 0;
}
div.plugin-modules .card .content .l-col .line .control {
  display: inline-block;
  vertical-align: top;
  margin: 0 13px 18px -2px;
  padding: 0 0 0 1px;
}
div.plugin-modules .card .content .l-col .line .control.size-100 {
  width: 100px;
}
div.plugin-modules .card .content .l-col .line .control.size-120 {
  width: 120px;
}
div.plugin-modules .card .content .l-col .line .control.size-158 {
  width: 158px;
}
div.plugin-modules .card .content .l-col .line .control.size-181 {
  width: 181px;
}
div.plugin-modules .card .content .l-col .line .control.size-191 {
  width: 191px;
}
div.plugin-modules .card .content .l-col .line .control.size-195 {
  width: 195px;
}
div.plugin-modules .card .content .l-col .line .control.size-199 {
  width: 199px;
}
div.plugin-modules .card .content .l-col .line .control.size-285 {
  width: 285px;
}
div.plugin-modules .card .content .l-col .line .control.size-294 {
  width: 294px;
}
div.plugin-modules .card .content .l-col .line .control.size-300 {
  width: 300px;
}
div.plugin-modules .card .content .l-col .line .control.size-308 {
  width: 308px;
}
div.plugin-modules .card .content .l-col .line .control.size-337 {
  width: 337px;
}
div.plugin-modules .card .content .l-col .line .control.size-360 {
  width: 360px;
}
div.plugin-modules .card .content .l-col .line .control.size-365 {
  width: 365px;
}
div.plugin-modules .card .content .l-col .line .control.size-406 {
  width: 406px;
}
div.plugin-modules .card .content .l-col .line .control.size-515 {
  width: 515px;
}
div.plugin-modules .card .content .l-col .line .control.size-616 {
  width: 616px;
}
div.plugin-modules .card .content .l-col .line .control.size-full {
  width: 100%;
}
div.plugin-modules .card .content .l-col .line .control input,
div.plugin-modules .card .content .l-col .line .control .input {
  display: block;
  width: 100%;
}
div.plugin-modules .card .content .l-col .line .control .selector {
  width: 100%;
}
div.plugin-modules .card .content .l-col .line .control p {
  margin: 8px 0 0 1px;
  white-space: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: var(--grey);
}
div.plugin-modules .card .content .l-col .line .control .button.add {
  width: 100%;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: var(--black);
  text-align: center;
  text-transform: uppercase;
}
div.plugin-modules .card .content .l-col .line .control .button.add::before {
  background: url(../images/ic_add_big.svg) no-repeat center center;
  content: ' ';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
  opacity: 0.25;
  background-size: 20px auto;
  vertical-align: -1px;
}
div.plugin-modules .card .content .l-col .line .control .button.add:hover {
  border: solid 1px rgba(0, 0, 0, 0.8);
}
div.plugin-modules .card .content .l-col .line .control .button.add:hover::before {
  opacity: 0.8;
}
div.plugin-modules .card .content .l-col .line .control .button-ver2.add {
  height: 36px;
  line-height: 34px;
}
div.plugin-modules .card .content .l-col .line .control .button-ver2.add::before {
  background: url(../images/ic_add_small.svg) no-repeat center center;
  content: '';
  width: 16px;
  height: 16px;
  margin: 0 7px 0 -5px;
  opacity: 0.3;
  display: inline-block;
  vertical-align: -3px;
}
div.plugin-modules .card .content .l-col .line .control .button-ver2.add:hover::before {
  opacity: 1;
}
div.plugin-modules .card .content .l-col .line .control div.text {
  white-space: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #807e7e;
}
div.plugin-modules .card .content .l-col .line .button.remove {
  display: inline-block;
  opacity: 0;
  margin: 25px 0 0 -14px;
  left: 632px;
  top: 6px;
}
div.plugin-modules .card .content .l-col .line .button.remove:hover {
  opacity: 1 !important;
}
div.plugin-modules .card .content .l-col .line .button-ver2.trash-popup.remove-btn {
  display: inline-block;
  opacity: 0;
  margin: 25px 0 0 -14px;
  left: 654px;
  top: 9px;
}
div.plugin-modules .card .content .l-col .line .button-ver2.trash-popup.remove-btn:hover {
  opacity: 1 !important;
  transform: scale(1);
}
div.plugin-modules .card .content .l-col .line .button-ver2.trash-popup.remove-btn.remove-btn-topper {
  margin: 0px 0 0 -14px;
  top: 4px;
}
div.plugin-modules .card .content .l-col .line:hover .button.remove {
  opacity: 1;
  transform: scale(1);
}
div.plugin-modules .card .content .l-col .line:hover .button-ver2.trash-popup.remove-btn {
  opacity: 1;
  transform: scale(1);
}
div.plugin-modules .card .content .l-col .line .type-dropdown {
  width: 100%;
}
div.plugin-modules .card .content .l-col .line .type-dropdown.open ul {
  z-index: 6;
}
div.plugin-modules .card .content .l-col .line .type-dropdown.open:after {
  z-index: 7;
}
div.plugin-modules .card .content .r-col {
  position: absolute;
  top: 0;
  right: 0;
  width: 235px;
  height: 100%;
}
div.plugin-modules .card .content .r-col .section {
  position: absolute;
  top: 24px;
  width: 100%;
  height: auto;
  border-radius: 7px;
  background-color: var(--white);
  padding: 24px 22px 0;
  border: 1px solid #e6e6e6;
}
div.plugin-modules .card .content .r-col .section .line {
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  margin: 0 0 24px;
}
div.plugin-modules .card .content .r-col .section .line .control {
  display: inline-block;
}
div.plugin-modules .card .content .r-col .section .line .control .selector {
  width: 187px;
}
div.plugin-modules .card .content .r-col .section .line .control > p {
  color: #999999;
  font-size: 14px;
  margin: 3px 0 0;
}
div.plugin-modules .card .content.audio-content .line .control .status,
div.plugin-modules .card .content.video-content .line .control .status {
  top: 6px;
}
div.plugin-modules .card .asc {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../images/ic_info.svg') center center no-repeat;
  background-size: cover;
  opacity: 0.25;
}
div.plugin-modules .card .asc .popup-help {
  width: 380px;
  height: 1px;
  overflow: hidden;
  border-radius: 7px;
  background-color: #f0efef;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  z-index: 1000;
  padding: 24px;
  font-size: 14px;
  line-height: 1.4;
  color: #807e7e;
  opacity: 0;
  right: 42px;
  top: 50%;
  transform: translateY(-50%);
  transition: height 0ms 100ms, opacity 100ms 0ms;
  pointer-events: auto;
  cursor: default;
}
div.plugin-modules .card .asc .popup-help::after {
  content: ' ';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #f0efef;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  border-left: solid 1px rgba(0, 0, 0, 0.05);
  top: 50%;
  right: -10px;
  transform: translateY(-50%) rotate(135deg);
}
div.plugin-modules .card .asc .popup-help h3 {
  color: var(--black);
  margin: 0 0 24px;
  padding: 0;
}
div.plugin-modules .card .asc .popup-help ul {
  padding: 0 0 0 20px;
  list-style-type: disc;
  margin: -10px 0 0;
}
div.plugin-modules .card .asc .popup-help p {
  padding: 0;
  margin: 0 0 14px;
}
div.plugin-modules .card .asc .popup-help p:nth-last-child(1) {
  margin: 0 !important;
}
div.plugin-modules .card .asc .popup-help .button.close {
  pointer-events: auto;
  position: absolute;
  right: 24px;
  top: 24px;
  background-size: 20px auto;
  opacity: 0.25;
}
div.plugin-modules .card .asc .popup-help .button.close:hover {
  opacity: 1;
}
div.plugin-modules .card .asc:hover {
  opacity: 1;
}
div.plugin-modules .card .asc.act {
  opacity: 1;
}
div.plugin-modules .card .asc.act .popup-help {
  opacity: 1;
  overflow: visible;
  transition: height 0ms 0ms, opacity 100ms 0ms;
  height: auto;
}
div.plugin-modules .card .error-animation {
  transition: 200ms linear max-height;
  overflow: hidden;
}
div.plugin-modules .card .error-animation.ng-enter {
  max-height: 8px;
}
div.plugin-modules .card .error-animation.ng-enter.ng-enter-active {
  max-height: 50px;
}
div.plugin-modules .card .error-animation.ng-leave {
  max-height: 50px;
}
div.plugin-modules .card .error-animation.ng-leave.ng-leave-active {
  max-height: 8px;
}
div.plugin-modules .card .show-user-filter-link {
  display: inline-block;
  border-bottom: 1px dotted currentColor;
  line-height: 16px;
}
div.plugin-modules .card .show-user-filter-link:hover {
  text-decoration: none;
}
div.plugin-modules .card .show-user-filter-link:after {
  display: inline-block;
  content: '';
  width: 10px;
  height: 6px;
  background: url('../images/arrow.svg') no-repeat center center;
  vertical-align: middle;
  margin: 0 0 0 4px;
}
div.plugin-modules .block-autocomplete {
  display: inline-block;
  vertical-align: middle;
  margin: -7px 0 -7px 8px !important;
}
div.plugin-modules .block-autocomplete .suggest .host {
  height: 36px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
}
div.plugin-modules .block-autocomplete .suggest .host .autocomplete {
  width: 100% !important;
  max-height: 206px;
}
div.plugin-modules .block-autocomplete .suggest .host .tags {
  border: none;
  padding: 0;
  background-color: transparent;
}
div.plugin-modules .block-autocomplete .suggest .host .tags .tag-list {
  display: block;
  width: auto;
  max-width: inherit;
  padding: 3px;
}
div.plugin-modules .block-autocomplete .suggest .host .tags .tag-list .tag-item {
  font: 16px/28px var(--font);
  color: var(--white);
  float: none;
  max-width: inherit;
  padding: 0 10px;
  height: 28px;
  border-radius: 3px;
  background-color: #448af1 !important;
}
div.plugin-modules .block-autocomplete .suggest .host .tags .tag-list .tag-item span {
  max-width: inherit;
  margin: -4px 0 0 0;
}
div.plugin-modules .block-autocomplete .suggest .host .tags .tag-list .tag-item .remove-button {
  font-size: 22px;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: -2px;
  color: var(--white);
  opacity: 1;
  pointer-events: auto;
}
div.plugin-modules .block-autocomplete .suggest .host .tags input {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100% !important;
  border: none;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
}
div.plugin-modules .block-autocomplete.selected input {
  width: 1px !important;
  opacity: 0;
  height: 1px !important;
  position: absolute;
}
div.plugin-modules .block-autocomplete.selected .host {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.045);
  border: solid 1px rgba(0, 0, 0, 0.07);
  pointer-events: none;
}
.help-image {
  margin: 0 0 16px;
}
.help-image > .line {
  margin: 0 0 6px;
}
.help-image > .line:last-child {
  margin: 0;
}
.help-image > .line.left {
  text-align: left;
}
.help-image > .line.right {
  text-align: right;
}
.help-image > .line.center {
  text-align: center;
}
.help-image > .line.size-170 {
  width: 170px;
}
.help-image > .line.size-295 {
  width: 295px;
}
.help-image > .line > .box {
  display: inline-block;
  margin: 0 1px;
}
.help-image > .line > .box.white {
  width: 80px;
  border-radius: 8px;
  background-color: var(--white);
  overflow: hidden;
}
.help-image > .line > .box .img {
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.help-image > .line > .box .img.top-img1 {
  width: 80px;
  height: 32px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_top_img1.svg');
}
.help-image > .line > .box .img.top-img2 {
  width: 80px;
  height: 32px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_top_img2.svg');
}
.help-image > .line > .box .img.top-img3 {
  width: 80px;
  height: 32px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_top_img3.svg');
}
.help-image > .line > .box .img.top-text1 {
  width: 80px;
  height: 60px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_top_text1.svg');
}
.help-image > .line > .box .img.top-text2 {
  width: 80px;
  height: 60px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_top_text2.svg');
}
.help-image > .line > .box .img.card1-long {
  width: 80px;
  height: 60px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_card1_long.svg');
}
.help-image > .line > .box .img.card2-long {
  width: 80px;
  height: 60px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_card2_long.svg');
}
.help-image > .line > .box .img.middle-text1 {
  width: 80px;
  height: 28px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_middle_text1.svg');
}
.help-image > .line > .box .img.middle-btnsubscr {
  width: 80px;
  height: 18px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_middle_btnsubscr.svg');
}
.help-image > .line > .box .img.middle-btn {
  width: 80px;
  height: 18px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_middle_btn.svg');
}
.help-image > .line > .box .img.bottom-text1 {
  width: 80px;
  height: 28px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_bottom_text1.svg');
}
.help-image > .line > .box .img.bottom-btnshow {
  width: 80px;
  height: 18px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_bottom_btnshow.svg');
}
.help-image > .line > .box .img.bottom-btn {
  width: 80px;
  height: 18px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_bottom_btn.svg');
}
.help-image > .line > .box .img.msg-bot1 {
  width: 120px;
  height: 28px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_msg_bot1.svg');
}
.help-image > .line > .box .img.msg-user1 {
  width: 120px;
  height: 28px;
  background-image: url('../../../../assets2/images/help/pic_plgn_help_msg_user1.svg');
}
.communication-info-link {
  position: absolute;
  top: 32px;
  right: 34px;
  width: 20px;
  height: 20px;
  background: url('../images/ic_info.svg') center center no-repeat;
  background-size: cover;
  text-decoration: none;
  opacity: 0.25;
  cursor: pointer;
}
.communication-info-link:hover {
  opacity: 1;
}
.digest-body .digest-body-line::after {
  display: table;
  clear: both;
  content: '';
}
.digest-body .digest-body-line--margin-bottom {
  margin-bottom: 5px;
}
.digest-body .digest-body-line__info-wrapper {
  float: left;
  margin-left: 25px;
  width: 303px;
}
.digest-body .digest-body-line__title {
  margin-top: 0;
  margin-bottom: 8px;
}
.digest-body .textarea-wrapper {
  position: relative;
  float: left;
  width: 270px;
  height: 59px;
  padding: 5px 2px 4px;
  border-radius: 18px;
  background-color: #ebebeb;
  margin: 0px;
}
.digest-body .textarea-wrapper .title {
  margin: 0 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: normal !important;
}
.digest-body .textarea-wrapper .wrap-mask {
  width: 252px;
  height: 44px !important;
  top: -1px;
  left: 7px;
}
.digest-body .textarea-wrapper .error:not(:focus) {
  border: solid 2px #e9604a;
  padding: 2px 5px 0;
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus)::-webkit-input-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus)::-moz-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus):-moz-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus):-ms-input-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus):-o-input-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper .error:not(:focus):input-placeholder {
  color: #e9604a;
}
.digest-body .textarea-wrapper textarea {
  display: block;
  width: 252px;
  height: 45px;
  padding: 3px 6px 0;
  margin: 2px 7px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
  color: var(--black);
  white-space: inherit;
  border-radius: 4px;
  border: solid 1px transparent;
  background-color: transparent;
  transition: border-color 100ms;
  overflow: hidden;
  resize: none;
}
.digest-body .textarea-wrapper textarea:focus {
  border: solid 1px #d9d9d9;
  background-color: var(--white);
  transition: border-color 100ms;
}
.digest-body .textarea-wrapper textarea:focus + .discount {
  opacity: 1;
}
.digest-body .textarea-wrapper .discount {
  position: absolute;
  right: 8px;
  bottom: 1px;
  text-align: center;
  font-size: 10px;
  color: var(--white);
  opacity: 0;
  transition: opacity 100ms;
  line-height: 14px;
  padding: 0 4px;
  height: 14px;
  border-radius: 8px;
  background-color: #5dc991;
}
.digest-body .editable {
  color: #807e7e;
}
.digest-body .digest-text {
  border-bottom-left-radius: 5px;
}
.digest-body .subscription-text {
  border-top-left-radius: 5px;
  height: 92px;
}
.digest-body .subscription-text span.discount {
  top: 32px;
}
.digest-body .bottom-sub-title {
  background: var(--white);
  border-radius: 5px;
  display: inline-block;
  padding: 5px;
  margin-left: 12px;
}
.digest-body::after {
  display: table;
  clear: both;
  content: '';
}
.pluginCardInfoText {
  /** same as `.plugin-modules .card .info` */
  font-size: 14px;
  line-height: 1.71;
  color: #767676;
  margin: 0 0 24px;
}
