@media (max-width: 1024px) {
  section.settings {
    padding-left: 0 !important;
  }
}
section.settings {
  padding-left: 0 !important;
  background-color: var(--greyLight20);
  min-height: 100%;
  display: table;
  width: 100%;
}
section.settings .settings-small__item {
  width: 497px;
  margin-bottom: 32px;
}
section.settings .no-margin {
  margin-bottom: 0;
}
section.settings > div {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
section.settings .dropdown {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 80%;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  text-align: center;
  font-size: 16px;
  color: var(--black);
  outline: none;
}
section.settings .rcol {
  padding: 0 32px 32px;
  width: 1088px;
}
section.settings .rcol.no-paddings {
  padding: 0;
}
section.settings .rcol .border-box {
  min-height: 181px;
  margin: 0 auto 32px;
  padding: 24px;
  position: relative;
  border-radius: 7px;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.13);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.07);
}
section.settings .rcol .border-box.connect-to-page.height-149 {
  min-height: 149px;
}
section.settings .rcol .border-box.connect-to-page.height-149 .state.state-pages-list,
section.settings .rcol .border-box.connect-to-page.height-149 .state.state-other-user-connected {
  min-height: 149px;
}
section.settings .rcol .border-box .publish-header {
  text-transform: capitalize;
}
section.settings .rcol .border-box h2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--black);
  padding: 0;
  margin: -4px 0 16px 0;
  text-align: left;
  display: inline-block;
}
section.settings .rcol .border-box .permission-header {
  margin-bottom: 24px;
}
section.settings .rcol .border-box h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  text-align: left;
  margin: 0 0 12px;
}
section.settings .rcol .border-box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
  margin: 0 0 24px;
}
section.settings .rcol .border-box.connect-to-page {
  padding: 24px;
  overflow: hidden;
  position: relative;
}
section.settings .rcol .border-box.connect-to-page::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../images/spinner_b.svg') center center no-repeat var(--white);
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}
section.settings .rcol .border-box.connect-to-page.no-paddings {
  padding: 0;
}
section.settings .rcol .border-box.connect-to-page.no-spiner::before {
  display: none;
}
section.settings .rcol .border-box.connect-to-page p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  margin: 0 0 16px;
  color: #666666;
}
section.settings .rcol .border-box.connect-to-page .button-box {
  font-size: 0;
}
section.settings .rcol .border-box.connect-to-page .button-box .db-link {
  padding: 0;
  margin-left: 32px;
}
section.settings .rcol .border-box.connect-to-page .button {
  display: inline-block;
  height: 36px;
  width: auto;
  line-height: 36px;
  border-radius: 7px;
  padding: 0 24px;
  font-size: 14px;
  text-transform: uppercase;
}
section.settings .rcol .border-box.connect-to-page .state {
  min-height: 181px;
  display: none;
  overflow: hidden;
  position: relative;
  background-color: var(--white);
}
section.settings .rcol .border-box.connect-to-page .state.act {
  display: block;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-img {
  display: inline-block;
  background: url('../images/pic_settings_prmssns.png') no-repeat left top var(--white);
  background-size: cover;
  width: 200px;
  height: 188px;
  margin-right: 24px;
  vertical-align: top;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content {
  display: inline-block;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem {
  height: 40px;
  border-radius: 7px;
  background-color: var(--greyLight20);
  padding: 0 16px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
  color: #666666;
  margin-bottom: 12px;
  display: inline-block;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem .schem-point {
  display: inline-block;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem .schem-point .schem-logo {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: -2px 4px 0 4px;
  vertical-align: middle;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem .schem-point.chatbot .schem-logo {
  background: url('../images/group-2.svg') no-repeat center center;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem .schem-point.facebook .schem-logo {
  background: url('../images/ic-facebook-blue.svg') no-repeat center center;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content .schem .schem-point .aditional {
  margin: 0px 0 0px 9px;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content p {
  width: 740px;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content p .dark {
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content p .underline {
  text-decoration: underline;
}
section.settings .rcol .border-box.connect-to-page .state .state-wrapper .state-content p a {
  text-decoration: none;
  color: #448af1;
}
section.settings .rcol .border-box.connect-to-page .state.state-default .db-link,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void .db-link {
  float: right;
  padding: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void .state-img {
  background: url('../images/pic_settings_pageadd.png') no-repeat left top var(--white);
  background-size: cover;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void p.full-black {
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void p.full-black.top-text {
  margin-bottom: 12px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void .button.refresh {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  margin: 0 0 0 12px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void .button.refresh::before {
  content: ' ';
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;
  background: url(../images/ic_refresh.svg) no-repeat center center;
  vertical-align: -7px;
  background-size: 20px auto;
  opacity: 0.3;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list-void .button.refresh:hover::before {
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list,
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected {
  padding: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .r-col,
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .r-col,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .r-col {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  border-left: 2px solid #dfdddd;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .r-col .button-box,
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .r-col .button-box,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .r-col .button-box {
  position: absolute;
  top: 50%;
  transform: translateY(-44px);
  -webkit-transform: translateY(-44px);
  width: 100%;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .r-col .button-box .button,
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .r-col .button-box .button,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .r-col .button-box .button {
  display: block;
  margin: 0 auto 16px;
  width: 208px;
  padding: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .icon-connected {
  background: url('../images/ic_ok.svg') no-repeat center center;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  background-size: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .icon-connecting {
  background: url('../images/spinner_b.svg') no-repeat center center;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  background-size: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header {
  padding: 20px 24px 16px 24px;
  height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header {
  margin: 0;
  display: inline-block;
  position: absolute;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-hide-add,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-hide-remove,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-hide-add,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-hide-remove {
  transition: opacity 80ms cubic-bezier(0.1, 0.5, 0.5, 1);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-leave.ng-leave-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-hide,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-leave.ng-leave-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-leave.ng-leave-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-hide {
  opacity: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header.ng-enter.ng-enter-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connecting.ng-enter.ng-enter-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header.ng-enter.ng-enter-active {
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connecting.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connecting.ng-leave.ng-leave-active {
  width: 0;
  opacity: 0;
  transition: width 400ms cubic-bezier(0.1, 0.5, 0.5, 1), opacity 80ms cubic-bezier(0.1, 0.5, 0.5, 1);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connecting.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connecting.ng-enter.ng-enter-active {
  width: 24px;
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-leave {
  transition: transform 200ms cubic-bezier(0.1, 0.5, 0.5, 1), opacity 80ms cubic-bezier(0.1, 0.5, 0.5, 1);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-leave.ng-leave-active {
  transform: scale(0);
  opacity: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .icon-connected.ng-enter.ng-enter-active {
  transform: scale(1);
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .publish-header-connected {
  margin: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header {
  font-size: 0;
  display: inline-block;
  position: absolute;
  right: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header .db-link {
  padding: 0;
  margin-left: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list-header .buttons-header .white-rounded {
  margin-left: 16px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .fb-pages-header {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  color: #767676;
  margin: 0 24px 8px 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list {
  padding: 0;
  overflow: auto;
  max-height: 400px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item {
  border-top: 1px solid var(--greyLight20);
  color: var(--black);
  position: relative;
  height: 72px;
  padding: 0;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .status-block {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  color: var(--green);
  text-transform: uppercase;
  float: right;
  margin: 24px 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .status-block .icon-connected,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .status-block .icon-connecting {
  vertical-align: bottom;
  margin-right: 6px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .status-block.connecting {
  margin-right: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .button.white {
  float: right;
  margin: 18px 24px 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .connected-to {
  display: none;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .page-item-container {
  cursor: pointer;
  line-height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .page-item-container .page-item-text {
  display: inline-block;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  line-height: 1.3;
  width: 50%;
  margin: 0 0 3px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .page-item-container:hover .page-item-text,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item .page-item-container:hover .page-item-image {
  color: #448af1;
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled {
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled:not(.button) {
  opacity: 0.5 !important;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .page-item-text,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .page-item-image {
  opacity: 0.6;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to {
  display: inline-block;
  float: right;
  margin-right: 24px;
  line-height: 72px;
  margin-bottom: -10px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to .connected-to-text {
  opacity: 0.6;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to .connected-to-text.red-text {
  color: #e9604a;
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to .connected-to-bot {
  opacity: 1;
  cursor: pointer;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to .connected-to-bot > a {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  color: #448af1;
  text-decoration: none;
  display: inline-block;
  display: -webkit-inline-box;
  max-width: 250px;
  vertical-align: middle;
  padding: 0 0 0 48px;
  margin: 0 0 4px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  max-height: 64px;
  overflow: hidden;
  -webkit-line-clamp: 3;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.show_connected_to_disabled .connected-to .connected-to-bot > a:before {
  content: '';
  background: url('../images/ic_chatbot.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.no-perm {
  height: 82px;
  padding: 15px 0 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.no-perm::before {
  content: attr(perm-error);
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #e9604a;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(233, 96, 74, 0.1);
  padding: 0 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.no-perm .button.connect {
  pointer-events: none;
  opacity: 0.3;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.no-perm .page-item-text {
  color: #e9604a !important;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-move,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-enter,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-leave {
  transition: height 0.3s cubic-bezier(0.1, 0.5, 0.5, 1), opacity 80ms cubic-bezier(0.1, 0.5, 0.5, 1);
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-leave.ng-leave-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-move,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-enter {
  opacity: 0;
  height: 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-leave,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-move.ng-move-active,
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item.ng-enter.ng-enter-active {
  opacity: 1;
  height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .page-item-image {
  background-size: 40px 40px;
  background-position: 0 center;
  background-repeat: no-repeat;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin: -3px 16px 0 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .add a {
  position: relative;
  display: inline-block;
  color: #448af1;
  text-decoration: none;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .add a::before {
  position: absolute;
  content: ' ';
  width: 16px;
  height: 16px;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background: url('../images/ic_add_small.svg') center center no-repeat;
  background-size: 16px auto;
  opacity: 0.3;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list .add a:hover::before {
  opacity: 1;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list.test-test {
  max-height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-pages-list .pages-list.test-test-test {
  max-height: 400px;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-header {
  padding: 20px 24px;
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  display: block;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container {
  padding: 24px;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col2,
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col3 {
  display: inline-block;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item-header {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  margin-top: 18px;
  text-transform: uppercase;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item a {
  text-decoration: none;
  color: #448af1;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item.status a {
  font-size: 12px;
  line-height: 2.33;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item.status.publish a {
  color: var(--green);
  pointer-events: none;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .app-item.status.publish a:before {
  background: url('../images/ic_ok.svg') no-repeat center center;
  width: 24px;
  height: 17px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1 {
  margin-right: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1 .page-item-container {
  cursor: pointer;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1 .page-item-container .page-item-image {
  background-size: 40px 40px;
  background-position: 0 center;
  background-repeat: no-repeat;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin: -6px 0 0 0;
  cursor: pointer;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1 .page-item-container .page-item-text {
  padding-left: 16px;
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col1 .page-item-container:hover .page-item-text {
  color: #448af1;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .col2 {
  margin-right: 120px;
}
section.settings .rcol .border-box.connect-to-page .state.state-app-connected .app-connected-container .button-box {
  float: right;
  margin-top: 44px;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .user-header {
  padding: 20px 24px;
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  display: block;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container {
  font-size: 0;
  height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .app-item {
  display: inline-block;
  font-size: 16px;
  margin-left: 24px;
  line-height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .app-item .page-item-container {
  cursor: pointer;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .app-item .page-item-container .page-item-image {
  background-size: 40px 40px;
  background-position: 0 center;
  background-repeat: no-repeat;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  margin-top: -3px;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .app-item .page-item-container .page-item-text {
  padding-left: 16px;
  color: var(--black);
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .app-item .page-item-container:hover .page-item-text {
  color: #448af1;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .user-connected-by {
  display: inline-block;
  float: right;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  color: var(--green);
  margin: 22px 0;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .user-connected-by .user-picture {
  display: inline-block;
  vertical-align: middle;
  background: center center no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: -3px 12px 0 15px;
  border-radius: 50%;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .user-connected-by .user-name {
  font-size: 16px;
  line-height: 1.75;
  color: var(--black);
  margin-right: 32px;
  text-transform: initial;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .user-connected-by .icon-connected {
  background: url('../images/ic_ok.svg') no-repeat center center;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 6px;
  background-size: 24px;
  vertical-align: bottom;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .button-box {
  display: inline-block;
  float: right;
  margin-right: 24px;
  line-height: 72px;
}
section.settings .rcol .border-box.connect-to-page .state.state-other-user-connected .other-user-container .button-box .button {
  vertical-align: middle;
}
section.settings .rcol .border-box.timezone {
  width: 100%;
  min-height: inherit;
  min-width: unset;
}
section.settings .rcol .border-box.timezone p {
  margin-bottom: 12px;
}
section.settings .rcol .border-box.timezone select {
  display: block;
  width: 100%;
  height: 36px;
  line-height: 34px;
  border-radius: 7px;
  border: solid 1px #d9d9d9;
  text-align: left;
  text-align-last: left;
  font-size: 16px;
  color: var(--black);
  padding: 0 12px;
}
section.settings .rcol .border-box.timezone select.no-timezone {
  color: var(--grey);
}
section.settings .rcol .border-box.timezone select option {
  -webkit-appearance: none;
  appearance: none;
  text-align: left;
  text-align-last: left;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
}
section.settings .rcol .border-box.persistent-menu {
  width: 100%;
  min-width: unset;
}
section.settings .rcol .border-box.persistent-menu p {
  margin: 4px 0 24px;
}
section.settings .rcol .border-box.persistent-menu .button.add {
  display: block;
  width: auto;
  margin: 0 0 24px;
  color: var(--black);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border-top-color: rgba(0, 0, 0, 0.09);
  border-left-color: rgba(0, 0, 0, 0.09);
  border-right-color: rgba(0, 0, 0, 0.09);
  border-bottom-color: rgba(0, 0, 0, 0.13);
  transition: box-shadow 80ms ease, border-top-color 80ms ease, border-right-color 80ms ease, border-bottom-color 80ms ease, border-left-color 80ms ease, background-color 80ms ease;
}
section.settings .rcol .border-box.persistent-menu .button.add:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
  border-top-color: rgba(0, 0, 0, 0.13);
  border-left-color: rgba(0, 0, 0, 0.13);
  border-right-color: rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
}
section.settings .rcol .border-box.persistent-menu .button.add:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border-top-color: rgba(0, 0, 0, 0.13);
  border-left-color: rgba(0, 0, 0, 0.13);
  border-right-color: rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
section.settings .rcol .border-box.persistent-menu .button.add.disabled {
  opacity: 0.5 !important;
}
section.settings .rcol .border-box.persistent-menu .items-list {
  position: relative;
}
section.settings .rcol .border-box.persistent-menu .sub {
  margin: 24px 0 0;
  font-size: 14px;
  color: #666666;
}
section.settings .rcol {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  min-width: 972px;
  flex-wrap: wrap;
}
section.settings .rcol > div {
  display: inline-block;
  vertical-align: top;
}
section.settings .rcol > div:first-child {
  width: 100%;
}
section.settings .rcol > div.col {
  width: 496px;
}
section.settings .rcol .settings-narrow-columns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
section.settings .rcol .timezone-box {
  position: relative;
  bottom: 0;
  width: 100%;
}
section.settings .rcol.bottom-align-left .col:nth-child(2) {
  padding-bottom: 240px;
}
section.settings .rcol.bottom-align-left .timezone-box {
  left: 32px;
}
