.suggest .host {
  margin: 0;
}
.suggest .host .tags {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: none;
  padding: 0px 3px 2px;
  border-radius: 3px;
  white-space: nowrap;
  line-height: 0;
}
.suggest .host .tags .tag-list {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  max-width: 79%;
}
.suggest .host .tags .tag-list .tag-item {
  font: 12px/25px var(--font);
  float: none;
  position: relative;
  border: none;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05) none repeat scroll 0 0 !important;
  margin: 0 6px 0 0;
  max-width: 220px;
}
.suggest .host .tags .tag-list .tag-item span {
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.suggest .host .tags .tag-list .tag-item .remove-button {
  opacity: 0.3;
  padding: 0 0 1px;
  font-size: 16px;
}
.suggest .host .tags .tag-list .tag-item .remove-button:hover {
  opacity: 1;
}
.suggest .host .tags .tag-list .tag-item::after {
  bottom: 15px;
  content: ', ';
  height: 5px;
  position: absolute;
  right: -5px;
  top: 0;
  width: 5px;
}
.suggest .host .tags .tag-list .tag-item:last-child::after {
  content: '';
}
.suggest .host .tags .input {
  float: none;
  vertical-align: middle;
  min-width: 154px;
  font: 12px/25px var(--font);
}
.suggest .host .autocomplete {
  padding: 0;
  width: 233px;
  right: 0;
  margin: 0;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  text-align: left;
}
.suggest .host .autocomplete .suggestion-list {
  margin: 0;
  padding: 0 0 13px;
  max-height: 219px;
  overflow-y: auto;
  position: relative;
  width: auto;
  overflow-x: hidden;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0);
}
.suggest .host .autocomplete .suggestion-list .suggestion-item {
  padding: 5px 10px 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 14px/1.5;
  color: var(--black);
  min-width: 162px;
}
.suggest .host .autocomplete .suggestion-list .suggestion-item em {
  background-color: transparent !important;
  color: var(--black) !important;
  font: 14px/1.5 var(--font) !important;
}
.suggest .host .autocomplete .suggestion-list .suggestion-item.selected {
  background-color: var(--greyLight20);
  color: var(--black);
}
.suggest.vertical .host .tags {
  min-height: 32px;
}
.suggest.vertical .host .tags .tag-list {
  display: inline;
  width: 100%;
  min-width: 100%;
}
.suggest.vertical .host .tags .tag-list .tag-item {
  white-space: nowrap;
  margin: 2px 6px 0 0;
}
.suggest.vertical .host .tags .input {
  margin-bottom: -4px;
  width: 100px;
  min-width: inherit;
}
.suggest.vertical .host .tags.focused {
  white-space: normal;
}
.suggest.vertical .host .tags.focused .tag-list {
  white-space: normal !important;
  width: 100%;
  min-width: 100%;
  display: block;
}
.suggest.vertical .host .tags.focused .tag-list .tag-item {
  white-space: nowrap !important;
  max-width: 100%;
}
.suggest.vertical .host .tags.focused .input {
  width: 100% !important;
}
.suggest.vertical .autocomplete {
  width: 100%;
  max-height: 206px;
}
