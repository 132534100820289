/**
 * store standard css properties but in mixins to prefix different browser compatibility
 */
segments-list {
  margin-left: 12px;
}
segments-list .segment-item_container {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
}
segments-list .icon-button_position {
  position: absolute;
  top: -6px;
  right: -6px;
}
segments-list icon {
  opacity: 0.3;
}
segments-list icon-button {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}
segments-list icon-button:hover > icon {
  opacity: 1;
}
segments-list .segment_button_selected {
  box-shadow: 0 0 0 2px rgba(5, 55, 72, 0.4) !important;
}
