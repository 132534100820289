.event-trigger-plugin-content_zapier-token {
  display: flex;
  align-items: flex-start;
  width: 623px;
  height: 81px;
  background: url('../images/token_bg.png') no-repeat left top;
  background-size: contain;
  font-size: 14px;
  line-height: 16px;
  padding: 36px 0 0 12px;
  white-space: nowrap;
}
.event-trigger-plugin-content_zapier-token-title {
  max-width: 382px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #5f6c72;
  margin: 0 20px 0 0;
}
.event-trigger-plugin-content_zapier-token-value {
  font-weight: 600;
  color: #aeb7bc;
  margin: 0 20px 0 0;
}
