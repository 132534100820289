section.users-tab {
  padding-left: 0;
  background-color: var(--greyLight20);
  min-height: 100%;
  -webkit-user-select: none;
  user-select: none;
}
section.users-tab .pt_content {
  display: inline-block;
}
@media (max-width: 1024px) {
  section.users-tab {
    padding-left: 0;
  }
}
section.users-tab .button-container_inside_spacing > *:not(:first-child) {
  margin-left: 8px;
}
section.users-tab .segment-container_spacer {
  margin-top: 26px;
}
section.users-tab .segment-container-buttons_container {
  display: inline-block;
  position: relative;
}
section.users-tab .adaptive-width-container {
  width: calc(100vw - 247px);
  min-width: 1111px;
}
section.users-tab .segment-action-buttons-container {
  position: relative;
}
section.users-tab .pt-upper-service-message {
  padding-top: 26px;
}
section.users-tab .pt-goto-pro-message {
  margin-top: 16px;
  text-align: center;
  height: 70px;
}
section.users-tab .pt-goto-pro-link {
  color: var(--blue);
  cursor: pointer;
  text-decoration: none;
}
section.users-tab .pt-usertable-container {
  position: relative;
  margin-bottom: 32px;
}
section.users-tab .pt-usertable-gradient-cover {
  background-image: linear-gradient(to top, var(--greyLight20), rgba(255, 255, 255, 0));
  height: 130px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-width: 1111px;
  pointer-events: none;
}
section.users-tab .children_positioning_spacebetween {
  display: flex;
  justify-content: space-between;
}
section.users-tab .segments-controls-container_display_property {
  display: flex;
  align-items: center;
}
section.users-tab .segments-controls-container_display_property > *:not(:first-child) {
  margin: 0 8px;
}
section.users-tab .error-alert .modal-alert-spacing {
  padding: 24px 40px;
}
section.users-tab .error-alert .modal-actions-spacing {
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;
}
section.users-tab .box {
  position: static;
  padding: 0 32px;
  margin: 0;
  max-width: unset;
}
section.users-tab .box .content .users-title {
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  color: var(--black);
}
section.users-tab .box .content .users-title-disabled-color {
  color: var(--grey);
}
section.users-tab .box .content .users-not-pro-text {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin: 16px 0 24px;
}
section.users-tab .box .content .users-not-pro-text .upgrade-link {
  color: var(--blue);
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  padding: 0;
}
section.users-tab .box .content .users-filter-buttons {
  position: relative;
  margin: 0 0 16px;
  min-width: 1111px;
}
section.users-tab .box .content .users-filter-buttons > * {
  margin: 0 4px 0 0;
}
section.users-tab .box .content .users-filter-buttons .button-ver2 {
  display: inline-block;
  vertical-align: top;
}
section.users-tab .box .content .users-filter-buttons .button-ver2.white-rounded {
  width: 120px;
  height: 36px;
  line-height: 34px;
  padding: 0;
  font-size: 16px;
  text-transform: none;
}
section.users-tab .box .content .users-filter-buttons.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 32px 0;
  height: 100px;
  margin: 0 0 64px 0;
}
section.users-tab .box .content .users-filter-buttons.bottom .button-ver2:not(.more) {
  background-color: var(--greyLight20);
}
section.users-tab .box .content .users-filter-buttons.bottom .more {
  position: absolute;
  top: 32px;
  left: 50%;
  width: 160px;
  margin-left: -80px;
}
section.users-tab .box .content .load-more {
  margin: 0 auto;
  padding: 30px 0 50px;
  width: 110px;
  cursor: pointer;
  color: #448af1;
}
section.users-tab .box .content .load-more:hover {
  text-decoration: underline;
}
section.users-tab .box .content .placeholder-loader {
  height: 150px;
  line-height: 150px;
  text-align: center;
  background: url('../images/spinner_b.svg') no-repeat center center;
  background-size: 32px 32px;
}
section.users-tab .box .content .placeholder-empty {
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #767676;
  white-space: nowrap;
  font-weight: bold;
}
section.users-tab .box .video-box {
  padding: 42px 0 0;
}
section.users-tab .box .video-box .video-for-people-tab {
  margin: 0 0 24px;
}
section.users-tab .box .inbox-button_layout {
  margin: 0 auto;
}
info-bar {
  height: 40px;
  opacity: 0.96;
  border-radius: 12px;
  background-color: var(--greyLight20);
  position: fixed;
  bottom: 20px;
  padding: 6px 12px;
  margin: 0 16px;
  width: calc(100% - 250px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 900px;
}
.info-sign-position {
  position: relative;
  top: 8px;
}
