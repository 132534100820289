.support {
  position: fixed;
  right: 32px;
  bottom: 32px;
  overflow: hidden;
  width: 56px;
  height: 56px;
  line-height: 54px;
  background-color: var(--white);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 28px;
  z-index: 3;
  transition: width 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  -webkit-transition: width 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
.support > * {
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  min-width: initial;
  width: auto;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  -webkit-transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
.support > span {
  font-size: 14px;
  color: #9b9b9b;
  padding: 0 10px 0 20px;
}
.support > div {
  height: 33px;
  line-height: 31px;
}
.support::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 56px;
  height: 56px;
  cursor: pointer;
  background: url('../images/ic_bubble_help.svg') no-repeat center center;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
  -webkit-transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
.support.act {
  width: 245px;
}
.support.act::before {
  opacity: 0;
  top: 1000px;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
  -webkit-transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
}
.support.act > * {
  opacity: 1;
}
.bot-create + .support {
  bottom: 96px;
}
