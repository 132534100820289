div.broadcast-null {
  height: 100%;
  max-width: 960px;
}
div.broadcast-null > div.pop {
  position: absolute;
  width: 296px;
  border-radius: 3px;
  background-color: #69a1f3;
  font-size: 14px;
  line-height: 1.4;
  color: var(--white);
  padding: 4px 8px;
  z-index: 1;
}
div.broadcast-null > div.pop::before {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 50%;
  left: -7px;
  transform: rotate(45deg) translateY(-50%);
  background-color: #69a1f3;
}
div.broadcast-null .null-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.broadcast-null .null-container .null-image {
  display: block;
}
div.broadcast-null .null-container .null-text {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #a5a5a5;
  width: 395px;
  margin-top: 20px;
}
broadcast-header {
  width: 100%;
}
