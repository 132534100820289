users-table-attr-popup .user-attributes-popup {
  position: absolute;
  top: 270px;
  left: 430px;
  width: 320px;
  height: auto;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
  padding: 18px 0 0;
  z-index: 10000;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
}
users-table-attr-popup .user-attributes-popup.show {
  opacity: 1;
  pointer-events: auto;
}
users-table-attr-popup .user-attributes-popup .user-attributes-popup-title {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--grey);
  padding: 0 24px 10px;
}
users-table-attr-popup .user-attributes-popup .attributes-list {
  max-height: 243px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 24px 18px;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute {
  display: flex;
  padding: 0 0 4px;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute > div {
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  border-radius: 12px;
  padding: 0 12px;
  white-space: nowrap;
  min-width: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute > div.name {
  min-width: 100px;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute > div.value {
  margin: 0 0 0 -6px;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute.user .name {
  color: var(--white);
  background-color: #906d60;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute.user .value {
  color: var(--white);
  background-color: #f4ae63;
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute.system .name {
  color: var(--black);
  background-color: var(--greyLight20);
}
users-table-attr-popup .user-attributes-popup .attributes-list .attribute.system .value {
  color: var(--black);
  background-color: var(--white);
  border: solid 1px var(--greyLight20);
  line-height: 26px;
}
