.row .user-filter .sequence-user-filter-item .filter-item {
  white-space: nowrap;
  margin: 0 0 16px;
  position: relative;
  font-size: 0;
}
.row .user-filter .sequence-user-filter-item .filter-item .trash-popup {
  right: -38px;
  top: 4px;
  transform: scale(0.5, 0.5);
  left: auto;
}
.row .user-filter .sequence-user-filter-item .filter-item .trash-popup::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 8px;
  left: -8px;
  top: 0;
}
.row .user-filter .sequence-user-filter-item .filter-item:hover > .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown {
  width: 66px;
  margin: 0 8px 0 0;
  text-align: left;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown .selected {
  font-size: 14px;
  padding: 0 22px 0 8px;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown ul {
  top: 0;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown ul li {
  font-size: 11px;
  padding: 0 8px 0 8px;
  height: 27px;
  line-height: 25px;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown ul li:first-child {
  height: 36px;
  line-height: 34px;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown.open ul {
  top: 0;
  z-index: 6;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown.open:after {
  z-index: 7;
}
.row .user-filter .sequence-user-filter-item .filter-item .art-big-dropdown.is {
  width: 84px;
  margin: 0 16px;
  text-align: left;
  min-width: auto;
}
.row .user-filter .sequence-user-filter-item .filter-item .attr-selector.cf-input {
  background-color: #fdfdfd !important;
}
.row .user-filter .sequence-user-filter-item .filter-item .attr-selector.cf-input.error {
  background-color: #fdf9f9 !important;
}
.row .user-filter .sequence-user-filter-item .filter-item .values {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 231px;
  height: 36px;
  line-height: 1;
  font-size: 16px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 36px;
  min-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fdfdfd !important;
  padding: 4px 3px 0 3px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list.focus .value-item:nth-last-of-type(2)::after {
  content: ', ' !important;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item {
  display: inline-block;
  position: relative;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  background-color: #f4ae63;
  font-size: 16px;
  color: var(--white);
  -webkit-user-select: none;
  user-select: none;
  margin: 0 8px 4px 0;
  max-width: 98%;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item span {
  display: block;
  padding: 0 12px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item::after {
  content: ', ';
  position: absolute;
  right: -5px;
  top: 0;
  color: var(--black);
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item:nth-last-of-type(2)::after {
  content: '';
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item.noresponse {
  background-color: #c3c3c3;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item .mini {
  right: -8px !important;
  top: -5px;
  z-index: 10;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .value-item:hover .mini {
  transform: scale(1);
  opacity: 1;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup {
  position: absolute;
  border-radius: 7px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.13);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 10000;
  padding: 0;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 29px 0 0 -4px;
  max-height: 258px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-width: unset;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li {
  display: flex;
  height: 48px;
  line-height: 48px;
  margin: 0;
  cursor: pointer;
  background-color: var(--white);
  order: 2;
  justify-content: space-between;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li:last-child {
  border-radius: 0 0 3px 0;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li.act {
  background-color: #f3f3f3;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li > span {
  display: flex;
  padding: 0 5px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li > span.name span {
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #f4ae63;
  padding: 0 12px;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  max-width: 161px;
  overflow: hidden;
  margin-top: 11px;
  text-overflow: ellipsis;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li > span.name.noresponse span {
  background-color: #c3c3c3;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li > span.stat {
  color: #b5b5b5;
  font-size: 14px;
  padding: 0 12px 0 0;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .valuePopup ul li.firstValue {
  order: 1;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .input {
  position: relative;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  border: none;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  -webkit-user-select: auto;
  user-select: all;
  margin: 0 8px 2px 0;
  max-width: 218px;
  min-width: 10px;
  padding: 0 0 0 3px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list .popup-box .input:not(:empty) {
  background-color: #f4ae63;
  color: var(--white);
  padding: 0 12px;
  border-radius: 13px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values .values-list.simple-input {
  padding: 0 6px;
}
.row .user-filter .sequence-user-filter-item .filter-item .values.act .values-list {
  height: auto;
  overflow: visible;
  white-space: normal;
  z-index: 100;
}
