/*112px - left/right padding (112*2 = 224px)*/
comment-plugin {
  display: block;
  max-width: 680px;
  margin: -8px 0 0;
  position: relative;
}
comment-plugin .font-style-italic {
  font-style: italic;
}
comment-plugin text-field a {
  cursor: pointer;
}
