section.settings > div .border-box.persistent-menu {
  overflow: hidden;
  position: relative;
}
section.settings > div .border-box.persistent-menu .persistent-menu-anchor {
  position: absolute;
  top: -32px;
}
section.settings > div .border-box.persistent-menu h2 {
  position: absolute;
  height: 36px;
  top: 24px;
  left: 24px;
}
section.settings > div .border-box.persistent-menu .controls {
  font-size: 14px;
  line-height: 2;
  color: var(--black);
  margin: 0 0 32px;
}
section.settings > div .border-box.persistent-menu .controls > * {
  display: inline-block;
  vertical-align: middle;
}
section.settings > div .border-box.persistent-menu .controls > *.art-switcher {
  margin: 0 4px 0 12px;
}
section.settings > div .border-box.persistent-menu .controls > *.help-mini {
  margin: 0 28px 0 0;
}
section.settings > div .border-box.persistent-menu .controls > *.art-big-dropdown {
  width: 150px;
  vertical-align: -2px;
  margin: -10px 0 -10px 12px;
  font-size: 32px;
  height: 36px;
  line-height: 34px;
}
section.settings > div .border-box.persistent-menu .controls > *.art-big-dropdown ul {
  overflow: auto;
}
section.settings > div .border-box.persistent-menu .controls > *.art-big-dropdown ul li {
  display: block;
}
section.settings > div .border-box.persistent-menu p {
  margin: 0 0 20px;
}
section.settings > div .border-box.persistent-menu p a {
  text-decoration: none;
  color: #448af1;
}
section.settings > div .border-box.persistent-menu p a:hover {
  text-decoration: underline;
}
.tooltip-persistent {
  width: auto;
}
.tooltip-persistent .tooltip-inner {
  background-color: #053748;
  max-width: 420px;
  height: auto;
  text-align: left;
  width: unset;
  margin: 0;
  font-size: 13px;
  line-height: 1.75;
  padding: 8px 14px;
}
.tooltip-persistent .tooltip-arrow {
  border-right-color: #053748 !important;
  min-width: 0;
  max-width: unset;
  margin: 0;
}
