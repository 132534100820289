.action-item-v2 {
  position: relative;
  width: 254px;
  height: 65px;
  border-top: solid 1px #ebebeb;
  background-color: var(--white);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
.action-item-v2.animate-off {
  transition: none !important;
}
.action-item-v2.animate-off * {
  transition: none !important;
}
.action-item-v2.button-has-error {
  box-shadow: 0 0 0 1px #e9604a;
  transition: box-shadow 0ms 100ms;
  z-index: 2;
}
.action-item-v2.button-has-error .content-box {
  background-color: transparent !important;
}
.action-item-v2.button-has-error .content-box span.title {
  color: #e9604a !important;
}
.action-item-v2.button-has-error .content-box span.title::before {
  color: #e9604a !important;
}
.action-item-v2.button-has-error .content-box .buy-title-text {
  color: #e9604a !important;
}
.action-item-v2.button-has-error .content-box .coast {
  color: #e9604a !important;
}
.action-item-v2.button-has-error .content-box .coast * {
  color: #e9604a !important;
}
.action-item-v2 > .trash-popup {
  left: auto;
  right: -6px;
}
.action-item-v2:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.action-item-v2 .content-box {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 254px;
  max-height: 64px;
  z-index: 0;
  background-color: var(--white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0);
  border: solid 1px rgba(0, 0, 0, 0);
  border-radius: 6px;
  transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, top cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, max-height cubic-bezier(0.2, 0.7, 0.5, 1) 130ms, box-shadow cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, border-color cubic-bezier(0.2, 0.7, 0.5, 1) 100ms;
}
.action-item-v2 .content-box .sup-title {
  line-height: 28px;
  font-size: 16px;
  height: 0;
  overflow: hidden;
  color: var(--black);
  text-align: center;
  margin: 7px 0 -11px;
  transition: height cubic-bezier(0.2, 0.7, 0.5, 1) 100ms;
}
.action-item-v2 .content-box .button-title {
  font-size: 18px !important;
  line-height: 24px !important;
  text-align: start !important;
  padding: 17px 16px 0;
  height: 50px !important;
}
.action-item-v2 .content-box .title-box {
  padding: 18px 0 0;
  width: 100%;
  height: 64px;
  text-align: center;
}
.action-item-v2 .content-box .title-box .title-resize-box {
  overflow: hidden;
  margin: 0 0 -6px;
  line-height: 20px;
  text-align: center;
  padding: 0;
  display: inline-block;
}
.action-item-v2 .content-box .title-box .title-resize-box .title {
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #448af1;
}
.action-item-v2 .content-box .title-box .title-resize-box .title::before {
  -webkit-user-select: none;
  user-select: none;
}
.action-item-v2 .content-box .title-box .title-resize-box .title:empty:not(:focus)::before {
  color: #e9604a;
}
.action-item-v2 .content-box .title-box .title-resize-box .title br {
  content: '';
}
.action-item-v2 .content-box .title-box .title-resize-box .title br::after {
  content: ' ';
}
.action-item-v2 .content-box .title-box .title-resize-box.not-been-change {
  background-color: rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(0, 0, 0, 0.24) !important;
}
.action-item-v2 .content-box .title-box .title-resize-box.not-been-change .title::before {
  color: var(--grey) !important;
}
.action-item-v2 .content-box .title-box .title-resize-box.share-title-box {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
  width: 252px;
  padding: 0 10px;
  cursor: pointer;
  line-height: 24px;
  -webkit-user-select: none;
  user-select: none;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title {
  cursor: pointer;
  text-align: left;
  margin: -2px 0 0;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title .coast {
  display: inline-block;
  color: var(--black);
  font-size: 17px;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title .coast.error {
  color: #e9604a;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title .coast span {
  display: inline-block;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  width: 130px;
  overflow: hidden;
  vertical-align: bottom;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title .buy-title-text {
  float: right;
  text-align: right;
  color: #448af1;
}
.action-item-v2 .content-box .title-box .title-resize-box.buy-title-box .buy-title .buy-title-text .button-ver2 {
  display: inline-block;
  vertical-align: -6px;
}
.action-item-v2 .content-box .title-box .title-resize-box .discount {
  display: none;
}
.action-item-v2 .content-box .title-box .title-resize-box .share-title {
  color: #448af1;
  padding: 0 28px 0 0;
  margin: 0;
  font-size: 17px;
}
.action-item-v2 .content-box .title-box .title-resize-box .share-title .icon {
  display: inline-block;
  position: relative;
  vertical-align: -6px;
}
.action-item-v2 .content-box .title-box .title-resize-box .share-title .icon.ihide {
  opacity: 0;
  pointer-events: none;
}
.action-item-v2 .content-box .title-box .preview {
  font-size: 12px;
  line-height: 1.17;
  color: var(--grey);
  text-align: center;
}
.action-item-v2 .content-box .title-box .preview .blocks {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.action-item-v2 .content-box .title-box .preview .blocks::after {
  content: '';
  position: absolute;
  height: 14px;
  width: 14px;
  top: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.action-item-v2 .content-box .title-box .preview .blocks span {
  position: relative;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  color: #767676;
  white-space: nowrap;
  border-radius: 3px;
  background-color: var(--greyLight20);
  padding: 0 2px;
  margin: 0 7px 0 0;
}
.action-item-v2 .content-box .title-box .preview .blocks span::after {
  content: ',';
  position: absolute;
  top: 0;
  right: -5px;
  font-size: 12px;
  color: #767676;
  width: 4px;
  text-align: right;
  height: 14px;
  line-height: 14px;
}
.action-item-v2 .content-box .title-box .preview .blocks span:last-child {
  margin: 0;
}
.action-item-v2 .content-box .title-box .preview .blocks span:last-child::after {
  display: none;
}
.action-item-v2 .content-box .title-box .preview .url {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  white-space: nowrap;
}
.action-item-v2 .content-box .title-box .preview .url span {
  display: inline-block;
  height: 13px;
  border-radius: 10px;
  background-color: #906d60;
  padding: 0 15px;
  font-size: 11px;
  line-height: 12px;
  color: var(--white);
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  vertical-align: middle;
}
.action-item-v2 .content-box .title-box .preview .url span::before,
.action-item-v2 .content-box .title-box .preview .url span::after {
  position: absolute;
  top: 0;
  font-size: 11px;
  color: #e0995d;
}
.action-item-v2 .content-box .title-box .preview .url span::before {
  left: 3px;
  content: '{{';
}
.action-item-v2 .content-box .title-box .preview .url span::after {
  right: 3px;
  content: '}}';
}
.action-item-v2 .content-box .title-box .preview .url span.system {
  background-color: #9e80bd;
}
.action-item-v2 .content-box .title-box .preview .url span.system:before,
.action-item-v2 .content-box .title-box .preview .url span.system:after {
  color: #e0e0e0;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup {
  position: absolute;
  width: 365px;
  border-radius: 7px;
  background-color: #fefefe;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(0, 0, 0, 0.12);
  top: 2px;
  right: -375px;
  padding: 24px;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms, min-height 0ms 200ms;
  max-height: 0;
  -webkit-user-select: none;
  user-select: none;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup.open {
  max-height: 1000px;
  transition: opacity 200ms, min-height 0ms 0ms;
  opacity: 1;
  pointer-events: all;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup::before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #fefefe;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  transform: rotate(-45deg);
  left: -5px;
  top: 21px;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup .close-small {
  position: absolute;
  top: 3px;
  right: 3px;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: #3c3f40;
  margin: 0;
  padding: 0 0 24px;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul {
  padding: 0;
  margin: 0;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li {
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #3c3f40;
  padding: 0 0 0 30px;
  margin: 0 0 16px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: left 3px;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li:last-child {
  margin: 0;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li span {
  display: block;
  white-space: nowrap;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li span:first-child {
  font-weight: bold;
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li:nth-child(1) {
  background-image: url('../images/emoji/crown.png');
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li:nth-child(2) {
  background-image: url('../images/emoji/usa.png');
}
.action-item-v2 .content-box .title-box .payment-type-info-popup ul li:nth-child(3) {
  background-image: url('../images/emoji/earth.png');
}
.action-item-v2 .content-box .title-box .earth {
  background-image: url('../images/globe.svg');
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 21px;
  right: 21px;
  opacity: 0 !important;
  transition: opacity 10ms 0ms;
  pointer-events: none;
}
.action-item-v2 .content-box .title-box .earth:hover + .payment-type-info-popup {
  max-height: 1000px;
  transition: opacity 200ms, min-height 0ms 0ms;
  opacity: 1;
  pointer-events: all;
}
.action-item-v2 .content-box .hide-box {
  width: 400px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 30ms 0ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 50ms 0ms;
}
.action-item-v2 .content-box .hide-box .state-selector {
  padding: 0;
  margin: 0 0 10px;
  display: block;
}
.action-item-v2 .content-box .hide-box .state-selector li {
  display: inline-block;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black);
  transition: color 100ms, border-bottom-color 100ms;
  margin: 0 12px 0 0;
  padding: 0 0 0 20px;
}
.action-item-v2 .content-box .hide-box .state-selector li:last-child {
  margin: 0;
}
.action-item-v2 .content-box .hide-box .state-selector li::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  opacity: 0.3;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-position: left center;
  background-repeat: no-repeat;
}
.action-item-v2 .content-box .hide-box .state-selector li.blocks::before {
  background-image: url('../images/action-icons/ic_blocks_small.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li.url::before {
  background-image: url('../images/action-icons/ic_link_small.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li.phone-call::before {
  background-image: url('../images/action-icons/ic_call_small.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li.share::before {
  background-image: url('../images/action-icons/ic_share_small.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li.buy::before {
  background-image: url('../images/action-icons/ic_buy_small.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li:hover,
.action-item-v2 .content-box .hide-box .state-selector li.act {
  color: #e9604a;
}
.action-item-v2 .content-box .hide-box .state-selector li:hover::before,
.action-item-v2 .content-box .hide-box .state-selector li.act::before {
  opacity: 1;
}
.action-item-v2 .content-box .hide-box .state-selector li:hover.blocks::before,
.action-item-v2 .content-box .hide-box .state-selector li.act.blocks::before {
  background-image: url('../images/action-icons/ic_blocks_small_active.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li:hover.url::before,
.action-item-v2 .content-box .hide-box .state-selector li.act.url::before {
  background-image: url('../images/action-icons/ic_link_small_active.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li:hover.phone-call::before,
.action-item-v2 .content-box .hide-box .state-selector li.act.phone-call::before {
  background-image: url('../images/action-icons/ic_call_small_active.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li:hover.share::before,
.action-item-v2 .content-box .hide-box .state-selector li.act.share::before {
  background-image: url('../images/action-icons/ic_share_small_active.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li:hover.buy::before,
.action-item-v2 .content-box .hide-box .state-selector li.act.buy::before {
  background-image: url('../images/action-icons/ic_buy_small_active.svg');
}
.action-item-v2 .content-box .hide-box .state-selector li.act {
  border-bottom: 2px solid #e9604a;
}
.action-item-v2 .content-box .hide-box .states-box > div.url .webview-h-sel-box {
  padding: 4px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-item-v2 .content-box .hide-box .states-box > div > p {
  margin: 0;
  padding: 6px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #767676;
}
.action-item-v2 .content-box .hide-box .states-box > div .input-box {
  height: 36px;
  border-radius: 7px;
}
.action-item-v2 .content-box .hide-box .states-box > div .input-box .input {
  height: 34px;
  line-height: 34px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 0 16px;
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand,
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus {
  border-radius: 7px;
  background-color: #fafafa;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  color: var(--black);
  max-height: 72px;
  word-wrap: break-word;
  word-break: break-all;
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand::before,
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus::before {
  color: var(--grey);
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand br,
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus br {
  content: '';
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand br::after,
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus br::after {
  content: ' ';
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand div,
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus div {
  display: inline;
}
.action-item-v2 .content-box .hide-box .states-box > div .input-url-box .art-textarea-expand.error:not(:focus)::before {
  transition: color 60ms;
  color: #e9604a;
}
.action-item-v2 .content-box .hide-box .states-box > div .blocks-title {
  line-height: 28px;
  font-size: 16px;
  color: var(--black);
  margin: -7px 0 1px;
}
.action-item-v2 .content-box .hide-box .buttons {
  text-align: right;
}
.action-item-v2 .content-box .hide-box .buttons .button-ver2 {
  display: inline-block;
  vertical-align: top;
  height: 36px;
  width: 104px;
  line-height: 34px;
  margin: 0 0 0 12px;
}
.action-item-v2 .content-box .hide-box .buttons .button-ver2.orange-rounded {
  line-height: 36px;
}
.action-item-v2:not(.expand) .content-box .title-box .title-resize-box {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
  border-radius: 0 !important;
}
.action-item-v2.has-target .content-box .title-box {
  padding: 6px 5px;
}
.action-item-v2.expand {
  cursor: auto;
  z-index: auto;
}
.action-item-v2.expand .content-box {
  top: -26px;
  left: 180px;
  width: 402px;
  max-height: 1400px;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px rgba(0, 0, 0, 0.09);
  z-index: var(--zindex-popover);
}
.action-item-v2.expand .content-box .sup-title {
  height: 28px;
}
.action-item-v2.expand .content-box .title-box {
  padding: 12px 0 0;
  height: 60px;
}
.action-item-v2.expand .content-box .title-box .earth {
  transition: opacity 50ms 0ms, box-shadow 100ms;
  opacity: 1 !important;
  pointer-events: all;
}
.action-item-v2.expand .content-box .title-box .title-resize-box {
  position: relative;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  cursor: text;
  line-height: 34px;
  border-radius: 7px;
  width: 236px;
}
.action-item-v2.expand .content-box .title-box .title-resize-box.buy-title-box {
  width: 252px;
  line-height: 34px;
  cursor: default;
}
.action-item-v2.expand .content-box .title-box .title-resize-box.buy-title-box .buy-title {
  margin: 0;
  cursor: default;
}
.action-item-v2.expand .content-box .title-box .title-resize-box .title {
  display: inline-block;
  font-size: 17px;
  text-align: center;
  color: #448af1;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
.action-item-v2.expand .content-box .title-box .title-resize-box .discount {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 10px;
  color: var(--white);
  opacity: 0;
  transition: opacity 100ms;
  line-height: 14px;
  padding: 0 4px;
  height: 14px;
  border-radius: 8px;
  background-color: #5dc991;
}
.action-item-v2.expand .content-box .title-box .title-resize-box .title:focus + .discount {
  opacity: 1;
}
.action-item-v2.expand .content-box .title-box .preview {
  display: none;
}
.action-item-v2.expand .content-box .hide-box {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: opacity 50ms 50ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 50ms 50ms;
}
.action-item-v2:not(.expand):hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.button-tooltip {
  z-index: 100009;
  width: auto;
}
.button-tooltip .tooltip-inner {
  width: 247px;
  height: 52px;
  border-radius: 4px;
  background-color: #053748;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: var(--white);
  min-width: unset;
  text-align: left;
  padding: 8px 12px;
}
.button-tooltip .tooltip-arrow {
  border-top-color: #053748 !important;
  min-width: unset;
}
.button-tooltip-warring {
  z-index: 100009;
  width: auto;
  pointer-events: none;
}
.button-tooltip-warring .tooltip-inner {
  width: 240px;
  height: auto;
  border-radius: 4px;
  background-color: #053748;
  font-size: 14px;
  line-height: 1.29;
  color: var(--white);
  min-width: unset;
  text-align: left;
  padding: 8px 12px;
}
.button-tooltip-warring .tooltip-arrow {
  border-top-color: #053748 !important;
  min-width: unset;
}
.button-tooltip-error {
  z-index: var(--zindex-modal) !important;
  width: auto;
  transform: translate(-90px, 10px);
}
.button-tooltip-error .tooltip-inner {
  width: 247px;
  height: 52px;
  border-radius: 4px;
  background-color: #e9604a;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: var(--white);
  min-width: unset;
  text-align: left;
  padding: 8px 12px;
}
.button-tooltip-error .tooltip-arrow {
  border-top-color: #e9604a !important;
  min-width: unset;
}
