.menu-tree-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 424px;
  min-height: 71px;
  background-color: #fbfafa;
  border: solid 1px #e7e7e7;
  border-top: solid 2px #e7e7e7;
  padding: 0 0 0;
  margin: 0 32px 0 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms;
  box-sizing: content-box;
}
.menu-tree-item:first-child .sort-box > *:first-child {
  padding-top: 12px;
}
.menu-tree-item.act,
.menu-tree-item:first-child {
  opacity: 1;
  pointer-events: auto;
}
.menu-tree-item:last-child {
  margin: 0;
}
.menu-tree-item::before {
  content: '';
  position: absolute;
  width: 44px;
  height: 6px;
  background-color: #e1e1e1;
  border-radius: 3px;
  left: 190px;
  top: 10px;
  z-index: 10;
}
.menu-tree-item::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  width: 100%;
  z-index: 20;
  background-color: #fbfafa;
  margin-top: -1px;
}
.menu-tree-item .menu-title {
  position: relative;
  height: 72px;
  padding: 12px 16px 0;
  line-height: 60px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: var(--black);
  box-sizing: border-box;
}
.menu-tree-item .menu-title::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  height: 1px;
  width: 100%;
  z-index: 10;
  background-color: #dbdbdb;
  margin-top: -1px;
}
.menu-tree-item .menu-title .back {
  width: 140px;
  height: 60px;
  position: absolute;
  bottom: 0;
  left: -16px;
  background-position: 30px center;
  background-repeat: no-repeat;
  background-image: url('../images/ic-arrow-left.svg');
}
.menu-tree-item .menu-title .back:hover {
  background-image: url('../images/ic-arrow-left-hover.svg');
}
.menu-tree-item .send-a-message {
  position: relative;
  height: 0;
  line-height: 60px;
  padding: 0 16px 0;
  box-sizing: border-box;
  font-size: 18px;
  color: #b5b4b4;
  transition: height 150ms cubic-bezier(0.22, 0.61, 0.36, 1), padding 150ms cubic-bezier(0.22, 0.61, 0.36, 1);
  pointer-events: none;
  overflow: hidden;
}
.menu-tree-item .send-a-message::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  height: 1px;
  width: 410px;
  z-index: 10;
  background-color: var(--greyLight20);
  margin-top: -1px;
}
.show-user-input .send-a-message {
  height: 72px;
  padding: 12px 16px 0;
}
.show-user-input .sort-box > div:first-child {
  padding-top: 0 !important;
}
