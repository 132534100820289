.menu-tree {
  position: relative;
}
.menu-tree .box {
  white-space: nowrap;
  transition: transform 250ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.menu-tree .box.sublayer-edit-mode {
  transform: translateX(-370px);
}
.menu-tree .box .placeholder {
  display: inline-block;
  vertical-align: top;
  transform: translateX(-928px);
  width: 426px;
  height: 255px;
  text-align: center;
  border-radius: 4px;
  background: url('../images/submenus.svg') no-repeat center 90px rgba(243, 242, 242, 0.22);
  border: dashed 1px rgba(166, 166, 166, 0.22);
  background-size: 320px auto;
  font-size: 18px;
  line-height: 1.56;
  color: rgba(53, 62, 65, 0.5);
  padding: 37px 0 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms;
}
.menu-tree .box .placeholder.show {
  opacity: 1;
}
.menu-tree > .back {
  position: absolute;
  width: 98px;
  height: 100%;
  border-radius: 7px;
  background-color: #fbfafa;
  left: -40px;
  top: 0;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  background-position: 33px center;
  background-repeat: no-repeat;
  background-image: url('../images/ic-arrow-left.svg');
  cursor: pointer;
  transition: opacity 150ms;
}
.menu-tree > .back:hover {
  background-image: url('../images/ic-arrow-left-hover.svg');
}
.menu-tree > .back::before {
  top: 50%;
  position: absolute;
  right: -48px;
  content: 'Back to the top level';
  transform: translateY(-50%) rotate(-90deg);
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #767676;
  white-space: nowrap;
  pointer-events: none;
}
.menu-tree > .back.act {
  opacity: 1;
  pointer-events: auto;
}
