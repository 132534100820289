div.structure-null {
  padding: 0;
  max-width: 960px;
}
div.structure-null h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  text-align: left;
  margin: 0 0 32px;
  padding: 0;
}
div.structure-null iframe {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0 0 120px;
}
.undeleteble-pro-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 253px;
  border-radius: 6px;
  border: solid 1px #e3e3e3;
  flex-direction: column;
  font-size: 16px;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  color: var(--greyDark);
}
.undeleteble-pro-block .text {
  margin: 12px;
}
.undeleteble-pro-block .pro-button {
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  color: var(--blue);
  border-top: 1px solid #e3e3e3;
}
.forbidden-group {
  height: 100%;
}
.forbidden-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.broadcasting-tags-sequence {
  margin-bottom: 40px;
}
.forbidden-image {
  background: url('../images/forbidden-group.svg') no-repeat center;
  content: '';
  width: 100%;
  height: 46px;
  margin-bottom: 28px;
}
.forbidden-text {
  text-align: center;
}
