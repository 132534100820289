@media (max-width: 1024px) {
  .grow-wrapper {
    padding-left: 72px !important;
  }
}
.grow-wrapper {
  background-color: var(--greyLight20);
  padding-left: 160px;
  min-height: 100%;
  display: table;
}
.grow {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  min-width: 972px;
  flex-wrap: wrap;
  width: 1088px;
}
.grow__text {
  font-size: 15px;
  line-height: 22px;
  color: #747474;
  margin: 0 0 24px;
  width: 100%;
}
.grow__link {
  color: #3672ee;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
}
.grow__link:hover {
  text-decoration: underline;
}
.grow-item {
  min-height: 134px;
  border-radius: 7px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.08);
  padding: 24px 24px 22px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.grow-item_no-flex {
  display: block;
}
.grow-item_blue-filled {
  background-image: radial-gradient(circle at 0 0, #448af1, #2c74de);
  color: var(--white);
}
.grow-item_fixed-height-184px {
  height: 184px;
}
.grow-item_fixed-height-272px {
  height: 272px;
}
.grow-item_padded {
  margin-bottom: 32px;
}
.grow-item_sticker-bot:after {
  content: '';
  width: 83px;
  height: 84px;
  position: absolute;
  right: 27px;
  top: 50%;
  margin-top: -41px;
  background-image: url('../../assets/images/bot-sticker.png');
  background-size: contain;
}
.grow-item_sticker-messenger:after {
  content: '';
  width: 69px;
  height: 77px;
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -45px;
  background: url('../../assets/images/messenger-sticker.png') no-repeat;
  background-size: 69px 77px;
}
.grow-item__title {
  padding: 0;
  text-align: left;
  display: block;
  font-size: 18px;
  line-height: 1.56;
  color: inherit;
  margin-bottom: 15px;
  font-weight: 300;
}
.grow-item__title_small {
  font-size: 12px;
  line-height: 2;
  color: #666666;
}
.grow-item__description {
  font-size: 14px;
  line-height: 1.57;
  color: var(--black);
  margin-bottom: 20px;
  font-weight: 300;
}
.grow-item__description.grow-item__description_fix-width {
  max-width: 350px;
}
.grow-item__description_without-indentation {
  margin-bottom: 0;
}
.grow-item__description_without-lineheight {
  line-height: 1.4;
}
.grow-item__button {
  border-radius: 7px !important;
  padding: 0 12px !important;
  height: 36px !important;
}
.grow-item__button_plus {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px !important;
}
.grow-item__button_plus:before {
  position: relative;
  display: inline-block;
  content: '';
  background: url(../images/ic-add-small.svg) no-repeat left top;
  width: 16px;
  height: 16px;
  opacity: 0.3;
  transition: opacity 100ms;
  margin-right: 8px;
}
.grow-item__button_plus:hover:before {
  opacity: 1;
}
.grow-item__button_refresh {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.grow-item__button_refreshing {
  pointer-events: none;
}
.grow-item__button_refreshing:before {
  content: '';
  width: 19px;
  height: 19px;
  background: url(../images/refresh-button-spinner.svg);
  display: inline-block;
  background-size: contain;
  margin-right: 6px;
}
.grow-item__button_refreshed:before {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url('../images/ic_done_green.svg');
  display: inline-block;
  margin-right: 4px;
}
.grow-item__button_refreshed {
  pointer-events: none;
}
.grow-item__button_copy {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0px 21px !important;
  margin-left: 18px !important;
  width: 104px;
}
.grow-item__button_copy:before {
  position: relative;
  display: inline-block;
  content: '';
  background: url(../images/ic_clipboard_small.svg) no-repeat left top;
  width: 14px;
  height: 13px;
  opacity: 0.3;
  transition: opacity 100ms;
  margin-right: 8px;
}
.grow-item__button_copy:hover:before {
  opacity: 1;
}
.grow-item__button_rounded {
  border-radius: 7px !important;
}
.grow-item__button_displaced {
  margin-left: -2px;
}
.grow-item__button_with-indent-right {
  margin-right: 20px;
}
.grow-item__note {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  display: inline-block;
  margin-left: 16px;
}
.grow-list {
  padding: 0 0 0 16px;
  margin: 0;
}
.grow-list__item {
  font-size: 14px;
  line-height: 1.78;
  color: #448af2;
  margin-bottom: 10px;
}
.grow-list__item:last-child {
  margin-bottom: 0;
}
.grow-list__content {
  color: var(--black);
  font-weight: 300;
}
.grow-rules {
  margin-top: 16px;
  width: 100%;
  margin-bottom: -24px;
}
.grow-rules__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 193px;
  overflow-y: auto;
}
.grow-rules__list_displaced {
  margin-left: -24px;
  width: 494px;
}
.grow-rules-item {
  height: 54px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-top: solid 1px var(--greyLight20);
  border-bottom: solid 1px var(--greyLight20);
  background-color: var(--white);
  margin-bottom: -1px;
}
.grow-rules-item__anchor {
  visibility: hidden;
  position: absolute;
  top: -15px;
}
.grow-rules-item:hover {
  background-color: var(--greyLight20);
}
.grow-rules-item:hover .grow-rules-item__drag-button,
.grow-rules-item:hover .grow-rules-item__copy-button,
.grow-rules-item:hover .grow-rules-item__delete-button {
  opacity: 0.3;
}
.grow-rules-item__title {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  -webkit-user-select: none;
  user-select: none;
}
.grow-rules-item__drag-button {
  width: 24px;
  height: 24px;
  background-image: url('../../../../assets2/images/ic_drag_ver.svg');
  flex-shrink: 0;
  opacity: 0;
}
.grow-rules-item__drag-button:hover {
  opacity: 1 !important;
}
.grow-rules-item__copy-button {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  opacity: 0;
  background: url('../../../../assets2/images/ic_duplicate_small.svg') no-repeat center;
  background-size: 16px;
}
.grow-rules-item__copy-button:hover {
  opacity: 1 !important;
}
.grow-rules-item__delete-button {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
  opacity: 0;
  background: url('../../../../assets2/images/ic_trash_midle.svg') no-repeat;
  background-size: 24px 24px;
  margin-right: 13px;
}
.grow-rules-item__delete-button:hover {
  opacity: 1 !important;
}
.grow-item-tooltip {
  width: 388px;
}
.grow-item-tooltip .tooltip-inner {
  padding-bottom: 151px;
  background: #053748 url('../images/grow-item-tooltip-bg.png') no-repeat center 75px;
  background-size: 364px 71px;
  padding-top: 7px;
  text-align: left;
  padding-left: 12px;
  line-height: 1.3;
}
.grow-item-tooltip .tooltip-arrow {
  border-top-color: #053748 !important;
  min-width: 0 !important;
}
.grow-item_actions_positioning {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
