.card-item {
  position: relative;
  margin: 0 0 40px;
}
.card-item .tooltip {
  z-index: var(--zindex-popover);
}
.card-item > div {
  display: inline-block;
  min-width: 100%;
}
.card-item.text > div,
.card-item.gallery > div,
.card-item.image > div {
  display: block;
}
.card-item .card {
  padding: 0;
  width: 254px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 3px 6px -1px rgba(0, 0, 0, 0.07);
  position: relative;
}
.card-item .card.card-error {
  box-shadow: 0 0 0 1px #e9604a, 0 3px 6px -1px rgba(0, 0, 0, 0.07);
}
.card-item .card.card-error .action-list .action-item {
  border-top: solid 1px #e9604a;
}
.card-item .card .content {
  padding: 3px 3px;
}
.card-item .card .content > div {
  position: relative;
}
.card-item .card .content textarea {
  display: block;
  width: 100%;
  padding: 3px 6px 0;
  background-color: #ffffff;
  border: solid 1px transparent;
  resize: none;
  margin: 0;
  overflow: hidden;
  white-space: inherit;
  border-radius: 7px;
}
.card-item .card .content textarea.error:not(:focus) {
  border: solid 1px #e9604a;
  padding: 3px 6px 0;
  color: #e9604a;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
}
.card-item .card .content textarea.error:not(:focus)::-webkit-input-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.error:not(:focus)::-moz-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.error:not(:focus):-moz-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.error:not(:focus):-ms-input-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.error:not(:focus):-o-input-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.error:not(:focus):input-placeholder {
  color: #e9604a;
}
.card-item .card .content textarea.email-textarea {
  border-radius: 7px;
  padding-left: 5px;
  padding-top: 4px;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  background-color: transparent;
  white-space: pre-wrap;
}
.card-item .card .content textarea:last-child {
  margin: 0;
}
.card-item .card .content textarea:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.33) !important;
}
.card-item .card .content .discount {
  position: absolute;
  right: 0;
  bottom: 1px;
  text-align: center;
  font-size: 11px;
  opacity: 0;
  transition: opacity 100ms;
  line-height: 14px;
  height: 14px;
  color: #45c195;
}
.card-item .card .content textarea:focus + .discount {
  opacity: 1;
}
.card-item .card .content div.title textarea,
.card-item .card .content div.title .wrap-mask {
  height: 60px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  top: -1px;
  color: #1d1d1d;
  padding: 2px 6px 0 !important;
}
.card-item .card .content div.body textarea,
.card-item .card .content div.body .wrap-mask {
  height: 60px;
  font-size: 12px;
  line-height: 1.4;
  color: #767676;
  top: -1px;
  padding: 4px 6px 0 !important;
}
.card-item .card .content > div.url textarea,
.card-item .card .content > div.url .wrap-mask {
  height: 22px;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey);
  white-space: nowrap;
}
.card-item .card .top-buttons {
  position: absolute;
  right: -8px;
  top: -28px;
  height: 27px;
  width: 271px;
  text-align: right;
  opacity: 1;
  transition: opacity 100ms;
  -webkit-transition: opacity 100ms;
  white-space: nowrap;
  padding: 0 4px 0 0;
  z-index: 10;
}
.card-item .card .top-buttons .button {
  display: inline-block;
  vertical-align: top;
  transition: opacity 60ms;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 8px;
  opacity: 0;
}
.card-item .card .top-buttons .button:hover {
  opacity: 1;
}
.card-item .card .top-buttons .button.image {
  background-image: url('../images/new/ic_image.svg');
}
.card-item .card .top-buttons .button.remove {
  top: 21px;
  left: 238px;
  position: absolute;
  margin: 0;
  width: 32px;
  height: 32px;
  background: var(--white) none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transform: scale(0.3);
  opacity: 0;
  transition: opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.card-item .card .top-buttons .button.remove::before {
  content: '';
  position: absolute;
  background: url('../images/new/ic_trash.svg') center center no-repeat;
  top: 0;
  left: 0;
  width: 30px;
  height: 31px;
  opacity: 0.3;
  transition: opasity 60ms;
}
.card-item .card .top-buttons .button.remove:hover::before {
  opacity: 1;
}
.card-item .card .top-buttons .button.drag {
  position: absolute;
  left: 8px;
  top: 0;
  background-image: url(../images/ic_drag_hor.svg);
  cursor: ew-resize;
  width: 190px;
  background-position: 4px center;
}
.card-item .card .action-list {
  position: relative;
}
.card-item .card .action-list:hover ~ .top-buttons .button {
  opacity: 0 !important;
}
.card-item .card .action-list > div {
  position: relative;
}
.card-item .card .action-list > .act {
  display: block !important;
}
.card-item .card .action-list .action-item {
  position: relative;
  width: 254px;
  height: 37px;
  line-height: 36px;
  border-top: solid 1px #ebebeb;
  background-color: var(--white);
  transition: border-color 400ms ease;
}
.card-item .card .action-list .action-item.ui-sortable-helper {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0);
  transition: transform 80ms ease, box-shadow 200ms ease, border-color 80ms ease;
  cursor: ns-resize !important;
  overflow: hidden;
  pointer-events: none;
  border-radius: 0 !important;
}
.card-item .card .action-list .action-item.ui-sortable-helper * {
  cursor: ns-resize !important;
}
.card-item .card .action-list .action-item.ui-sortable-helper .remove-btn-float {
  display: none;
}
.card-item .card .action-list .action-item.ui-sortable-helper:active {
  border-color: rgba(0, 0, 0, 0.17) !important;
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0.13);
  transform: scale(1.02, 1.02);
}
.card-item .card .action-list .action-item.ui-sortable-helper.error {
  border-color: #e9604a !important;
}
.card-item .card .action-list .action-item .remove-btn-float {
  position: absolute;
  z-index: 10000;
  top: -6px;
  left: -6px;
  width: 32px;
  height: 32px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  transform: scale(0.3);
  opacity: 0;
  transition: opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.card-item .card .action-list .action-item .remove-btn-float > div {
  width: 100%;
  height: 100%;
}
.card-item .card .action-list .action-item:hover .remove-btn-float {
  transform: scale(1);
  opacity: 1;
}
.card-item .card .action-list .action-item .title {
  position: relative;
  text-align: center;
  font-size: 16px;
  color: #448af1;
  width: 200px;
  height: 34px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 2px;
  text-overflow: ellipsis;
  resize: none;
  background-color: transparent;
}
.card-item .card .action-list .action-item .discount {
  position: absolute;
  text-align: center;
  font-size: 10px;
  color: #5dc991;
  opacity: 0;
  transition: opacity 100ms;
  right: 58px;
  bottom: 0;
  line-height: 14px;
  height: 14px;
}
.card-item .card .action-list .action-item textarea:focus + .discount {
  opacity: 1;
}
.card-item .card .action-list .action-item .preview {
  display: none;
  position: absolute;
  z-index: 10;
  height: 34px;
  top: 1px;
  left: 199px;
  width: 55px;
  overflow: hidden;
  line-height: 31px;
  font-size: 13px;
  padding-left: 2px;
  cursor: pointer;
}
.card-item .card .action-list .action-item .preview span {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 8px;
  font-size: 12px;
  line-height: 34px;
}
.card-item .card .action-list .action-item .preview::after {
  content: ' ';
  position: absolute;
  width: 60px;
  height: 44px;
  top: 0;
  right: 2px;
  background-color: var(--white);
  z-index: -1;
  border-bottom-right-radius: 7px;
}
.card-item .card .action-list .action-item .popup-editor {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 390px;
  height: 48px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  z-index: var(--zindex-popover);
  overflow: hidden;
}
.card-item .card .action-list .action-item .popup-editor .editor-type-buttons {
  display: block !important;
  right: auto;
  left: 8px;
  top: 7px;
  width: auto;
}
.card-item .card .action-list .action-item .popup-editor .content {
  position: absolute;
  left: 70px;
  top: 7px;
  height: 32px;
  width: 220px;
  padding: 0;
}
.card-item .card .action-list .action-item .popup-editor .content > input {
  display: block;
  border: 0;
  height: 100%;
  width: 100%;
  font-size: 12px;
  line-height: 1.3;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons {
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  width: 96px;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button {
  display: inline-block;
  opacity: 0.3;
  width: 48px;
  height: 46px;
  background-position: center center;
  background-repeat: no-repeat;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button.save:hover {
  opacity: 1;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button.save {
  background-image: url('../images/ic_done.svg');
  background-size: 25px 25px;
  margin-right: -4px;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button.cancel {
  position: relative;
  opacity: 1;
  background-color: var(--greyLight20);
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button.cancel::after {
  content: ' ';
  display: block;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../images/ic_trash.svg');
  background-size: 23px 23px;
  opacity: 0.3;
}
.card-item .card .action-list .action-item .popup-editor .save-close-buttons .button.cancel:hover::after {
  opacity: 1;
}
.card-item .card .action-list .action-item .target-add {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 36px;
  border-left: 1px solid var(--greyLight20);
  cursor: pointer;
}
.card-item .card .action-list .action-item .target-add::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url('../images/new/ic_add_small.svg') no-repeat center center;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  transition: opacity 60ms;
}
.card-item .card .action-list .action-item .target-add:hover::after {
  opacity: 1;
}
.card-item .card .action-list .action-item .editor-type-buttons {
  display: none;
  position: absolute;
  top: 6px;
  right: 2px;
  height: 32px;
  width: 55px;
  text-align: right;
  white-space: nowrap;
  z-index: 100;
  background-color: var(--white);
  padding-right: 2px;
}
.card-item .card .action-list .action-item .editor-type-buttons .button {
  display: inline-block;
  opacity: 0.3;
  width: 24px;
  height: 32px;
  background-position: center center;
}
.card-item .card .action-list .action-item .editor-type-buttons .button:hover,
.card-item .card .action-list .action-item .editor-type-buttons .button.act {
  opacity: 1;
  display: inline-block !important;
}
.card-item .card .action-list .action-item .editor-type-buttons .button.tag {
  margin-right: 2px;
  background-image: url('../images/ic_hash.svg');
}
.card-item .card .action-list .action-item .editor-type-buttons .button.url {
  background-image: url('../images/ic_link.svg');
}
.card-item .card .action-list .action-item .editor-type-buttons.inline {
  top: 0px;
  right: 1px;
  text-align: right;
  height: 35px;
  width: 54px;
  border-radius: 7px;
}
.card-item .card .action-list .action-item .editor-type-buttons.inline::after {
  display: none !important;
}
.card-item .card .action-list .action-item .editor-type-buttons.inline .button {
  height: 36px;
  width: 26px;
  background-position: center center;
  background-repeat: no-repeat;
  border-left: solid 1px #ebebeb;
}
.card-item .card .action-list .action-item .editor-type-buttons::after {
  position: absolute;
  content: ' ';
  width: 2px;
  height: 24px;
  background-color: #ebebeb;
  top: 4px;
  left: 26px;
}
.card-item .card .action-list .action-item.error {
  border: none;
  box-shadow: 0 0 0 1px #e9604a;
  z-index: 500;
  height: 36px;
  margin: 1px 0 0px 0;
  line-height: 37px;
}
.card-item .card .action-list .action-item.error .editor-type-buttons .button.tag {
  background-image: url('../images/ic_hash_red.svg');
  opacity: 1;
}
.card-item .card .action-list .action-item.error .editor-type-buttons .button.url {
  background-image: url('../images/ic_link_red.svg');
  opacity: 1;
}
.card-item .card .action-list .action-item:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.card-item .card .action-list .action-item.add {
  font-size: 14px;
  color: var(--black);
  text-align: center;
  line-height: 48px;
  height: 49px;
  cursor: pointer;
  text-transform: uppercase;
  opacity: 0.25;
  transition: opacity 60ms;
}
.card-item .card .action-list .action-item.add::before {
  content: ' ';
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../images/new/ic_add_small.svg') no-repeat center center;
  vertical-align: -7px;
}
.card-item .card .action-list .action-item.add:hover {
  opacity: 1;
}
.card-item .card:hover .top-buttons .button {
  opacity: 0.3;
  transform: scale(1);
}
.card-item .card:hover .top-buttons .button.remove {
  opacity: 1;
}
.card-item .r-buttons {
  left: -36px;
  position: absolute;
  top: var(--grid-unit, 4px);
  bottom: var(--grid-unit, 4px);
  width: 34px;
  opacity: 0;
  z-index: var(--zindex-mask);
  transition: opacity 60ms;
  height: 90%;
}
.card-item .r-buttons.position-top {
  top: 0;
}
.card-item .r-buttons.r-hide {
  opacity: 0 !important;
}
.card-item .r-buttons > .button {
  width: 24px;
  height: 24px;
  opacity: 0.3;
  z-index: 10;
}
.card-item .r-buttons > .button:hover {
  opacity: 1;
}
.card-item .r-buttons .remove {
  background: url('../images/ic_trash.svg') center center no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}
.card-item .r-buttons .move {
  cursor: ns-resize;
  background: url('../images/ic_drag_ver.svg') center center no-repeat;
  left: 0;
  top: 48px;
}
.card-item .r-buttons .l-hover {
  position: absolute;
  width: 24px;
  height: 100%;
  z-index: 0;
}
.card-item.text .l-hover:hover ~ .remove,
.card-item.image .l-hover:hover ~ .remove,
.card-item.plugin .l-hover:hover ~ .remove {
  opacity: 0 !important;
  transform: scale(0.3) !important;
}
.card-item.text .move,
.card-item.image .move,
.card-item.plugin .move {
  top: 0;
}
.card-item.text .remove,
.card-item.image .remove,
.card-item.plugin .remove {
  top: -17px;
  left: 257px;
  position: absolute;
  margin: 0;
  width: 32px;
  height: 32px;
  background: var(--white) none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transform: scale(0.3);
  opacity: 0;
  transition: opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.card-item.text .remove::before,
.card-item.image .remove::before,
.card-item.plugin .remove::before {
  content: '';
  position: absolute;
  background: url('../images/new/ic_trash.svg') center center no-repeat;
  top: 0;
  left: 0;
  width: 30px;
  height: 31px;
  opacity: 0.3;
  transition: opasity 60ms;
}
.card-item.text .remove:hover::before,
.card-item.image .remove:hover::before,
.card-item.plugin .remove:hover::before {
  opacity: 1;
}
.card-item.plugin .remove {
  left: 680px;
}
.card-item.short-margin-bottom {
  margin: -10px 0 20px;
}
.card-item .block-disabled {
  pointer-events: none;
  opacity: 0.78;
}
.card-item > div.disclosed .r-buttons,
.card-item > div:hover .r-buttons {
  opacity: 1;
}
.card-item.text > div:hover .r-buttons .remove,
.card-item.image > div:hover .r-buttons .remove,
.card-item.plugin > div:hover .r-buttons .remove {
  transform: scale(1);
  opacity: 1;
}
.card-item.gallery {
  white-space: nowrap;
  position: relative;
}
.card-item.gallery .drag-box {
  position: relative;
  white-space: nowrap;
  display: inline-block;
}
.card-item.gallery .card {
  display: inline-block;
  white-space: normal;
  margin-right: 8px;
  border-radius: 7px;
  vertical-align: top;
}
.card-item.gallery .card.ui-sortable-helper {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0);
  transition: transform 80ms ease, box-shadow 200ms ease, border-color 80ms ease;
}
.card-item.gallery .card.ui-sortable-helper:active {
  border-color: rgba(0, 0, 0, 0.07);
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0.13);
  transform: scale(1.02, 1.02);
  background: var(--white);
}
.card-item.gallery .card .body textarea {
  white-space: pre-wrap;
}
.card-item.gallery .card > .image {
  margin: 0;
  width: 254px;
  height: 144px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: var(--white);
  overflow: hidden;
}
.card-item.gallery .card > .image.found {
  background-color: rgba(53, 62, 65, 0);
}
.card-item.gallery .card > .image:hover.found {
  background-color: #353e41;
}
.card-item.gallery .card > .image:hover .img {
  opacity: 0.1;
}
.card-item.gallery .card > .image:hover .button-image,
.card-item.gallery .card > .image:hover .button.remove,
.card-item.gallery .card > .image:hover .button.crop {
  opacity: 0.7;
}
.card-item.gallery .card > .image:hover .button-image:hover,
.card-item.gallery .card > .image:hover .button.remove:hover,
.card-item.gallery .card > .image:hover .button.crop:hover {
  opacity: 1;
}
.card-item.gallery .card > .image .img {
  width: 254px;
  height: 144px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 100ms;
  background-color: var(--white);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.card-item.gallery .card > .image .img.loading {
  text-align: center;
  vertical-align: middle;
  line-height: 160px;
  opacity: 1 !important;
}
.card-item.gallery .card > .image .img.placeholder {
  opacity: 1 !important;
  background-color: var(--white);
  position: relative;
  border-bottom: solid 1px #ebebeb;
}
.card-item.gallery .card > .image .img.placeholder .button-image--replace {
  opacity: 0.35;
  background-position: center 38px;
  background-image: url('../images/new/ic_image.svg');
  width: 100%;
  height: 100% !important;
  top: 0 !important;
  margin: 0;
  line-height: 65px !important;
}
.card-item.gallery .card > .image .img.placeholder .button-image--replace .button-image__title {
  color: var(--black);
  font-size: 16px;
  position: relative !important;
  top: 10px;
  line-height: 26px;
}
.card-item.gallery .card > .image .img.placeholder .button-image--replace:hover {
  opacity: 1;
}
.card-item.gallery .card > .image .button {
  width: 24px;
  height: 24px;
  top: 8px;
  opacity: 0;
  z-index: 15;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 100ms;
}
.card-item.gallery .card > .image .button.remove {
  background-image: url('../images/ic-close-white.svg');
  right: 8px;
}
.card-item.gallery .card > .image .button.crop {
  background-image: url('../images/ic_crop.svg');
  right: 34px;
}
.card-item.gallery .card .action-list .action-item:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.card-item.gallery .card .top-buttons button.image {
  position: relative;
  overflow: hidden;
  border: none;
  background-color: transparent;
}
.card-item.gallery .card.add {
  height: 321px;
  line-height: 321px;
  text-align: center;
  cursor: pointer;
  margin-left: -4px;
}
.card-item.gallery .card.add.qr-mode {
  height: 277px;
  line-height: 277px;
}
.card-item.gallery .card.add::after {
  content: ' ';
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background: url('../images/new/ic_add_large.svg') no-repeat center center;
  opacity: 0.3;
}
.card-item.gallery .card.add:hover::after {
  opacity: 1;
}
.card-item.gallery .card:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.card-item.gallery .card:last-child > .image {
  border-top-right-radius: 7px;
}
.card-item.gallery .card:last-child .action-list .action-item:last-child {
  border-bottom-right-radius: 7px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image {
  height: 254px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image .img {
  height: 254px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image .img.loading {
  line-height: 260px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image .img .button-image {
  background-position: center 88px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image .img .button-image .button-image__title {
  vertical-align: -16px;
}
.card-item.gallery.square-img-gallery .drag-box .card > .image .button-image-wrapper {
  height: 254px;
}
.card-item.gallery.square-img-gallery .drag-box .card.add {
  height: 421px;
}
.card-item.text .card {
  background-color: #e2e2e2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 6px -1px rgba(0, 0, 0, 0.07);
}
.card-item.text .card .content {
  padding: 5px;
}
.card-item.text .card .content .body textarea,
.card-item.text .card .content .body .wrap-mask {
  overflow: hidden;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.32;
  color: var(--black);
  height: 89px;
  padding: 7px 9px;
  white-space: pre-wrap;
  margin: 0;
  border-radius: 7px;
  top: 0;
}
.card-item.text .card .content .body textarea:focus,
.card-item.text .card .content .body .wrap-mask:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.33) !important;
}
.card-item.text .card .content .body textarea.error:not(:focus),
.card-item.text .card .content .body .wrap-mask.error:not(:focus) {
  padding: 7px 9px;
}
.card-item.bc-plugin {
  float: none;
  width: 680px;
  -webkit-user-select: none;
          user-select: none;
}
.card-item.bc-plugin a {
  color: #e9604a;
  text-decoration: none;
}
.card-item.bc-plugin a:hover {
  color: #e9604a;
  text-decoration: underline;
}
.card-item.bc-plugin .card {
  position: relative;
  width: auto;
  padding: 32px 40% 52px 68px;
  border-radius: 7px;
  background-color: var(--greyLight20);
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  border: none;
}
.card-item.bc-plugin .card ol {
  padding: 0;
  margin: 0 0 24px;
  counter-reset: item;
}
.card-item.bc-plugin .card ol li {
  display: block;
  position: relative;
  margin: 0 0 24px;
  padding: 0 30px 0 0;
}
.card-item.bc-plugin .card ol li input {
  display: block;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  height: 36px;
  line-height: 34px;
  width: 90%;
  margin-top: 12px;
  padding: 0 12px;
  -webkit-user-select: text;
          user-select: text;
}
.card-item.bc-plugin .card ol li::before {
  left: -36px;
  width: 24px;
  height: 24px;
  line-height: 22px;
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  counter-increment: item;
  content: counter(item);
  position: absolute;
  font-size: 16px;
  color: var(--black);
  text-align: center;
}
.card-item.bc-plugin .card .col {
  position: absolute;
  right: 0px;
  top: 50%;
  height: 80%;
  width: 40%;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  transform: translateY(-50%);
  margin-top: -23px;
}
.card-item.bc-plugin .card .col > div {
  position: absolute;
  right: 0px;
  top: 50%;
  height: auto;
  width: 100%;
  transform: translateY(-50%);
  padding: 0 32px 0 54px;
  font-size: 16px;
  line-height: 1.5;
}
.card-item.bc-plugin .card .col > div p {
  margin: 24px 0;
}
.card-item.bc-plugin .card .col > div p label {
  position: relative;
  margin: 24px 0;
}
.card-item.bc-plugin .card .col > div p label input {
  position: absolute;
  left: -23px;
  top: 2px;
  height: 20px;
}
.card-item.bc-plugin .card .col > div p label span {
  display: block;
  color: #999999;
}
.card-item.bc-plugin .card .remove {
  position: absolute;
  width: 24px;
  height: 24px;
  background: url('../images/ic-close-black.svg') no-repeat center center;
  opacity: 0.2;
  right: 12px;
  top: 12px;
  background-size: 24px 24px;
}
.card-item.bc-plugin .card .remove:hover {
  opacity: 0.5;
}
.card-item.bc-plugin .card .footer {
  background-color: #69a1f3;
  bottom: 0;
  color: var(--white);
  font-size: 14px;
  height: 48px;
  left: 0;
  line-height: 1.1;
  padding: 8px 32px;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.card-item.bc-plugin .card .footer .no-connection-page {
  margin-top: 0;
}
.card-item.bc-plugin .card .footer .fb-btn-box {
  position: relative;
  display: inline-block;
  vertical-align: center;
  width: 123px;
  height: 20px;
  border-radius: 3px;
  overflow: hidden;
  background: url('../images/spinner_b.svg') no-repeat 4px center var(--white);
  background-size: 18px 18px;
}
.card-item.bc-plugin .card .footer .fb-btn-box > div > span {
  height: 40px !important;
}
.card-item.bc-plugin .card .footer .fb-btn-box iframe {
  margin: -10px 0 0 -3px;
}
.card-item.admin-chat-plugin .card {
  padding: 32px;
  position: relative;
  overflow: hidden;
  width: 680px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
.card-item.admin-chat-plugin .card .ptitle {
  padding: 0;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.5;
  color: var(--black);
  margin: 0 0 32px;
}
.card-item.admin-chat-plugin .card .selector {
  margin: 0 0 30px;
}
.card-item.admin-chat-plugin .card .admin-chat-top-buttons {
  height: 27px;
  width: 254px;
  text-align: right;
  opacity: 0;
  transition: opacity 100ms;
  -webkit-transition: opacity 100ms;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 4px 0 0;
}
.card-item.admin-chat-plugin .card .admin-chat-top-buttons .button {
  display: inline-block;
  vertical-align: top;
  opacity: 0.4;
  transition: opacity 60ms;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  margin-right: 8px;
}
.card-item.admin-chat-plugin .card .admin-chat-top-buttons .button:hover {
  opacity: 1;
}
.card-item.admin-chat-plugin .card .admin-chat-top-buttons .button.image {
  background-image: url('../images/new/ic_image.svg');
}
.card-item.admin-chat-plugin .card .admin-chat-image {
  margin: -5px 0 0 -2px;
  width: 254px;
  height: 142px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: var(--black);
  overflow: hidden;
}
.card-item.admin-chat-plugin .card .admin-chat-image:hover .img {
  opacity: 0.5;
}
.card-item.admin-chat-plugin .card .admin-chat-image:hover .button.remove,
.card-item.admin-chat-plugin .card .admin-chat-image:hover .button.crop {
  opacity: 0.5;
}
.card-item.admin-chat-plugin .card .admin-chat-image:hover .button.remove:hover,
.card-item.admin-chat-plugin .card .admin-chat-image:hover .button.crop:hover {
  opacity: 1;
}
.card-item.admin-chat-plugin .card .admin-chat-image .img {
  width: 254px;
  height: 142px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 100ms;
  background-color: var(--greyLight20);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.card-item.admin-chat-plugin .card .admin-chat-image .img.loading {
  text-align: center;
  vertical-align: middle;
  line-height: 160px;
}
.card-item.admin-chat-plugin .card .admin-chat-image .button {
  width: 24px;
  height: 24px;
  top: 8px;
  opacity: 0;
  z-index: 15;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 100ms;
}
.card-item.admin-chat-plugin .card .admin-chat-image .button.remove {
  background-image: url('../images/ic-close-white.svg');
  background-size: 16px 16px;
  right: 8px;
}
.card-item.admin-chat-plugin .card .admin-chat-image .button.crop {
  background-image: url('../images/ic_crop.svg');
  right: 34px;
}
.card-item.admin-chat-plugin .card .sub-card {
  width: 254px;
  background-color: var(--white);
  border: solid 1px #ebebeb;
  border-radius: 7px;
}
.card-item.admin-chat-plugin .card .sub-card.start {
  margin: 0 0 32px;
}
.card-item.admin-chat-plugin .card .sub-card.start .content .body textarea {
  height: 40px;
}
.card-item.admin-chat-plugin .card .sub-card.stop {
  margin: 0 0 32px;
  background-color: #ebebeb;
}
.card-item.admin-chat-plugin .card .sub-card.stop .content .body textarea {
  font-size: 16px;
  line-height: 1.3;
  color: var(--black);
  height: 68px;
  background-color: transparent;
}
.card-item.admin-chat-plugin .card .sub-card.stop .content .body textarea:focus {
  background-color: var(--white);
}
.card-item.admin-chat-plugin .card .sub-card .action-item {
  width: 252px;
}
.card-item.admin-chat-plugin .card .sub-card .action-item .title {
  width: 252px;
}
.card-item.admin-chat-plugin .card .sub-card .action-item .discount {
  right: 4px;
}
.card-item.admin-chat-plugin .card .sub-card .col {
  position: absolute;
  left: 316px;
  top: auto;
  transform: none;
  -webkit-transform: none;
  height: auto;
  width: 333px;
  border: none;
  margin: -4px 0 0;
}
.card-item.admin-chat-plugin .card .sub-card .col h4 {
  text-align: left;
  padding: 0;
  margin: 0 0 12px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  font-weight: bold;
}
.card-item.admin-chat-plugin .card .sub-card .col p {
  font-size: 13px;
  line-height: 1.4;
  color: #807e7e;
  padding: 0;
  margin: 0 0 14px;
}
.card-item.admin-chat-plugin .card .sub-card .discount {
  right: 6px;
  bottom: 2px;
}
.card-item.admin-chat-plugin .card .settings {
  clear: both;
  margin: 0 0 32px;
}
.card-item.admin-chat-plugin .card .settings .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  margin: 0 0 16px;
  padding: 0;
}
.card-item.admin-chat-plugin .card .settings .timeout-settings {
  width: 260px;
  height: 84px;
  line-height: 84px;
  border-radius: 7px;
  background-color: var(--white);
  font-size: 16px;
  color: var(--black);
  display: inline-block;
  vertical-align: top;
}
.card-item.admin-chat-plugin .card .settings .timeout-settings > div > input {
  display: inline-block;
  vertical-align: middle;
  width: 64px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  margin: 0 8px 0 12px;
}
.card-item.admin-chat-plugin .card .settings .timeout-input_container {
  line-height: 1.4;
  display: inline-block;
}
.card-item.admin-chat-plugin .card .settings .timeout-description {
  display: inline-block;
  width: 345px;
  padding: 0 0 0 19px;
  vertical-align: top;
  color: #807e7e;
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
}
.card-item.admin-chat-plugin .card .line {
  position: relative;
}
.card-item.admin-chat-plugin .card .line .control > label {
  display: block;
  -webkit-user-select: none;
  user-select: none;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='checkbox'] + span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 2px #dfdddd;
  margin: 0 8px 0 0;
  vertical-align: -2px;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='checkbox']:checked + span {
  background: url('../images/ic_done_w.svg') center center no-repeat #448af1;
  border: none;
  background-size: cover;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='radio'] + span {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 8px 0 0;
  vertical-align: -1px;
}
.card-item.admin-chat-plugin .card .line .control > label input[type='radio']:checked + span::after {
  content: ' ';
  z-index: 5;
  top: 3px;
  left: 3px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #448af1;
  border-radius: 50%;
}
.card-item.admin-chat-plugin .card .footer {
  background-color: #69a1f3;
  bottom: 0;
  color: var(--white);
  font-size: 16px;
  height: 48px;
  left: 0;
  line-height: 46px;
  padding: 0 32px;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.card-item.admin-chat-plugin .card .footer span {
  display: inline-block;
  width: 145px;
  height: 24px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.08);
  vertical-align: middle;
  margin: 0 5px 0 0;
}
.card-item.admin-chat-plugin .card:hover .admin-chat-top-buttons {
  opacity: 1;
}
.card-item.form {
  float: none;
  width: auto;
  min-width: 680px;
  max-width: 960px;
  -webkit-user-select: none;
          user-select: none;
}
.card-item.form .card {
  position: relative;
  width: auto;
  padding: 40px 32px 0;
  border-radius: 7px;
  background-color: var(--greyLight20);
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  border: none;
  min-width: 880px;
}
.card-item.form .card .line {
  margin-bottom: 16px;
  position: relative;
}
.card-item.form .card .line input {
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  padding: 0 12px;
  -webkit-appearance: none;
          appearance: none;
  outline: none !important;
  font-size: 16px;
  color: var(--black);
  margin: 0 11px 0 0;
}
.card-item.form .card .line .selector {
  display: inline-block;
  margin: 0 11px 0 0;
}
.card-item.form .card .line .s1 {
  width: 20%;
  min-width: 170px;
}
.card-item.form .card .line .s2 {
  width: 74.5%;
}
.card-item.form .card .line .s3 {
  width: 52.5%;
}
.card-item.form .card .line .s4 {
  width: 47.25%;
}
.card-item.form .card .line .button.add {
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  margin: auto;
}
.card-item.form .card .line .button.add::after {
  content: ' ';
  display: inline-block;
  background: url('../images/ic_add_small.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  opacity: 0.3;
}
.card-item.form .card .line .button.add:hover::after {
  opacity: 1;
}
.card-item.form .card .line .button.remove {
  height: 24px;
  position: absolute;
  right: 8px;
  top: 4px;
  width: 24px;
  background: url('../images/ic_trash.svg') no-repeat center center;
  opacity: 0;
  transition: opacity 100ms;
}
.card-item.form .card .line .button.remove:hover {
  opacity: 1 !important;
}
.card-item.form .card .line:last-child {
  margin: 0 0 24px;
}
.card-item.form .card .line:hover .button.remove {
  opacity: 0.3;
}
.card-item.form .card .button.close {
  position: absolute;
  background-image: url('../images/ic-close-black.svg');
  width: 24px;
  height: 24px;
  top: 8px;
  right: 8px;
  background-size: 24px 24px;
  opacity: 0.3;
  transition: opacity 100ms;
}
.card-item.form .card .button.close:hover {
  opacity: 1;
}
.card-item.form .card .content {
  padding: 0;
  margin-right: -32px;
}
.card-item.form .card .footer {
  margin: 0 -32px;
  padding: 0 0 8px 32px;
  border-top: 1px solid var(--white);
}
.card-item.form .card .footer .title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  padding: 16px 0 12px;
}
.card-item.image .card {
  height: 200px;
}
.card-item.image .card div > *,
.card-item.image .card div > .image-box .replace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.card-item.image .card div > .act {
  display: block;
}
.card-item.image .card div > .upload,
.card-item.image .card div .replace {
  background: url('../images/new/ic_image.svg') no-repeat center 65px;
  text-align: center;
  padding: 34px 0 0;
  opacity: 0.35;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  font-size: 16px;
  line-height: 26px;
  color: var(--black);
  transition: opacity 60ms;
  text-transform: capitalize;
}
.card-item.image .card div > .upload:hover,
.card-item.image .card div .replace:hover {
  opacity: 1;
}
.card-item.image .card div .replace {
  background: url('../images/new/ic_image_white.svg') no-repeat center 75px rgba(53, 62, 65, 0.9);
  z-index: 10;
  opacity: 0;
  color: var(--white);
  display: block !important;
  transition: opacity 100ms;
}
.card-item.image .card div > .image-box {
  border-radius: 7px;
  box-sizing: initial;
  left: 0;
  padding: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.card-item.image .card div > .image-box:hover .replace {
  opacity: 1;
}
.card-item.image .card div > .pre-loader {
  background-color: var(--white);
  border-radius: 5px;
  z-index: 2;
}
.card-item.image .card div > .pre-loader::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 3;
  background: url('../images/spinner_b.svg') no-repeat center center;
  background-size: 32px 32px;
}
.card-item.image .card div:hover .button-picture-wrapper {
  display: block !important;
}
.add-button-wrapper {
  margin: -20px 0 0;
}
.add-button-wrapper .add-button-title {
  display: inline-block;
  box-shadow: none;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--black);
  margin: 0 0 10px;
}
.add-button-wrapper .add-buttons-box {
  display: flex;
}
.card-item.add,
.add-plugin-window {
  display: inline-block;
  width: auto;
  position: relative;
  background-color: var(--white);
  margin: 0;
  border-radius: 8px;
}
.card-item.add ul,
.add-plugin-window ul {
  opacity: 1;
  pointer-events: none;
  transition: opacity 150ms;
  padding: 0;
  margin: 0;
}
.card-item.add ul.nav-flex,
.add-plugin-window ul.nav-flex {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.card-item.add ul.nav-flex li,
.add-plugin-window ul.nav-flex li {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
.card-item.add ul.nav-flex li .link-wrp,
.add-plugin-window ul.nav-flex li .link-wrp {
  flex: 1 1 100%;
}
.card-item.add ul.nav-flex li .link-wrp_new,
.add-plugin-window ul.nav-flex li .link-wrp_new {
  position: relative;
}
.card-item.add ul.nav-flex li .link-wrp_new:after,
.add-plugin-window ul.nav-flex li .link-wrp_new:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  right: 7px;
  top: -9px;
  background: #e9604a;
  border: 2px solid var(--white);
  border-radius: 5px;
}
.card-item.add ul li,
.add-plugin-window ul li {
  display: block;
  width: 85px;
  height: 68px;
  position: relative;
  z-index: 3;
  float: left;
  background-color: var(--white);
  outline: none;
  box-shadow: inset -1px 0 0 0 var(--greyLight20);
  margin: 0;
  padding: 1px 0px 0px;
  cursor: pointer;
  transition: background-color 150ms ease;
}
.card-item.add ul li:hover .icon-nav,
.add-plugin-window ul li:hover .icon-nav,
.card-item.add ul li:active .icon-nav,
.add-plugin-window ul li:active .icon-nav {
  opacity: 0.63;
}
.card-item.add ul li:hover .link-text,
.add-plugin-window ul li:hover .link-text,
.card-item.add ul li:active .link-text,
.add-plugin-window ul li:active .link-text {
  color: var(--black);
}
.card-item.add ul li:active,
.add-plugin-window ul li:active {
  background-color: #fcfcfc;
}
.card-item.add ul li.disabled,
.add-plugin-window ul li.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.card-item.add ul li .link-text,
.add-plugin-window ul li .link-text {
  display: block;
  width: 100%;
  position: relative;
  z-index: 4;
  float: left;
  color: #323232;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  outline: none;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: background-color 100ms 0ms;
}
.card-item.add ul li .link-text .asc,
.add-plugin-window ul li .link-text .asc {
  position: absolute;
  right: 12px;
  opacity: 0;
  transition: opacity 100ms;
  width: 24px;
  height: 24px;
  margin-top: -36px;
  cursor: pointer;
  background: url('../images/ic_info.svg') center center no-repeat;
  background-size: 22px auto;
}
.card-item.add ul li .link-text .asc-popup,
.add-plugin-window ul li .link-text .asc-popup {
  width: 256px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.05);
  height: 242px;
  padding: 0;
  position: absolute;
  right: -272px;
  top: -99999px;
  opacity: 0;
  transition: top 0ms 100ms, opacity 100ms 0ms;
  line-height: 242px;
}
.card-item.add ul li .link-text .asc-popup > div,
.add-plugin-window ul li .link-text .asc-popup > div {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 0 24px;
  font-size: 14px;
  line-height: 24px;
  color: var(--black);
  text-align: left;
}
.card-item.add ul li .link-text .asc-popup > div p,
.add-plugin-window ul li .link-text .asc-popup > div p {
  padding: 0;
  margin: 0 0 24px;
}
.card-item.add ul li .link-text .asc-popup > div p:last-child,
.add-plugin-window ul li .link-text .asc-popup > div p:last-child {
  margin: 0;
}
.card-item.add ul li .link-text .asc-popup > div ol,
.add-plugin-window ul li .link-text .asc-popup > div ol {
  margin: 0 0 20px;
  padding: 0;
}
.card-item.add ul li .link-text .asc-popup > div ol li,
.add-plugin-window ul li .link-text .asc-popup > div ol li {
  list-style-position: inside;
  display: list-item;
}
.card-item.add ul li .link-text .asc:hover,
.add-plugin-window ul li .link-text .asc:hover {
  opacity: 1 !important;
}
.card-item.add ul li .link-text .asc:hover + .asc-popup,
.add-plugin-window ul li .link-text .asc:hover + .asc-popup {
  transition: top 0ms 0ms, opacity 100ms 0ms;
  top: -2px;
  opacity: 1;
}
.card-item.add ul li .icon-nav,
.add-plugin-window ul li .icon-nav {
  position: relative;
  z-index: 4;
  display: block;
  float: left;
  vertical-align: middle;
  margin: 0;
  width: 100%;
  min-height: 20px;
  background: center center no-repeat;
  line-height: 1;
  opacity: 0.3;
  transition: opacity 150ms;
  background-size: 20px;
}
.card-item.add ul li .icon-nav + .link-text,
.add-plugin-window ul li .icon-nav + .link-text {
  margin-top: 7px;
}
.card-item.add ul li .icon-nav.gallery,
.add-plugin-window ul li .icon-nav.gallery {
  background-image: url('../../assets/images/new/ic_gallery.svg');
}
.card-item.add ul li .icon-nav.text,
.add-plugin-window ul li .icon-nav.text {
  background-image: url('../../assets/images/new/ic_textcard.svg');
  min-height: 14px;
  margin: -1px 0;
}
.card-item.add ul li .icon-nav.image,
.add-plugin-window ul li .icon-nav.image {
  background-image: url('../../assets/images/new/ic_imagecard.svg');
}
.card-item.add ul li .icon-nav.list,
.add-plugin-window ul li .icon-nav.list {
  background-image: url('../../assets/images/new/ic_textcard.svg');
  min-height: 14px;
  margin: -1px 0;
}
.card-item.add ul li .icon-nav.qr,
.add-plugin-window ul li .icon-nav.qr {
  background-image: url('../../assets/images/new/ic_qr_plugin.svg');
}
.card-item.add ul li .icon-nav.plugin,
.add-plugin-window ul li .icon-nav.plugin {
  background-image: url('../../assets/images/new/ic_plugin.svg');
}
.card-item.add ul li .icon-nav .full-around,
.add-plugin-window ul li .icon-nav .full-around {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.card-item.add ul li .icon-nav.icon-typing,
.add-plugin-window ul li .icon-nav.icon-typing {
  width: 20px;
  height: 25px;
  line-height: 0.4;
  letter-spacing: -1px;
  text-align: center;
  font-weight: 600;
  position: relative;
  float: none;
  margin: 0 auto;
}
.card-item.add ul li .icon-nav.icon-typing.icon-xs,
.add-plugin-window ul li .icon-nav.icon-typing.icon-xs {
  height: 12px;
  width: 17px;
}
.card-item.add ul li .icon-nav.icon-typing.icon-xs:before,
.add-plugin-window ul li .icon-nav.icon-typing.icon-xs:before {
  top: -1px;
  height: 14px;
}
.card-item.add ul li .icon-nav.icon-typing:before,
.add-plugin-window ul li .icon-nav.icon-typing:before {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: var(--black);
  color: var(--black);
  border-radius: 20px;
}
.card-item.add ul li .icon-nav.icon-typing:after,
.add-plugin-window ul li .icon-nav.icon-typing:after {
  content: '...';
  position: relative;
}
.card-item.add ul li:last-child,
.add-plugin-window ul li:last-child {
  box-shadow: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.card-item.add ul li:last-child:after,
.add-plugin-window ul li:last-child:after,
.card-item.add ul li:last-child:before,
.add-plugin-window ul li:last-child:before {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.card-item.add ul li:last-child:after,
.add-plugin-window ul li:last-child:after {
  right: 0;
}
.card-item.add ul li:last-child:hover .icon-nav,
.add-plugin-window ul li:last-child:hover .icon-nav,
.card-item.add ul li:last-child:active .icon-nav,
.add-plugin-window ul li:last-child:active .icon-nav {
  opacity: 1;
}
.card-item.add ul li:first-child,
.add-plugin-window ul li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.card-item.add ul li:first-child:after,
.add-plugin-window ul li:first-child:after,
.card-item.add ul li:first-child:before,
.add-plugin-window ul li:first-child:before {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.card-item.add ul li:first-child:after,
.add-plugin-window ul li:first-child:after {
  left: -1px;
}
.card-item.add ul.forSkills li,
.add-plugin-window ul.forSkills li {
  width: 81px;
}
.card-item.add:hover ul,
.add-plugin-window:hover ul {
  opacity: 1;
  pointer-events: auto;
}
.card-item.add:hover::after,
.add-plugin-window:hover::after {
  display: none;
}
.card-item::after {
  content: ' ';
  display: block;
  clear: both;
}
.cards-list {
  position: relative;
  margin-bottom: 230px;
}
.cards-list::after {
  content: ' ';
  display: block;
  clear: both;
}
.instagram-lock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 242, 242, 0.7);
  overflow: hidden;
  border-radius: 7px;
  z-index: 100;
  -webkit-user-select: none;
  user-select: none;
}
.instagram-lock > div {
  position: absolute;
  text-align: center;
  width: 325px;
  color: var(--black);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 14px;
}
.instagram-lock ~ * {
  filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  pointer-events: none;
}
.button-picture-wrapper {
  position: relative !important;
  display: none !important;
  top: 0 !important;
  left: 0 !important;
  width: 255px !important;
  height: 200px !important;
  background-color: rgba(53, 62, 65, 0.9);
  border-radius: 7px;
  opacity: 1;
  z-index: 1;
}
.button-picture-wrapper .button-image--replace-picture .button-image__title,
.button-picture-wrapper .button-image--crop-picture .button-image__title {
  height: 21px !important;
}
.button-picture-wrapper .button-image--replace-picture:hover,
.button-picture-wrapper .button-image--crop-picture:hover {
  opacity: 1 !important;
}
.button-picture-wrapper .button-image--replace-picture {
  width: 50px !important;
  margin-left: 60px;
}
.button-picture-wrapper .button-image--crop-picture {
  width: 32px !important;
  margin-left: 161px;
}
.button-picture-wrapper:hover .button-image {
  opacity: 0.7;
}
.button-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 266px;
  height: 142px;
}
.button-image-wrapper:hover .button-image {
  opacity: 0.5;
}
.button-image {
  position: absolute !important;
  top: 50% !important;
  height: 50px !important;
  margin-top: -23px;
  line-height: 48px;
  cursor: pointer;
  opacity: 0;
}
.button-image .button-image__title {
  position: static !important;
  display: inline-block !important;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  vertical-align: bottom;
  line-height: 1.8;
  text-transform: capitalize;
  color: var(--white);
}
.button-image--replace {
  display: inline-block !important;
  padding: 0px;
  border: none;
  outline: none;
  background: transparent url('../images/new/ic_image_white.svg') no-repeat top center;
}
.button-image--replace-gallery {
  margin-left: 34px;
}
.button-image--replace-picture {
  margin-left: 34px;
}
.button-image--crop {
  display: inline-block !important;
  background: transparent url('../images/ic_crop.svg') no-repeat top center;
}
.button-image--crop-gallery {
  margin-left: 114px;
}
.button-image--remove {
  display: inline-block !important;
  background: transparent url('../images/new/ic_remove.svg') no-repeat top center;
}
.button-image--remove-gallery {
  margin-left: 172px;
}
.action-item-tooltip .tooltip-inner {
  background-color: #053748;
  width: 250px;
  text-align: left;
  height: auto;
}
.action-item-tooltip .tooltip-arrow {
  border-right-color: #053748 !important;
}
