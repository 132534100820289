billing-details-component .modal-paddings {
  padding: 16px 28px;
}
billing-details-component .content {
  min-width: 317px;
}
billing-details-component modalactions {
  padding-right: 28px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
billing-details-component .small-container {
  width: 221px;
}
billing-details-component .input-spacing {
  margin-top: 6px;
}
billing-details-component .input-spacing:first-child {
  margin-top: 8px;
}
billing-details-component .text-15 {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey);
}
billing-details-component .button-container_inside_spacing > *:not(:first-child) {
  margin-left: 8px;
}
