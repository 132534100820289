section.settings > div .border-box.bc-api {
  width: 100%;
  min-width: unset;
  overflow: hidden;
  position: relative;
  margin-bottom: 32px;
}
section.settings > div .border-box.bc-api p {
  margin: 0 0 12px;
}
section.settings > div .border-box.bc-api p a {
  text-decoration: none;
  color: #448af1;
}
section.settings > div .border-box.bc-api p a:hover {
  text-decoration: underline;
}
section.settings > div .border-box.bc-api .token-wrapper .token-field {
  width: 325px;
  height: 36px;
  font-size: 16px;
  border-radius: 7px;
  padding: 6px 16px;
  background-color: #fafafa;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  vertical-align: bottom;
  box-sizing: border-box;
  margin-right: 16px;
}
section.settings > div .border-box.bc-api .token-wrapper .reset {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  min-width: 100px;
  border-radius: 7px;
}
section.settings .tooltip-copied {
  animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  -webkit-animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  opacity: 0;
  padding: 0;
}
section.settings .tooltip-copied .tooltip-inner {
  background-color: var(--green);
  min-width: 108px;
  height: 20px;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: var(--white);
  border-radius: 2px;
  line-height: 16px;
}
section.settings .tooltip-copied .tooltip-arrow {
  border-top-color: var(--green);
  bottom: -5px;
}
@keyframes tooltip {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    margin: -8px 0;
  }
  90% {
    opacity: 1;
    margin: -8px 0;
  }
  100% {
    opacity: 0;
    margin: -16px 0;
  }
}
