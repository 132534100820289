.quick-reply-plugin {
  border-top: 1px dashed #e0e0e0;
  border-bottom: 1px dashed #e0e0e0;
  padding: 40px 0 0;
  background-color: var(--greyLight20);
  height: 122px;
}
.quick-reply-plugin:not(.ui-sortable-helper) + .quick-reply-plugin,
.quick-reply-plugin:not(.ui-sortable-helper) + .ui-sortable-helper + .quick-reply-plugin {
  margin-top: -40px;
}
.quick-reply-plugin.ui-sortable-placeholder {
  visibility: visible !important;
  background-color: transparent !important;
  border: none !important;
}
.quick-reply-plugin.ui-sortable-placeholder::before {
  display: none;
}
.quick-reply-plugin.ui-sortable-placeholder::after {
  content: 'Quick replies can be placed only under text, gallery, list or image card';
  display: block;
  background-color: var(--greyLight20) !important;
  width: 100%;
  height: 100%;
  line-height: 53px;
  padding: 0 0 0 78px;
  font-size: 12px;
  color: var(--black);
  white-space: nowrap;
  border: none !important;
  right: -28px;
  background: url('../images/ic_warning_red.svg') 51px 14px no-repeat;
}
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper) {
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--greyLight20);
  opacity: 1;
  border: 1px solid var(--greyLight20);
}
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper) .qr-error {
  display: block;
}
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper) .quick-replies {
  opacity: 0.3;
  pointer-events: none;
}
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper) .pause {
  display: none;
}
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper)::before,
.quick-reply-plugin.quick-reply-disabled:not(.ui-sortable-helper)::after {
  background-color: var(--greyLight20);
  border: 1px solid var(--greyLight20);
}
.quick-reply-plugin.ui-sortable-helper {
  box-shadow: 0 12px 16px -1px rgba(0, 0, 0, 0);
  transition: box-shadow 200ms ease;
  height: 122px !important;
}
.quick-reply-plugin .r-buttons {
  top: 50%;
  transform: translateY(-50%);
  height: 52px;
}
.quick-reply-plugin .r-buttons .move {
  top: 13px;
}
.quick-reply-plugin .r-buttons .remove {
  display: none;
  top: 28px;
  background-image: url('../images/ic_trash_mid.svg');
}
.quick-reply-plugin:hover .r-buttons {
  opacity: 1 !important;
}
.quick-reply-plugin .pause {
  position: absolute;
  font-size: 14px;
  color: #767676;
  bottom: -1px;
  background-color: var(--greyLight20);
  height: 2px;
  line-height: 1px;
  padding: 0 24px 0 0;
  z-index: 1;
}
.quick-reply-plugin .pause .button-ver2 {
  position: absolute;
  top: -6px;
  right: 4px;
}
.quick-reply-plugin .pause .tooltip {
  display: block !important;
  width: auto !important;
  height: auto !important;
  line-height: normal;
  margin: -24px 0 0 21px;
}
.quick-reply-plugin .pause .tooltip .tooltip-inner {
  background-color: #053748;
  max-width: 320px;
  white-space: normal;
  height: auto !important;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
}
.quick-reply-plugin .pause .tooltip .tooltip-arrow {
  border-top-color: #053748 !important;
}
.quick-reply-plugin .qr-error {
  font-size: 12px;
  color: var(--black);
  margin: -28px 0 12px;
  display: none;
  white-space: nowrap;
  line-height: 24px;
}
.quick-reply-plugin .qr-error::before {
  content: '';
  display: inline-block;
  vertical-align: -7px;
  width: 24px;
  height: 24px;
  background: url('../images/ic_warning_red.svg') no-repeat center center;
}
.tooltip-c-pause {
  display: block !important;
  width: auto !important;
  height: auto !important;
  line-height: normal;
}
.tooltip-c-pause .tooltip-inner {
  background-color: #053748;
  max-width: 320px;
  white-space: normal;
  height: auto !important;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  min-width: unset;
}
.tooltip-c-pause .tooltip-arrow {
  border-top-color: #053748 !important;
  min-width: unset;
}
.card-item.text + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.image + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.gallery + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.list + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.gallery + .ui-sortable-helper + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.image + .ui-sortable-helper + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.text + .ui-sortable-helper + .quick-reply-plugin.ui-sortable-placeholder,
.card-item.list + .ui-sortable-helper + .quick-reply-plugin.ui-sortable-placeholder {
  visibility: hidden !important;
}
