.plugin-modules .card .go-to-block .info {
  position: relative;
}
.plugin-modules .card .go-to-block .content .l-col .line .control {
  margin: 0 11px 18px 0;
}
.plugin-modules .card .go-to-block .content .l-col .line .control .control-title {
  font-size: 12px;
  line-height: 1.5;
  color: #666666;
}
.plugin-modules .card .go-to-block .content .l-col .line .control .remove-line {
  left: auto;
  right: -12px;
  top: -12px;
}
.plugin-modules .card .go-to-block .content .l-col .line .control .blocks-suggest-wrapper:hover .remove-line {
  transform: scale(1);
  opacity: 1;
}
.plugin-modules .card .go-to-block .content .l-col .line .random-switcher {
  display: inline-block;
  position: absolute;
  right: 0;
  margin: 10px 0 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.plugin-modules .card .go-to-block .content .l-col .line .random-switcher .art-switcher {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 4px;
}
.plugin-modules .card .go-to-block .content .l-col .line .random-switcher .art-switcher:after {
  position: absolute;
  content: '';
  top: 0;
  left: 100%;
  height: 100%;
  width: 61px;
}
.plugin-modules .card .go-to-block .content .l-col .line .random-switcher > span {
  color: #767676;
}
.plugin-modules .card .go-to-block .content .l-col .line .button-ver2.go-to-block-add-bitton {
  height: 36px;
  line-height: 34px;
  display: inline-block;
}
.plugin-modules .card .go-to-block .content .l-col .line .button-ver2.go-to-block-add-bitton::before {
  content: '';
  background: url('../images/ic_add_small.svg') center center no-repeat;
  width: 16px;
  height: 16px;
  margin: 0 7px 0 -5px;
  opacity: 0.3;
  display: inline-block;
  vertical-align: -3px;
}
.plugin-modules .card .go-to-block .content .l-col .line .button-ver2.go-to-block-add-bitton:hover::before {
  opacity: 1;
}
.plugin-modules .card .go-to-block .content .l-col .line.add-blocks-line {
  margin-top: 6px;
}
