section.settings > div .border-box.admins {
  width: 100%;
  min-width: unset;
  min-height: 228px !important;
  overflow: hidden;
  position: relative;
  padding: 0 !important;
}
section.settings > div .border-box.admins::before {
  content: '';
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../images/spinner_b.svg') center center no-repeat var(--white);
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}
section.settings > div .border-box.admins .pages-list-header {
  padding: 20px 24px 16px 24px;
}
section.settings > div .border-box.admins .pages-list-header .admins-header {
  margin: 0;
  display: inline-block;
}
section.settings > div .border-box.admins .pages-list-header .buttons-header {
  font-size: 0;
  display: inline-block;
  float: right;
}
section.settings > div .border-box.admins .pages-list-header .buttons-header .button {
  border-radius: 7px;
}
section.settings > div .border-box.admins p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
  margin: 0;
  padding: 0 24px 24px 24px;
}
section.settings > div .border-box.admins ul {
  display: block;
  padding: 0;
  margin: 0;
  max-height: 536px;
  overflow: auto;
  border-top: 1px solid var(--greyLight20);
  background-color: var(--white);
}
section.settings > div .border-box.admins ul li {
  display: block;
  position: relative;
  border-top: 1px solid var(--greyLight20);
  margin: 0;
  height: 72px;
  line-height: 72px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0 24px 0 80px;
  background-color: var(--white);
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 100ms;
  -webkit-transition: background-color 100ms;
}
section.settings > div .border-box.admins ul li:first-child {
  border-top: none;
}
section.settings > div .border-box.admins ul li > span {
  position: absolute;
  content: ' ';
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background: center center no-repeat var(--greyLight20);
  background-size: cover;
  color: transparent;
}
section.settings > div .border-box.admins ul li.you {
  pointer-events: none;
}
section.settings > div .border-box.admins ul li.you span {
  color: #bbbbbb;
}
section.settings > div .border-box.admins ul li:hover {
  background-color: var(--greyLight20);
}
section.settings > div .border-box.admins ul li:hover .remove {
  opacity: 0.35;
}
section.settings > div .border-box.admins ul li .remove {
  opacity: 0;
  position: absolute;
  background-image: url('../images/ic_trash.svg');
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: opacity 100ms;
  -webkit-transition: opacity 100ms;
}
section.settings > div .border-box.admins ul li .remove:hover {
  opacity: 1;
}
section.settings > div .border-box.admins ul li > div {
  line-height: 1.25;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 6px;
}
section.settings > div .border-box.admins ul li > div > div {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
  color: var(--grey);
  padding: 4px 0 0;
}
section.settings > div .border-box.admins ul li > div > span {
  color: var(--grey);
}
section.settings > div .border-box.no-spiner::before {
  display: none;
}
