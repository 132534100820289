*[suggest-template-multiline] {
  position: relative;
}
*[suggest-template-multiline] > .auto-box {
  display: none;
  position: absolute;
  z-index: var(--zindex-dropdown);
  top: 38px;
  left: 0;
  min-width: 200px;
  max-height: 294px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2px 0;
}
*[suggest-template-multiline] > .auto-box.act {
  display: block;
}
*[suggest-template-multiline] > .auto-box li {
  cursor: pointer;
  padding: 5px;
  white-space: nowrap;
}
*[suggest-template-multiline] > .auto-box li > span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 2px 22px;
  height: 26px;
  line-height: 22px;
  background-color: #906d60;
  font-size: 16px;
  color: var(--white);
  border-radius: 13px;
  max-width: 218px;
  overflow: hidden;
  text-overflow: ellipsis;
}
*[suggest-template-multiline] > .auto-box li > span::before {
  content: '{{';
  font-size: 16px;
  position: absolute;
  left: 8px;
  top: 1px;
  color: #e0995d;
}
*[suggest-template-multiline] > .auto-box li > span::after {
  content: '}}';
  font-size: 16px;
  position: absolute;
  right: 8px;
  top: 1px;
  color: #e0995d;
}
*[suggest-template-multiline] > .auto-box li:hover,
*[suggest-template-multiline] > .auto-box li.act {
  background-color: var(--greyLight20);
}
*[suggest-template-multiline] > .auto-box li.system > span {
  background-color: #9e80bd;
}
*[suggest-template-multiline] > .auto-box li.system > span:before,
*[suggest-template-multiline] > .auto-box li.system > span:after {
  color: #e0e0e0;
}
*[suggest-template-multiline] > .wrap-mask {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: auto !important;
  top: 0;
  left: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  padding: 2px 6px;
  margin: 2px 0 0 0;
  white-space: pre-wrap;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  background-color: transparent !important;
}
*[suggest-template-multiline] > .wrap-mask > .mask {
  color: rgba(0, 0, 0, 0);
  display: block;
  word-wrap: break-word;
}
*[suggest-template-multiline] > .wrap-mask > .mask > span {
  display: inline;
  border-radius: 13px;
  background-color: #906d60;
  color: var(--white);
  padding: 0;
  margin: 0;
  opacity: 1;
  vertical-align: inherit;
}
*[suggest-template-multiline] > .wrap-mask > .mask > span i {
  color: #e0995d;
  opacity: 1;
  font-style: normal;
  position: relative;
  top: -1px;
}
*[suggest-template-multiline] > .wrap-mask > .mask > span.system {
  background-color: #9e80bd;
}
*[suggest-template-multiline] > .wrap-mask > .mask > span.system i {
  color: #e0e0e0;
}
