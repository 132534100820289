.quick-replies {
  padding: 0;
  position: relative;
  margin: 0;
}
.quick-replies .or {
  width: 254px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: var(--grey);
  text-transform: uppercase;
  margin: -8px 0 0;
}
.quick-replies .items-list {
  position: relative;
  white-space: nowrap;
}
.quick-replies .items-list .item {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 40px;
  margin: 0 8px 0 0;
  border-radius: 20px;
  white-space: nowrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.quick-replies .items-list .item.add {
  font-size: 14px;
  text-align: center;
  color: var(--black);
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 188px;
  line-height: 38px;
  text-transform: uppercase;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  transition: box-shadow 80ms ease 0s, border-top-color 80ms ease 0s, border-right-color 80ms ease 0s, border-bottom-color 80ms ease 0s, border-left-color 80ms ease 0s, background-color 80ms ease 0s;
}
.quick-replies .items-list .item.add::before {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../../assets/images/ic_add_small.svg) no-repeat center center;
  opacity: 0.25;
  vertical-align: -3px;
  transition: opacity 60ms;
  margin: 0 6px 0 0;
}
.quick-replies .items-list .item.add:hover {
  border-color: rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
}
.quick-replies .items-list .item.add:hover::before {
  opacity: 1;
}
.quick-replies .items-list .item.add:active {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.22);
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 20ms ease 0s, border-color 20ms ease 0s;
}
.quick-replies .items-list .item.add:first-child {
  width: 254px;
  padding: 0 33px 0 24px;
}
.quick-replies .items-list .item .title-box {
  height: 40px;
  line-height: 38px;
  border-radius: 20px;
  background-color: var(--white);
  padding: 6px 0 0;
  border: solid 1px #448af1;
  position: relative;
}
.quick-replies .items-list .item .title-box .art-textarea-expand {
  position: static;
  pointer-events: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  line-height: 21px;
  min-height: unset;
  color: #448af1;
  padding: 0 0 0 26px;
  margin: 3px 26px 0 0;
  -webkit-user-select: none;
  user-select: none;
}
.quick-replies .items-list .item .title-box .art-textarea-expand br {
  content: '';
}
.quick-replies .items-list .item .title-box .art-textarea-expand br::after {
  content: ' ';
}
.quick-replies .items-list .item .title-box .art-textarea-expand div {
  display: inline;
  /* prevent new line*/
}
.quick-replies .items-list .item .title-box .art-textarea-expand::after {
  bottom: 3px;
  right: 0;
  transition: opacity 100ms;
}
.quick-replies .items-list .item .title-box .art-textarea-expand:empty {
  min-width: 243px;
}
.quick-replies .items-list .item .title-box .preview {
  text-align: center;
  line-height: 14px;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 3px;
  opacity: 1;
  transition: opacity 100ms;
  pointer-events: none;
}
.quick-replies .items-list .item .title-box .preview > div {
  overflow: hidden;
  white-space: nowrap;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.quick-replies .items-list .item .title-box .preview > div span {
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 14px;
  border-radius: 3px;
  background-color: var(--greyLight20);
  font-size: 12px;
  line-height: 14px;
  color: #767676;
  padding: 0 2px;
  margin: 0 5px 0 0;
}
.quick-replies .items-list .item .title-box .preview > div span::after {
  content: ',';
  position: absolute;
  top: 0;
  right: -3px;
}
.quick-replies .items-list .item .title-box .preview > div span:last-child {
  margin: 0;
}
.quick-replies .items-list .item .title-box .preview > div span:last-child::after {
  display: none;
}
.quick-replies .items-list .item.has-preview .title-box {
  padding: 0;
}
.quick-replies .items-list .item > .buttons {
  position: absolute;
  white-space: nowrap;
  bottom: -22px;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  opacity: 0;
  transition: opacity 60ms;
  -webkit-transition: opacity 60ms;
}
.quick-replies .items-list .item > .buttons .button-ver2 {
  display: inline-block;
  vertical-align: top;
  width: 48px;
  height: 19px;
  background-position: center -2px;
}
.quick-replies .items-list .item.error {
  border: solid 1px #e9604a;
}
.quick-replies .items-list .item.error .title .input {
  color: #e9604a;
}
.quick-replies .items-list .item > .trash-popup {
  left: auto;
  right: -6px;
}
.quick-replies .items-list .item:hover .buttons {
  opacity: 1;
}
.quick-replies .items-list .item:hover > .trash-popup {
  opacity: 1 !important;
  transform: scale(1);
}
.quick-replies .items-list .item .popup {
  pointer-events: none;
  transform: scale(0.3) translateY(-30px);
  transition: opacity 100ms, transform 100ms;
  opacity: 0;
  position: absolute;
  left: -17px;
  top: -39px;
  z-index: var(--zindex-popover);
  width: 280px;
  height: 188px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 9px 16px 16px;
}
.quick-replies .items-list .item .popup .buttons {
  text-align: right;
  padding: 24px 0 0;
  line-height: normal;
}
.quick-replies .items-list .item .popup .buttons .button-ver2 {
  display: inline-block;
  vertical-align: top;
  width: 104px;
  height: 36px;
  margin: 0 0 0 12px;
  line-height: 34px;
}
.quick-replies .items-list .item .popup .buttons .button-ver2.orange-rounded {
  line-height: 36px;
}
.quick-replies .items-list .item .popup .sup-title {
  line-height: 28px;
  font-size: 16px;
  color: var(--black);
  padding: 0 0 51px;
}
.quick-replies .items-list .item .popup .blocks-title {
  line-height: 28px;
  font-size: 16px;
  color: var(--black);
  padding: 0 0 1px;
}
.quick-replies .items-list .item.edit {
  cursor: auto;
  pointer-events: auto;
}
.quick-replies .items-list .item.edit .title-box {
  z-index: var(--zindex-popover-next);
  padding: 6px 0 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  max-width: 246px;
}
.quick-replies .items-list .item.edit .title-box .preview {
  opacity: 0;
}
.quick-replies .items-list .item.edit .title-box .art-textarea-expand {
  pointer-events: auto;
  -webkit-user-select: auto;
  user-select: all;
}
.quick-replies .items-list .item.edit .popup {
  transform: scale(1) translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.quick-replies .items-list .item.edit > .trash-popup {
  opacity: 0 !important;
  transform: scale(0.3);
  pointer-events: none;
}
.quick-replies .user-property {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  position: relative;
  height: 36px;
  line-height: 36px;
  margin: 0 6px 0 24px;
}
.quick-replies .user-property .button-ver2.white {
  padding: 0 24px 0 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  background-color: var(--greyLight20);
  border-radius: 7px;
}
.quick-replies .user-property .button-ver2.white::before {
  content: ' ';
  display: inline-block;
  background: url('../../assets/images/ic_user_variable.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  opacity: 0.35;
  margin: -3px 8px 0 0;
}
.quick-replies .user-property .button-ver2.white:hover {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(0, 0, 0, 0.2);
}
.quick-replies .user-property .title {
  display: inline-block;
  margin: 0 4px 0 -4px;
  font-size: 14px;
  color: #7b7b7b;
  height: 36px;
  line-height: 36px;
}
.quick-replies .user-property .title i {
  font-style: normal;
  color: #cecece;
}
.quick-replies .user-property .property {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  height: 24px;
  line-height: 24px;
  background-color: #906d60;
  font-size: 14px;
  color: var(--white);
  cursor: pointer;
  border-radius: 13px;
}
.quick-replies .user-property .property span {
  display: inline-block;
  vertical-align: top;
}
.quick-replies .user-property .property::before {
  content: '{{';
  font-size: 14px;
  position: relative;
  left: 1px;
  top: -1px;
  color: #e0995d;
}
.quick-replies .user-property .property::after {
  content: '}}';
  font-size: 14px;
  position: relative;
  left: -1px;
  top: -1px;
  color: #e0995d;
}
.quick-replies .user-property .property .trash-popup {
  left: auto;
  right: -6px;
}
.quick-replies .user-property .property:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.quick-replies .ai-mode {
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  font-size: 14px;
  color: var(--black);
  padding: 0 0 0 16px;
}
.quick-replies .ai-mode .art-switcher {
  margin: 0 6px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.quick-replies .ai-mode .help-mini {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 2px;
}
.quick-replies .ai-mode .qr-tooltip {
  display: block !important;
  width: auto !important;
  height: auto !important;
  line-height: normal;
}
.quick-replies .ai-mode .qr-tooltip .tooltip-inner {
  background-color: #053748;
  max-width: 320px;
  white-space: normal;
  height: auto !important;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
}
.quick-replies .ai-mode .qr-tooltip .tooltip-arrow {
  border-left-color: #053748 !important;
}
.quick-replies .help-box {
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 36px;
}
.quick-replies .help-box .button-ver2.help {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background-size: contain;
}
.quick-replies .help-box .tool-tips {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 34px;
  white-space: normal;
  z-index: 10;
}
.quick-replies .help-box .tool-tips div p {
  margin: 0 0 24px;
}
.quick-replies .help-box .tool-tips div p:last-child {
  margin: 0;
}
.quick-replies .help-box .tool-tips div p.title {
  font-weight: bold;
}
.quick-replies .help-box .tool-tips .tt1,
.quick-replies .help-box .tool-tips .tt2 {
  position: relative;
}
.quick-replies .help-box .tool-tips .tt1::before,
.quick-replies .help-box .tool-tips .tt2::before {
  content: ' ';
  background-color: #053748;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  left: -5px;
  width: 12px;
  height: 12px;
}
.quick-replies .help-box .tool-tips .tt1 .button-ver2,
.quick-replies .help-box .tool-tips .tt2 .button-ver2 {
  position: absolute;
  background-size: 18px auto;
}
.quick-replies .help-box .tool-tips .tt1 {
  width: 480px;
  height: 289px;
  border-radius: 7px;
  padding: 16px 228px 16px 16px;
  font-size: 14px;
  line-height: 1.71;
  color: var(--white);
  background: url('../../assets/images/pic_qr_tips.png') no-repeat 264px 53px #053748;
  background-size: 200px auto;
}
.quick-replies .help-box .tool-tips .tt1 .button-ver2 {
  top: 8px;
  right: 8px;
}
.quick-replies .help-box .tool-tips .tt2 {
  border-radius: 3px;
  width: 259px;
  height: 114px;
  padding: 12px 28px 12px 12px;
  font-size: 14px;
  line-height: 1.29;
  color: var(--white);
  background-color: #053748;
  margin-left: -408px;
}
.quick-replies .help-box .tool-tips .tt2 .button-ver2 {
  top: 4px;
  right: 4px;
}
.quick-replies > .trash.button-ver2 {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  opacity: 0;
  z-index: 10;
  margin: 4px 0 0 0;
}
.quick-replies:hover > .trash {
  opacity: 0.3;
  display: block;
}
.quick-replies::before {
  content: ' ';
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 10px;
  height: 24px;
}
