.typing-container {
  margin: 20px 24px;
}
.typing-container .typing-body .typing-title {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: var(--black);
  margin-right: 28px;
}
.typing-container .typing-body .typing-title .typing-logo {
  background: url('../../assets/images/ic_typing_round.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 24px;
  vertical-align: bottom;
  margin-right: 12px;
}
.typing-container .typing-body .typing-selector {
  display: inline-block;
  width: 350px;
}
div.plugin-modules .card.card-no-padding {
  padding: 0;
}
div.plugin-modules .card.card-no-padding div.asc {
  top: 22px;
  right: 24px;
}
