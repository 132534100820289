*[suggest-template] {
  position: relative;
}
*[suggest-template] > .auto-box {
  display: none;
  position: absolute;
  z-index: var(--zindex-dropdown);
  top: 65px;
  left: 0;
  min-width: 200px;
  max-height: 294px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2px 0;
  margin: 0;
}
*[suggest-template] > .auto-box.act {
  display: block;
}
*[suggest-template] > .auto-box li {
  cursor: pointer;
  padding: 5px;
  white-space: nowrap;
}
*[suggest-template] > .auto-box li > span {
  display: inline;
  position: relative;
  padding: 2px 10px;
  height: 26px;
  line-height: 26px;
  background-color: #906d60;
  font-size: 16px;
  color: var(--white);
  border-radius: 13px;
}
*[suggest-template] > .auto-box li > span::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template] > .auto-box li > span::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template] > .auto-box li:hover,
*[suggest-template] > .auto-box li.act {
  background-color: var(--greyLight20);
}
*[suggest-template] > .auto-box li.system > span {
  background-color: #9e80bd;
}
*[suggest-template] > .auto-box li.system > span:before,
*[suggest-template] > .auto-box li.system > span:after {
  color: #e0e0e0;
}
*[suggest-template] > .wrap-mask {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 30px;
  top: 32px;
  left: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  white-space: pre;
  padding: 3px 0 0 13px;
}
*[suggest-template] > .wrap-mask > .mask {
  color: rgba(0, 0, 0, 0);
  display: inline-block;
}
*[suggest-template] > .wrap-mask > .mask > span {
  display: inline-block;
  position: relative;
  padding: 0px 0px;
  height: 26px;
  line-height: 26px;
  background-color: #906d60;
  font-size: 16px;
  color: var(--white);
  cursor: pointer;
  border-radius: 13px;
}
*[suggest-template] > .wrap-mask > .mask > span::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: 0px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template] > .wrap-mask > .mask > span::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 0px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template] > .wrap-mask > .mask > span.system {
  background-color: #9e80bd;
}
*[suggest-template] > .wrap-mask > .mask > span.system:before,
*[suggest-template] > .wrap-mask > .mask > span.system:after {
  color: #e0e0e0;
}
*[suggest-template].url > .wrap-mask {
  top: 0;
  left: 1px;
  width: 246px;
  padding: 3px 3px 0px 0px;
}
*[suggest-template].url > .wrap-mask > .mask > span {
  height: 16px;
  border-radius: 13px;
  padding: 0 1px 0 1px;
  line-height: 16px;
  margin: 0px -4px 0 4px;
  font-size: 12px;
}
*[suggest-template].url > .wrap-mask > .mask > span::before {
  content: '{{';
  font-size: 13px;
  position: relative;
  left: 0px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template].url > .wrap-mask > .mask > span::after {
  content: '}}';
  font-size: 13px;
  position: relative;
  left: 0px;
  top: -1px;
  color: #e0995d;
}
*[suggest-template].url .auto-box {
  top: 4px;
}
.action-item[suggest-template] div.wrap-mask {
  top: -3px;
  text-align: center;
  width: 266px;
  left: -19px;
  font-size: 17px;
  height: 45px;
}
.action-item[suggest-template] > .auto-box {
  top: 20px;
  margin-left: -20px;
}
