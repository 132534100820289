.public-vars-suggest .values {
  position: relative;
  height: auto;
}
.public-vars-suggest .values .values-list {
  width: 100%;
  height: auto;
  min-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  padding: 4px 3px 0 3px;
}
.public-vars-suggest .values .values-list .value-item {
  display: inline-block;
  position: relative;
  height: 26px;
  margin: 0 8px 4px 0;
  max-width: 98%;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj {
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  font-size: 16px;
  color: var(--white);
  -webkit-user-select: none;
  user-select: none;
  background-color: #906d60;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj > span {
  display: block;
  padding: 0 10px;
  height: 26px;
  max-width: 100%;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj > span::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj > span::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj > span span {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj.system {
  background-color: #9e80bd;
}
.public-vars-suggest .values .values-list .value-item .value-item-obj.system > span:before,
.public-vars-suggest .values .values-list .value-item .value-item-obj.system > span:after {
  color: #e0e0e0;
}
.public-vars-suggest .values .values-list .value-item .trash-popup {
  left: auto;
  right: -6px;
}
.public-vars-suggest .values .values-list .value-item:hover .trash-popup {
  transform: scale(1, 1);
  opacity: 1;
}
.public-vars-suggest .values .values-list .value-item::after {
  content: ', ';
  position: absolute;
  right: -5px;
  top: 0;
  color: var(--black);
}
.public-vars-suggest .values .values-list .value-item:nth-last-of-type(2) {
  margin: 0 0 4px 0;
}
.public-vars-suggest .values .values-list .value-item:nth-last-of-type(2)::after {
  content: '';
}
.public-vars-suggest .values .values-list .popup-box {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup {
  position: absolute;
  border-radius: 7px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.13);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 10000;
  padding: 0;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 29px 0 0 -4px;
  max-height: 258px;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-width: unset;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li {
  display: flex;
  height: 48px;
  line-height: 47px;
  margin: 0;
  cursor: pointer;
  background-color: var(--white);
  order: 2;
  justify-content: space-between;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li:last-child {
  border-radius: 0 0 3px 0;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li.act {
  background-color: #f3f3f3;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div {
  width: 100%;
  padding: 10px;
  max-width: 255px;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div > span {
  display: table-cell;
  padding: 0 5px;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div > span.name {
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  background-color: #906d60;
  padding: 0 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  max-width: 100%;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div > span.name span {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: var(--white);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div > span.name::before {
  content: '{{';
  font-size: 16px;
  color: #e0995d;
  position: absolute;
  left: 7px;
  top: -1px;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div > span.name::after {
  content: '}}';
  font-size: 16px;
  color: #e0995d;
  position: absolute;
  right: 7px;
  top: -1px;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div.system .name {
  background-color: #9e80bd;
}
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div.system .name:before,
.public-vars-suggest .values .values-list .popup-box .valuePopup ul li div.system .name:after {
  color: #e0e0e0;
}
.public-vars-suggest .values .values-list .popup-box input {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 0 0 0 8px;
  background-color: transparent;
  width: 160px;
}
.public-vars-suggest .values.act .values-list {
  height: auto;
  overflow: visible;
  white-space: normal;
  z-index: 100;
}
