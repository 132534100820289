.plugin-insert {
  position: absolute;
  top: -41px;
  left: calc(-1 * var(--content-padding-left));
  width: 100%;
  height: 40px;
}
.plugin-insert:hover .add-small {
  opacity: 0.3;
}
.plugin-insert.small-height {
  top: -3px;
  height: 10px;
}
.plugin-insert .add-small {
  position: absolute;
  left: 8px;
  bottom: 6px;
  opacity: 0;
  background-size: 18px auto;
}
.plugin-insert .add-small:hover {
  opacity: 0.9;
}
.plugin-insert .add-small.b-show {
  opacity: 0.3;
}
.plugin-insert .add-small.b-show::before,
.plugin-insert .add-small.b-show::after {
  display: none;
}
.tooltip-insert-plugin {
  z-index: var(--zindex-dropdown);
  width: auto;
}
.tooltip-insert-plugin .tooltip-inner {
  background-color: #053748;
  pointer-events: none;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: var(--white);
}
.tooltip-insert-plugin .tooltip-arrow {
  border-right-color: #053748 !important;
  min-width: 0;
  max-width: unset;
  margin: 0;
}
